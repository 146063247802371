<template>

  <div>
    <div class="route-title">{{ $t('StatisticsRoute') }}</div>
    <div v-if="isLoading" class="loader-container">
      <div class="loader"></div>
    </div>
    <div class="section" v-if="!isLoading1">

      <div class="section-title">
        {{ $t('mostStoresProvideInspection') }}
      </div>
      <div v-if="this.storeItems.length===0&&!isLoading1" style="padding: 100px" class="select-title">
        <label for="selectItem" > لا يوجد عناصر لعرضها</label>
      </div>
      <div class="grid-container2" v-if="this.storeItems.length!==0">

        <ShopCard v-for="(item) in this.storeItems" :key="item.id" :id="item.id" :fileData="item.store"
                  :name="item.store.name"
                  :number="item.store.cost + ' ريال'"
                  :customMethod="() => pressStore(item.id)"/>
      </div>
    </div>
    <div class="section"  v-if="!isLoading2">
      <div class="section-title">
        {{ $t('TopBestSellingMerchants') }}
      </div>
      <div v-if="this.sellersItems.length===0&&!isLoading2" style="padding: 100px" class="select-title">
        <label for="selectItem" > لا يوجد عناصر لعرضها</label>
      </div>
      <div class="grid-container2" v-if="this.sellersItems.length!==0">
        <ShopCard v-for="(item, index) in this.sellersItems" :key="index" :fileData="item.user"
                  :name="item.user.first_name+' '+item.user.last_name" :number="item.total_sold+' منتج مباع'"
                  :customMethod="() => pressUser(item.user.id)"/>
      </div>
    </div>
    <div class="section"  v-if="!isLoading3">
      <div class="section-title">
        {{ $t('MostActiveUsers') }}
      </div>
      <div v-if="this.buyersItems.length===0&&!isLoading3" style="padding: 100px" class="select-title">
        <label for="selectItem" > لا يوجد عناصر لعرضها</label>
      </div>
      <div class="grid-container2" v-if="this.buyersItems.length!==0">
        <ShopCard v-for="(item, index) in this.buyersItems" :name="item.user.first_name+' '+item.user.last_name"
                  :number="item.total_purchases+' منتج مباع'" :key="index" :fileData="item" :customMethod="() => pressUser(item.user.id)"/>
      </div>
    </div>
    <div class="section"  v-if="!isLoading">
      <div class="section-title">
        {{ $t('AllUsers') }}
        <div class="icon-row">
          <div class="icon" v-html="GroupMessage" @click="openPopup"></div>
          {{ $t('SendGroupMessage') }}
        </div>
      </div>
      <div v-if="this.AllUsers.length===0&&!isLoading" style="padding: 100px" class="select-title">
        <label for="selectItem" > لا يوجد عناصر لعرضها</label>
      </div>
<div class="grid-auto"  v-if="!isLoading">
  <div class="grid-container">
    <div v-for="(item, index) in AllUsers" :key="index" class="grid-item">
      <ShopCard :name="item.first_name+' '+item.last_name"
                :number="item.total_sold +' منتج مباع'" :key="index" :fileData="item" :customMethod="() => pressUser(item.id)"/>

    </div>
  </div></div>

      <div  v-for="(title, index) in exportList" :key="index">
        <ExportPDF :title="title"/>

      </div>
    </div>
    <GroupMessagePopUp v-if="showGroupPopUp" ref="GroupMessagePopUpRef" @close-popup="closeGroupPopUp"/>
  </div>
</template>


<script>


import {GroupMessage} from "@/util/constants";
import ShopCard from "@/features/statistics/presentation/components/ShopCard.vue";
import ExportPDF from "@/features/statistics/presentation/components/ExportPDF.vue";
import StatisticsViewModel from "@/features/statistics/viewmodels/StatisticsViewModel";
import GroupMessagePopUp from "@/features/statistics/presentation/components/GroupMessagePopUp.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "MainStatistic",


  components: {
    GroupMessagePopUp,
    ExportPDF,
    ShopCard

  },

  data() {
    return {
      showGroupPopUp:false,
      GroupMessage: GroupMessage,
      exportList: [
        'ExportReportsIntermediaryStores',
        'ExportReportsForSales',
        'ExportReportsActiveUsers',
        'ExportProducts'
      ],
      storeItems: [],
      sellersItems: [],
      buyersItems: [],
      AllUsers: [],
      isLoading: true,
      isLoading1: true,
      isLoading2: true,
      isLoading3: true,
    }
  },
  created() {
    this.fetchTopStoresData();
    this.fetchTopSellersData();
    this.fetchTopUsersData();
    this.fetchAllUsersData();


  },
  methods: {
    async fetchTopStoresData() {

      try {
        const data = await StatisticsViewModel.getTopStores();
        console.log(data[0].store)
        this.storeItems = data;
        this.isLoading1 = false;
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading1 = false;

      }
    },
    async fetchTopSellersData() {
      try {
        const data = await StatisticsViewModel.getTopSellers();
        console.log(data[0].total_sold + 'users')
        this.sellersItems = data;
        this.isLoading2 = false;

      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading2 = false;

      }
    },

    async fetchTopUsersData() {
      try {
        const data = await StatisticsViewModel.getTopUsers();
        console.log('top isssssss'+data)
        this.buyersItems = data;
        console.log('top isssssssnnnnnnnnnnnnn'+this.buyersItems[0].user)
        this.isLoading3 = false;

      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading3 = false;

      }
    },
    async fetchAllUsersData() {
      try {
        const data = await StatisticsViewModel.getAllUsers();
        console.log(data)
        this.AllUsers = data;
        console.log('is all USERS'+this.AllUsers)

        this.isLoading = false;

      } catch (error) {
        console.error("Error fetching users:", error.message);
        this.isLoading = false;

      }
    },
    pressStore(id) {
      this.$router.push({
        name: 'IntermediateStoreDetails',
        params: {id: id},
      });
    },
    pressUser(id) {
      this.$router.push({name: 'UserDetails', params: {id: id},});
    },
    closeGroupPopUp() {
      this.showGroupPopUp = false;
    },
    openPopup() {
      this.showGroupPopUp = true;
      if (this.$refs.GroupMessagePopUpRef && this.$refs.GroupMessagePopUpRef.openPopup) {
        this.$refs.GroupMessagePopUpRef.openPopup();
      }
    },

  },

}
</script>


<style scoped src="../styles/Statistics.css">

</style>