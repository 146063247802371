<template>
  <div class="page-scrollable">
    <div v-if="isLoading" class="loader-container">
      <div class="loader"></div>
    </div>
    <div v-if="!isLoading">
      <div class="part">
        <div class="row">
          <div class="route-title">{{ $t('PrivacyPolicy') }}</div>
          <div v-if="!isEditing1" class="icon" v-html="EditItem()" @click="toggleEdit(1)"></div>
          <button class="icon" @click="cancelEdit(1)" v-if="isEditing1">Cancel</button>
        </div>
        <div class="editable-container">
          <p v-if="!isEditing1">{{ text1 }}</p>
          <textarea v-else class="custom-input" v-model="this.text1" @keyup.enter="saveText(1)"
                    @blur="cancelEdit(1)"></textarea>
        </div>
      </div>
      <div class="part">
        <div class="row">
          <div class="route-title">{{ $t('PaymentPolicy') }}</div>
          <div v-if="!isEditing2" class="icon" v-html="EditItem()" @click="toggleEdit(2)"></div>
          <button class="icon" @click="cancelEdit(2)" v-if="isEditing2">Cancel</button>

        </div>
        <div class="editable-container">
          <p v-if="!isEditing2">{{ text2 }}</p>
          <textarea v-else class="custom-input" v-model="this.text2" @keyup.enter="saveText(2)"
                    @blur="cancelEdit"></textarea>
        </div>
      </div>
      <div class="part">

        <div class="row">
          <div class="route-title">{{ $t('ContractPolicy') }}</div>
          <div v-if="!isEditing3" class="icon" v-html="EditItem()" @click="toggleEdit(3)"></div>
          <button class="icon" @click="cancelEdit(3)" v-if="isEditing3">Cancel</button>

        </div>
        <div class="editable-container">
          <p v-if="!isEditing3">{{ text3 }}</p>
          <textarea v-else class="custom-input" v-model="this.text3" @keyup.enter="saveText(3)"
                    @blur="cancelEdit"></textarea>
        </div>
      </div>

      <div class="part">

        <div class="row">
          <div class="route-title">{{ $t('bankAccount') }}</div>
          <div v-if="!isEditing5" class="icon" v-html="EditItem()" @click="toggleEdit(5)"></div>
          <button class="icon" @click="cancelEdit(5)" v-if="isEditing5">Cancel</button>

        </div>
        <div class="editable-container">
          <p v-if="!isEditing5">{{ text5 }}</p>
          <textarea v-else class="custom-input" v-model="this.text5" @keyup.enter="saveText(3)"
                    @blur="cancelEdit"></textarea>
        </div>
      </div>

      <div class="part">

        <div class="row">
          <div class="route-title">{{ $t('whoAreWe') }}</div>
          <div v-if="!isEditing4" class="icon" v-html="EditItem()" @click="toggleEdit(4)"></div>
          <button class="icon" @click="cancelEdit(4)" v-if="isEditing4">Cancel</button>

        </div>
        <div class="editable-container">
          <p v-if="!isEditing4">{{ text4 }}</p>
          <textarea v-else class="custom-input" v-model="this.text4" @keyup.enter="saveText(4)"
                    @blur="cancelEdit"></textarea>
        </div>
      </div>
      <div class="row2">
        <div class="select-title">
          <label for="selectItem"> {{ $t('PlatformCommission') }}</label>
        </div>
        <input id="selectItem" v-model="commission">
      </div>
      <div class="image-container">
        <div class="image" @click="sendData">
          <span class="button-text">{{ $t("Save") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {EditIcon, EditItem} from "@/util/constants";
import PublicPoliciesViewModel from "@/features/public_policies/viewmodels/PublicPoliciesViewModel";

export default {
  name: "PolicityContent",
  created() {
    this.getItems()
  },
  methods: {
    async getItems() {
      try {

        const policiesData = await PublicPoliciesViewModel.getAllPolicies();

        this.text1 = policiesData.privacy_policy;
        this.text2 = policiesData.payment_policy;
        this.text3 = policiesData.contract_with_merchant;
        this.text4 = policiesData.about_us;
        this.text5 = policiesData.bank_account;

        this.commission = policiesData.commission;
        this.isLoading = false

      } catch (error) {
        this.isLoading = false

        console.error('Error fetching policies:', error);
      }
    },
    EditItem() {
      return EditItem
    },
    EditIcon() {
      return EditIcon
    },
    startEdit() {
      this.isEditing = true;
      this.editedText = this.text;
    },

    //here
    toggleEdit(index) {
      if (index === 1) {
        this.isEditing1 = !this.isEditing1;
        this.editedText1 = this.text1;

      } else if (index === 2) {
        this.isEditing2 = !this.isEditing2;
        this.editedText2 = this.text2;

      } else if (index === 3) {
        this.isEditing3 = !this.isEditing3;
        this.editedText3 = this.text3;

      } else if (index === 4) {
        this.isEditing4 = !this.isEditing4;
        this.editedText4 = this.text4;

      } else if (index === 5) {
        this.isEditing5 = !this.isEditing5;
        this.editedText5 = this.text5;

      }

    },
    async sendData() {
      this.isLoading = true

      try {
        console.log({
          privacy_policy: this.text1,
          payment_policy: this.text2,
          contract_with_merchant: this.text3,
          about_us: this.text4,
          commission: this.commission
        })
        const response = await PublicPoliciesViewModel.updateAllPolicies({
          privacy_policy: this.text1,
          payment_policy: this.text2,
          contract_with_merchant: this.text3,
          about_us: this.text4,
          bank_account: this.text5,
          commission: this.commission
        });
        this.getItems()
        this.isEditing1 = false
        this.isEditing2 = false
        this.isEditing3 = false
        this.isEditing4 = false
        this.isEditing5 = false

        this.isLoading = false
        console.log(response);
      } catch (error) {
        this.isLoading = false

        console.error('Error sending data:', error);
      }
    },
  saveText(index) {
    if (index === 1) {
      this.text1 = this.editedText1;
      this.isEditing1 = false;
    } else if (index === 2) {
      this.text2 = this.editedText2;
      this.isEditing2 = false;
    } else if (index === 3) {
      this.text3 = this.editedText3;
      this.isEditing3 = false;
    } else if (index === 4) {
      this.text4 = this.editedText4;
      this.isEditing4 = false;
    } else if (index === 5) {
      this.text5 = this.editedText5;
      this.isEditing5 = false;
    }
  },
  cancelEdit(index) {
    if (index === 1) {
      this.isEditing1 = false
    } else if (index === 2) {
      this.isEditing2 = false
    } else if (index === 3) {
      this.isEditing3 = false
    } else if (index === 4) {
      this.isEditing4 = false
    }  else if (index === 5) {
  this.isEditing5 = false
}



  },
  },

  data() {
    return {
      isLoading: true,
      editedText1: "",
      editedText2: "",
      editedText3: "",
      editedText4: "",
      editedText5: "",
      commission: "",
      isEditing1: false,
      isEditing2: false,
      isEditing3: false,
      isEditing4: false,
      isEditing5: false,
      text3: '',
      text1: '',
      text2: '',
      text4: '',
      text5: '',
      categories: [
        {id: 1, name: "Category 1", image: "Category 1"},
        {id: 2, name: "Category 2", image: "Category 1"},
        {id: 3, name: "Category 3", image: "Category 1"},
        // Add more categories as needed
      ],
    };
  },
};
</script>
<style scoped src="../styles/PublicPolicies.css">

</style>