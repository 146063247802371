<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
    // this.$router.push({name: 'CityList'});
    this.$router.push({name: 'Login'});
    // this.$router.push({name: 'SubCategoriesData'});
  }
}
</script>

<style>
@font-face {
  font-family: Segoe UI;
  src: url('~@/fonts/Segoe UI.ttf');
}
#app {

  justify-content: center;
  align-items: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}

@media screen and (max-width: 768px) {
  #app {
    height: auto;
  }
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
}
</style>
