<template>
  <div class="row">
    <div class="route-title">
      تفاصيل تقرير رقم {{ this.id }}
    </div>
    <div class="icon" v-html="ShareIcon()" @click="openPopup15"></div>
    <a class="icon" :href="downloadUrl" download="report.pdf">
      <div v-html="PDFIcon()"></div>
    </a>
  </div>
  <div>
    <iframe
        title="PDF"
        :src='pdfUrl'
        width="100%"
        height="1000px"
    ></iframe>
  </div>
  <SharePopUp v-if="showSharePopUp" ref="SharePopUpRef" @close-popup="closeGroupPopUp" :name="this.downloadUrl"/>

</template>

<script>
import {PDFIcon, PdfUrl, ShareIcon} from "@/util/constants";
import IntermediateStoreViewModel from "@/features/intermediate_store/viewmodels/IntermediateStoreViewModel";
import SharePopUp from "@/components/SharePopUp.vue";

export default {
  name: "ShowReport",
  components: {SharePopUp},
  methods: {
    ShareIcon() {
      return ShareIcon
    },
    PDFIcon() {
      return PDFIcon
    },

    async downloadPDF() {
      try {
        const response = await fetch(this.downloadUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading PDF:", error.message);
      }
    },
    async shareFile() {
      const shareText = `Check out this PDF file: ${this.downloadUrl}`;
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(shareText)}`;

      window.open(whatsappUrl, '_blank');
    },
    async fetchReportData() {

      try {
        this.isLoading = true
        const id = this.$route.query.id;
        console.log('id isss' + id)
        const data = await IntermediateStoreViewModel.getReport(id);
        this.pdfUrl = PdfUrl + data + '#toolbar=0'
        this.downloadUrl = PdfUrl + data
        console.log(this.pdfUrl)
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching store details:", error.message);
        this.isLoading = false;

      }
    },
    closeGroupPopUp() {
      this.showSharePopUp = false;
    },
    openPopup15() {
      this.showSharePopUp = true;
      if (this.$refs.SharePopUpRef && this.$refs.SharePopUpRef.openPopup) {
        this.$refs.SharePopUpRef.openPopup();
      }
    },
  },


  data() {
    return {
      pdfUrl: "",
      downloadUrl: "",
      showSharePopUp: false,
      id: null
    };
  },
  created() {
    this.id= this.$route.query.id;

    this.fetchReportData();
  },


};
</script>
<style scoped src="../styles/ShowReport.css">

</style>