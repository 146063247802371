<template>
  <div class="card" @click="openDetails(this.subcategory.id)">
    <img :src="ImageUrl()" alt="Card Image" class="card-image"/>

    <div class="card-content">
      <h2>{{ this.subcategory.name }}</h2>
      <div class="card-icons">
        <div class="icon-left">
          <div v-html="DeleteIcon()" @click.stop="openDeletePopup"></div>
        </div>
        <div class="icon-right" @click.stop="openEdit">
          <div v-html="EditIcon()"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {DeleteIcon, EditIcon, ImageUrl} from "@/util/constants";
import editCategory from "@/features/categories/presentation/pages/EditCategory.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "SubSubCategoryCard",
  computed: {
    editCategory() {
      return editCategory
    }
  },
  props: {
    subcategory: Object,
    isMain: Boolean,
  },
  methods: {
    ImageUrl() {
      return ImageUrl + this.subcategory.image
    },

    openEdit() {
      this.$router.push({
        name: 'EditSubSubCategory', query: {
          category: this.subcategory.name,
          id: this.subcategory.id,
          code: this.subcategory.code,
          drafting_fee: this.subcategory.drafting_fee,
          trader_profit: this.subcategory.trader_profit
        }
      });

    },
    EditIcon() {
      return EditIcon
    },
    openDeletePopup() {
      this.$emit('open-popup');
    },
    openDetails(subId) {
   if (!this.isMain){
     this.$router.push({name: 'SubSubCategoriesDetails', params: {id: subId}, query: {name: this.subcategory.name}});

   }
    }
    ,

    DeleteIcon() {
      return DeleteIcon
    }
  }
  ,
}
</script>
<style scoped src="../styles/Categories.css">

</style>