<template>
  <div class="shop-card" @click="openPDF(item.id)">
    <div class="circle"
         v-html="UserReportIcon()"></div>

      <div class="card-title">المنتج رقم {{this.item.product.code}}</div>
   <div class="card-title2">{{ formattedDate}}</div>
  </div>
</template>

<script>
import {Statistics, UserReportIcon} from "@/util/constants";
import { format } from "date-fns";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'ReportsCard',
  data() {
    return {
      date:null
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedDate() {
      const date = new Date(this.item.created_at);
      return format(date, "dd/MM/yyyy");
    },
  },
  methods: {
    UserReportIcon() {
      return UserReportIcon
    },
    Statistics() {
      return Statistics
    },
    openPDF(id) {
      this.$router.push({name: 'ReservationDetails', params: {id: id},});
    }
  }
}

</script>


<style scoped src="../presentation/styles/ProductsCard.css">

</style>