import MainService from '../services/MainService';

export default {
    async logout() {
        try {
            const token = localStorage.getItem('token');

            const response = await MainService.logout(token);
            return response;
        } catch (error) {
            console.error('Error fetching stores:', error.message);
            throw error;
        }
    },


};
