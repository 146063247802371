import NotificationsService from '../services/NotificationsService';

export default {
    async getAllNotifications() {
        try {
            const token = localStorage.getItem('token');

            const response = await NotificationsService.getAllNotifications(token);
            return response.data;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    },


};
