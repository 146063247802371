<template>
  <div class="shop-card" @click="openPDF">
    <div class="circle"
         v-html="Statistics()"></div>


      <div class="card-title"> التقرير رقم   {{this.report['id']}}</div>
    <div class="card-title2">{{ formattedDate}}</div>
  </div>

</template>

<script>
import {Statistics} from "@/util/constants";
import {format} from "date-fns";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'ReportCard',
  data() {
    return {
      pdfUrl: "https://www.africau.edu/images/default/sample.pdf",
    };
  },
  props: {
    report: Object
  },
  computed: {

    formattedDate() {
      const date = new Date(this.report.created_at);
      return format(date, "dd/MM/yyyy");
    },
  },
  methods: {
    Statistics() {
      return Statistics
    },
    openPDF() {
      this.$router.push({ name: 'ShowReport', query: { id: this.report['id'] } });    }
  }
}

</script>


<style scoped src="../styles/ReportCard.css">

</style>