<template>
  <div :class="{ 'rtl-layout': isArabic }" class="main">
    <div v-if="!this.isSidebarOpen" class="menu-icon" @click="toggleSidebar">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M0 0h24v24H0z" fill="none"/>
        <path
            d="M4 18h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0-5h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0-5h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1z"/>
      </svg>
    </div>
    <transition name="slide">

      <div v-if="isSidebarOpen" class="side">
        <!--        <div class="close-icon" @click="toggleSidebar2">-->
        <!--          <button style="background-color: transparent ;    font-size: 25px; margin: 5px -2px 5px 0; ">&times;-->
        <!--          </button>-->
        <!--        </div>-->
        <SideBar @open-popup="open" @close-popup="closeGroupPopUp"/>

      </div>
    </transition>

    <div class="content">


      <router-view/>
    </div>

  </div>
  <LogoutPopUp v-if="openLogout" ref="LogoutPopUpRef"/>


  <!--  <div class="fab">-->
  <!--    <button @click="performAction">+</button>-->
  <!--  </div>-->
</template>

<script>

import SideBar from "@/features/main/presentation/views/components/SideBar.vue";
import LogoutPopUp from "@/features/main/presentation/views/components/LogoutPopUp.vue";

export default {
  name: "MainLayout",
  components: {
    LogoutPopUp,
    SideBar,
  },

  data() {
    return {
      isSidebarOpen: true,
      isArabic: false,
      openLogout: false,


    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    toggleSidebar2() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    handleResize() {
      this.isSidebarOpen = window.innerWidth > 768;
    },
    closeGroupPopUp() {
      if (window.innerWidth < 768) {
        this.isSidebarOpen = false;
      }

    },
    open() {
      this.openLogout = true;
      console.log('aaaaaadddddddddd')
      if (this.$refs.LogoutPopUpRef && this.$refs.LogoutPopUpRef.openPopup) {
        this.$refs.LogoutPopUpRef.openPopup();
      }
    },

  },

  created() {
    if (window.innerWidth <= 768) {
      this.isSidebarOpen = false;
    }
    window.addEventListener("resize", this.handleResize);
  },

};
</script>

<style scoped src="../styles/Main.css">
</style>
