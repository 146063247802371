<script>
import GoogleMapsApiLoader from 'google-maps-api-loader'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Map2",
  props: {
    mapConfig: Object,
    apiKey: String,
  },

  data() {
    return {
      google: null,
      map: null
    }
  },

  async mounted() {
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: this.apiKey
    })
    this.google = googleMapApi
    this.initializeMap()
  },

  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap
      this.map = new this.google.maps.Map(
          mapContainer, this.mapConfig
      )
      this.map.addListener('click', (event) => {
        this.handleMapClick(event.latLng);
      });
    },
    handleMapClick(latLng) {
      this.$emit('point-selected', {
        latitude: latLng.lat(),
        longitude: latLng.lng()
      });
    }
  }
}
</script>

<template>
  <div>
    <div class="google-map" ref="googleMap"></div>
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot
          :google="google"
          :map="map"
      />
    </template>
  </div>
</template>
