<template>
  <div>
    <div class="page-scrollable">

      <div class="row">
        <div class="route-title">{{ $t('RegisteredIntermediateStores') }}</div>
        <div class="icon" v-html="AddIcon()" @click="OpenAddStoreOptions"></div>

      </div>
    </div>

    <div class="row">
      <input
          style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif"
          :placeholder="$t('StoreName')"
          type="text"
          v-model="name"
          @input="searchStore(name)"
      />
      <div class="icon2" v-html="FilterIcon()" @click="OpenFilterOptions"></div>

    </div>
    <div v-if="isLoading" class="loader-container">
      <div class="loader"></div>
    </div>

    <div v-if="this.shopItems.length===0 && !isLoading">
      <div>لا يوجد عناصر</div>
    </div>

    <div class="grid-container">
      <IntermidiateShopCard v-for="(item, index) in shopItems" :key="index" :item="item"
                            @click="openDetails1(item.id)"/>
    </div>
  </div>

</template>

<script>
import IntermidiateShopCard from "@/features/intermediate_store/presentation/components/IntermidiateShopCard.vue";
import {AddIcon, FilterIcon} from "@/util/constants";
import IntermediateStoreViewModel from "@/features/intermediate_store/viewmodels/IntermediateStoreViewModel";

export default {
  name: "StoreResultsBody",
  components: {IntermidiateShopCard},
  data() {
    return {
      shopItems: [],
      isLoading: true,
      name: ''
    };
  },
  created() {
    if (this.$route.query.cost !== undefined || this.$route.query.sales !== undefined || this.$route.query.created !== undefined) {
      this.SortStoresData(this.$route.query.cost, this.$route.query.sales, this.$route.query.created);
    } else if (this.$route.query.reg !== undefined || this.$route.query.city !== undefined || this.$route.query.nig !== undefined || this.$route.query.commission !== undefined) {
      this.sendStoresData()
    } else {
      this.fetchStoresData();

    }
  },
  methods: {
    async searchStore(name) {
      if (name.length === 0) {
        await this.fetchStoresData()
      } else {
        try {
          const data = await IntermediateStoreViewModel.searchStore(name);
          console.log(data)
          this.shopItems = data.data;
          this.isLoading = false;
        } catch (error) {
          console.error("Error fetching stores:", error.message);
          this.isLoading = false;

        }
      }

    },
    async SortStoresData(cost, sales, created) {

      try {
        const data = await IntermediateStoreViewModel.sortStores(cost, sales, created);
        console.log(data)
        this.shopItems = data.data;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading = false;

      }
    },
    async fetchStoresData() {

      try {
        const data = await IntermediateStoreViewModel.getAllStores();
        console.log(data)
        this.shopItems = data.data;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading = false;

      }
    },


    async sendStoresData() {
      try {
        const data = await IntermediateStoreViewModel.sendStore(this.$route.query.reg, this.$route.query.city, this.$route.query.nig, this.$route.query.commission);
        console.log(data)
        this.shopItems = data.data;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading = false;

      }
    },
    OpenFilterOptions() {
      this.$router.push({name: 'SortBody'});
    },
    OpenAddStoreOptions() {
      this.$router.push({name: 'AddStore'});
    },
    AddIcon() {
      return AddIcon
    },
    FilterIcon() {
      return FilterIcon
    },
    openDetails1(id) {
      this.$router.push({
        name: 'IntermediateStoreDetails',
        params: {id: id},
      });
    },
  },
};
</script>

<style scoped src="../styles/StoreResultsBody.css">

</style>