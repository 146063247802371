<template>
  <div class="page-scrollable">

    <div class="row">
      <div class="route-title">{{ $t('Notifications') }}</div>
    </div>
    <div v-if="isLoading" class="loader-container">
      <div class="loader"></div>
    </div>
    <div v-for="(item, index) in items2" :key="index"
         class="notification">
      <div style="display: flex">
        <div class="circle"></div>
        <div style="font-weight: bold; margin-right: 20px; text-align: start">{{ item.description }}</div>
      </div>

    </div>
  </div>
</template>
<script>

import NotificationsViewModel from "@/features/notifications/viewmodels/NotificationsViewModel";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Notifications",
  data() {


    return {
      isLoading:true,
      items2: [
         ],
    }
  }, created() {
    this.fetchNotData();
  },
  methods: {
    async fetchNotData() {

      try {
        const data = await NotificationsViewModel.getAllNotifications()
        console.log(data)
        this.items2=data.data
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching store details:", error.message);
        this.isLoading = false;

      }
    },
  }


}
</script>
<style scoped src="../styles/Notifications.css">

</style>