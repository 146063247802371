export default {
    greeting: 'مرحبا!',
    loginTitle: 'تسجيل الدخول في منصة الذهب',
    email: 'الايميل',
    emailValidation: 'البريد الاكتروني المدخل غير صالح',
    emailRequired: 'ادخل البريد الالكتروني من فضلك',
    password: 'كلمة السر',
    passwordValidation: 'كلمة المرور اقل من 8 محارف',
    passwordRequired: 'ادخل كلمة المرور من فضلك',
    login: 'تسجيل الدخول',
    Statistics: "الاحصاءات",
    StatisticsRoute: "احصائيات و تقارير خاصة بالتطبيق",
    IntermediateStoresRoute: "ترتيب المتاجر الوسيطة",
    ProductsRoute: "المنتجات المتوفرة ضمن التطبيق",
    CategoriesRoute: "التصنيفات الاساسية",
    mostStoresProvideInspection: "اكثر 5 متاجر مقدمة خدمة الفحص",
    TopBestSellingMerchants: "اكثر 5 تجار مبيعا",
    MostActiveUsers: "اكثر 5 مستخدمين فعالين",
    AllUsers: "جميع المستخدمين",
    IntermediateStores: "المتاجر الوسيطة",
    Products: "المنتجات",
    Categories: "التصنيفات",
    PublicPolicies: "السياسات العامة",
    Notifications: "الاشعارات",
    Messages: "الرسائل",
    SendGroupMessage: "ارسال رسالة جماعية",
    ExportReportsIntermediaryStores: "اصدار تقارير تخص المتاجر الوسيطة",
    ExportReportsForSales: "اصدار تقارير تخص تجار البيع",
    ExportReportsActiveUsers: "اصدار تقارير تخص المستخدمين النشطين",
    ExportProducts: "اصدار تقارير تخص المنتجات",
    SendGroupMessagePopUp: "ارسال رسالة جماعية عن طريق التطبيق",
    uploadFile: "رفع الملف",
    uploadFileTitle: "ارفاق ملف",
    or: "أو",
    send: "ارسال",
    filter: "تصفية",
    sort: "ترتيب",
    filterIntermiadateStore: "تصفية المتاجر الوسيطة",
    StoreCommission: "عمولة المتجر",
    Region: "المنطقة",
    City: "المدينة",
    District: "الحي",
    Search: "البحث",
    FromHighestToLowest: "من الأعلى الى الادنى",
    FromLowestToHighest: "من الأدنى الى الاعلى",
    FromNewestToOldest: "من الاحدث الى الاقدم",
    FromOldestToNewest: "من الاقدم الى الاحدث",
    FromMostToLeast: "من الأكثر الى الاقل",
    FromLeastToMost: "من الأقل الى الأكثر",
    SortByPrice: "ترتيب حسب السعر",
    SortByRegistrationDate: "ترتيب حسب تاريخ التسجيل",
    SortByBestSellers: "ترتيب حسب الأكثر مبيعا",
    RegisteredIntermediateStores: "المتاجر الوسيطة المسجلة ضمن التطبيق",
    StoreName: "اسم المتجر المراد البحث عنه",
    StoreName2: "اسم المتجر",
    ByWhatsapp: "عن طريق الواتساب",
    BySMS: "او عن طريق رسالة نصية",
    AddStoreTitle: "اضافة متجر وسيط",
    emailStore: "الايميل",
    phoneNumber: "رقم الهاتف",
    CheckValue: "قيمة الفحص",
    AboutStore: "نبذة عن المتجر",
    Address: "العنوان",
    Governorate: "المحافظة",
    StoreLogo: "لوغو المتجر",
    add: "اضافة",
    Street: "الشارع",
    StoreVerification: "( توثيق حساب المتجر )",
    NationalId: "الرقم الوطني",
    CommercialRegister: "السجل التجاري",
    CommercialRegisterImage: "صورة السجل التجاري",
    License: "الرخصة",
    LicenseImage: "صورة الرخصة",
    ExportedReports: "التقارير المصدرة من المتجر",
    EditStoreInformation: "تعديل بيانات المتجر",
    DeleteStoreAccount: "حذف حساب المتجر",
    BlockStoreAccount: "حظر حساب المتجر",
    UnblockAccount: "فك حظر الحساب",
    UserAds: "إعلانات المستخدم",
    UserSales: "مبيعات المستخدم",
    UserPurchases: "مشتريات المستخدم",
    BlockAccount: "حظر الحساب",
    ProductsTitle: "المنتجات المتوفرة ضمن التطبيق",
    productSearch: "رقم المنتج المراد البحث عنه",
    SortProducts: "ترتيب المنتجات",
    FilterProducts: "تصفية المنتجات",
    SortPublicationDate: "ترتيب حسب تاريخ النشر",
    productCategory: "تصنيف المنتج",
    productSubCategory: "تصنيف المنتج الفرعي",
    CaliberPiece: "عيار القطعة",
    weight: "الوزن ",
    from: "من",
    to: "الى",
    price: "السعر",
    productDetails: "تفاصيل المنتج",
    manufacturer: "الشركة المصنعة",
    itemAge: "عمر القطعة",
    itemWeight: "وزن القطعة",
    ItemPrice: "سعر القطعة",
    BuyerInformation: "معلومات المشتري",
    gramPrice: "سعر الغرام",
    itemCaliber: "عيار القطعة",
    receivingInformation: "معلومات الاستلام",
    Sold: "مباع",
    amountPaid: "المبلغ المدفوع",
    PaymentMethod: "طريقة الدفع",
    Ratings: "التقييمات",
    SellerRating: "تقييم البائع",
    BuyerRating: "تقييم المشتري",
    ProductRating: "تقييم المنتج",
    ServiceRating: "تقييم الخدمة",
    Reports: "الابلاغات",
    EditProductInformation: 'تعديل بيانات المنتج',
    DeleteProduct: 'حذف المنتج',
    AddProduct: 'اضافة منتج',
    ProductDesc: 'وصف عن المنتج',
    ProductAge: 'عمر المنتج',
    ProductWeight: 'وزن المنتج',
    ProductType: 'نوع المنتج',
    ProductCategory: 'تصنيف المنتج',
    CurrentPricePerGram: 'سعر الغرام الحالي حسب السعر',
    AddedProfitValue: 'القيمة الربحية المضافة',
    PlatformCommissionPerGram: 'عمولة المنصة مقابل الغرام',
    AdditionalAmountIfAny: 'مبلغ إضافي ان وجد',
    ExplanationOfTheAdditionalAmount: 'شرح عن المبلغ الاضافي',
    ThePriceOfATotalGram: 'سعر الغرام الكلي',
    TotalPricePerPiece: 'سعر القطعة بشكل كلي',
    GoldKarat: 'عيار الذهب ',
    Industry: 'الصناعة',
    International: 'عالمية',
    Local: 'محلية',
    PieceOffer: 'عرض على القطعة',
    OfferText: 'نص العرض',
    ValueOfTheDiscount: 'قيمة الحسم ان وجد',
    FixedAtTheCurrentPrice: 'تثبيت على السعر الحالي ( في حال تم التثبيت ف السعر لن \n' +
        'يتغير مع تغير سعر الذهب )',
    UploadProductImages: 'تحميل صور المنتج',
    ChooseDeliveryMethod: 'اختر الية التسليم',
    HandInHandWithoutFees: 'يد بيد بدون رسوم ',
    IntermediaryStoreIncludeFees: 'متجر وسيط يمكن ان يتضمن رسوم بتحملها البائع',
    BackupPhone: 'رقم هاتف احتياطي في حال عدم التواجد على الرقم لأصلي :',
    DealtWithOfficiallyAndReceipt: 'هذه الارقام سيتم التعامل معها رسميا و سيتم ارسال اليها رسائل الاستلام و التسليم',
    ChooseMiddleStore: 'اختر المتجر الوسيط',
    IntermediaryShopAgreement: 'متجر وسيط ضمن اتفاق ما بين البائع و المشتري',
    BrokerStorePlatform: 'متجر وسيط ضمن المنصة',
    SelectCity: 'اختر المدينة',
    ChooseNeighborhood: 'اختر الحي',
    CommissionRate: 'سعر العمولة',
    SearchStoreName: 'ابحث عن اسم المتجر',
    MainCategories: 'التصنيفات الاساسية',
    AddMainCategories: 'اضافة صنف رئيسي',
    CategoryName: 'اسم الصنف',
    CategoryImg: 'صورة الصنف',
    SubCategoriesFor: 'التصنيفات الفرعية',
    GoldBannerOffers: 'عروض البانر الإعلاني الخاصة',
    AddOfferBanner: 'اضافة عرض ضمن البانر الاعلاني',
    OfferImage: 'صورة العرض',
    PrivacyPolicy: 'سياسة الخصوصية',
    PaymentPolicy: 'سياسة الدفع',
    ContractPolicy: 'العقد بين المنصة و التاجر',
    whoAreWe: 'من نحن',
    PlatformCommission: 'عمولة المنصة',
    Save: 'حفظ',
    IncomingMessages: 'الرسائل الواردة',
    TypeReplay: 'اكتب رد',
    AddSubCategory: 'اضافة تصنيف جزئي',
    Logout: 'تسجيل الخروج',
    bankAccount: 'الحساب البنكي',
    do_you_want_to_delete_the: 'هل تريد حذف تصنيف',
    do_you_want_to_delete_the_type: 'هل تريد حذف نوع',
   EditMainCategories: 'تعديل صنف رئيسي',
    orders: 'الطلبات',

    EditSubCategories: 'تعديل الصنف الفرعي',
    Book: 'الحجوزات',
    Problems: 'أنواع المشاكل',
    AddProblems: 'أضف نوع مشاكل',
    EditProblem: 'تعديل نوع مشاكل',
    ProblemName: 'اسم النوع',



}
