<template>
  <div class="star-rating">
    <span
        v-for="star in totalStars"
        :key="star"
        @mouseover="hoverStar(star)"
        @click="setValue(star)"
        :class="{ 'filled': star <= value && star !== value + 0.5, 'half-filled': star<= value + 0.5 }"
        v-html="star > value ? '&#9734;' : '&#9733;'"
    ></span>
  </div>
</template>


<script>
export default {
  props: {
    value: {
      type: Number,
      default: 5,
    },
    totalStars: {
      type: Number,
      default: 5,
    },
  },
  methods: {
    hoverStar() {
      // You can add hover functionality if needed
    },
    setValue(star) {
      this.$emit('input', star);
    },
  },
};
</script>

<style scoped>
.star-rating {
  font-size: 24px;
}

span {
  cursor: pointer;
  margin-right: 3px;
}

.filled {
  color: orange;
}

.half-filled {
  position: relative;
  overflow: hidden;
  color: orange;
}

.half-filled:before {
  content: "\2605";
  position: absolute;
  overflow: hidden;
  width: 50%;
  color: orange;
}
</style>
