import {createApp} from 'vue'
import App from './App.vue'
import {createI18n} from 'vue-i18n';
import ar from "@/lang/ar";
import en from "@/lang/en";
import router from './routers'
import '@fortawesome/fontawesome-free/css/all.css';
import VueGoogleMaps from '@fawmi/vue-google-maps';

const messages = {
    en,
    ar,
};
const i18n = createI18n({
    legacy: false,
    locale: 'ar',
    messages: messages
});
createApp(App).use(i18n).use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyC75cYgVGb3CfdsuFFU2tNTbobMhEgCUdY',
        libraries: 'places,geometry',
    },
}).use(router).mount('#app')
