<template>
  <div>
    <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
    <div class="row">
      <div class="route-title">{{ $t('EditProblem') }}</div>

    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('ProblemName') }}</label>
      </div>
      <div>
        <input id="selectItem" v-model="CategoryName">
        <div v-if="validationErrorName" style="color: red; font-size: 13px;">{{ validationErrorName }}</div>

      </div>
    </div>


    <div v-if="isLoading" class="loader-container">
      <div class="loader"></div>
    </div>
    <div class="image-container" v-if="!isLoading">
      <div class="image" @click="onPress">
        <span class="button-text">{{ $t("add") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import {AddIcon} from "@/util/constants";
import ProblemsViewModel from "@/features/problems/viewmodels/ProblemsViewModel";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "EditProblem",

  created() {
    this.CategoryName = this.$route.query.category;
    this.CategoryCode = this.$route.query.code;
    this.CategoryPrice = this.$route.query.price;

  },

  data() {
    return {
      phoneNumbers: [{value: ""}],
      items: [
        {label: this.$t('Region')},
        {label: this.$t('Governorate')},
        {label: this.$t('City')},
        {label: this.$t('District')},
        {label: this.$t('Street')},
      ],
      selectedFileName: "",
      CategoryName: "",
      CategoryPrice: "",
      SelectedFile: null,
      isLoading: false,
      CategoryCode: "",
      validationError: '',
      validationErrorName: '',
      validationErrorImage: '',
      errorMessage: '',

    };
  }, methods: {
    async onPress() {
      try {
        this.isLoading = true
        var name = this.CategoryName


        if (name === this.$route.query.category) {
          name = null
        }

        const data = await ProblemsViewModel.EditProblem(name, this.$route.query.id);
        console.log(data)
        this.$router.push({name: 'ProblemsContent'});
        this.isLoading = false

      } catch (error) {
        console.error("Error Login:", error);

        if (error.message) {
          this.errorMessage = error.message || "خطا في السيرفر";
        } else {
          this.errorMessage = "خطا في السيرفر";
        }

        setTimeout(() => {
          this.errorMessage = '';
        }, 2000);

        this.isLoading = false;

      }
    },
    validateInput() {
      const englishRegex = /^[a-zA-Z]+$/;
      if (this.CategoryCode.length < 1 || this.CategoryCode.length > 6 || this.CategoryCode.length === 0) {
        this.validationError = 'الرجاء ادخال رمز بين ال 0 وال 6 محارف';
      } else if (!englishRegex.test(this.CategoryCode)) {
        this.validationError = 'الرجاء الادخال باللغة الانجليزية';
      } else {
        this.validationError = '';
      }
    },
    AddIcon() {
      return AddIcon
    },
    handleImageUpload(event) {
      this.SelectedFile = event.target.files[0];
      const file = this.SelectedFile;
      if (file) {
        this.selectedFileName = file.name;
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result;
          console.log("Uploaded image URL:", imageUrl);
        };
        reader.readAsDataURL(file);
      }
    },
    addPhoneNumber() {
      if (this.phoneNumbers.length < 3) {
        this.phoneNumbers.push({value: ""});
      }
    },
  }
}
</script>

<style scoped src="../styles/AddProblem.css">

</style>