import CategoriesService from '../services/CategoriesService';

export default {
    async getAllCategories() {
        try {
            const token = localStorage.getItem('token');

            const response = await CategoriesService.getAllCategories(token);
            return response.data;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    },
    async getAllSubCategories(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await CategoriesService.getAllSubCategories(token, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    },
    async getAllSubSubCategories(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await CategoriesService.getAllSubSubCategories(token, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    },   async getAllSubSubMainCategories(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await CategoriesService.getAllSubSubMainCategories(token, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    },

    async getAdsCategory(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await CategoriesService.getAdsCategory(token, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching ads :', error.message);
            throw error;
        }
    },

    async deleteCategory(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await CategoriesService.deleteCategory(token, id);
            return response.data;
        } catch (error) {
            console.error('Error delete category :', error.message);
            throw error;
        }
    },    async deleteSubSubCategory(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await CategoriesService.deleteSubSubCategory(token, id);
            return response.data;
        } catch (error) {
            console.error('Error delete category :', error.message);
            throw error;
        }
    },
    async deleteBanner(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await CategoriesService.deleteBanner(token, id);
            return response.data;
        } catch (error) {
            console.error('Error delete category :', error.message);
            throw error;
        }
    },
    async deleteSubCategory(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await CategoriesService.deleteSubCategory(token, id);
            return response.data;
        } catch (error) {
            console.error('Error delete category :', error.message);
            throw error;
        }
    },
    async getAdsSubCategory(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await CategoriesService.getAdsSubCategory(token, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching ads :', error.message);
            throw error;
        }
    },
    async addCategory(name, image, code, commission) {
        try {
            const token = localStorage.getItem('token');

            const response = await CategoriesService.addCategory(token, name, image, code, commission);
            return response.data;
        } catch (error) {
            console.error('Error adding category :', error.message);
            throw error;
        }
    },

    async editCategory(name, image, id, code, commission) {
        try {
            const token = localStorage.getItem('token');

            const response = await CategoriesService.editCategory(token, name, image, id, code, commission);
            return response.data;
        } catch (error) {
            console.error('Error edit category :', error.message);
            throw error;
        }
    },
    async editSubCategory(name, image, id, code) {
        try {
            const token = localStorage.getItem('token');
            console.log('id is ' + id + code)
            const response = await CategoriesService.editSubCategory(token, name, image, id, code);
            return response.data;
        } catch (error) {
            console.error('Error edit category :', error.message);
            throw error;
        }
    },
    async addBanner(name, image, categoryId, subCategoryId, icon, isActive, hours) {
        try {
            const token = localStorage.getItem('token');

            const response = await CategoriesService.addBanner(token, name, image, categoryId, subCategoryId, icon, isActive, hours);
            return response.data;
        } catch (error) {
            console.error('Error adding category :', error.message);
            throw error;
        }
    },
    async editBanner(name, image, id, icon, isActive, hours) {
        try {
            const token = localStorage.getItem('token');

            const response = await CategoriesService.editBanner(token, name, image, id, icon, isActive, hours);
            return response.data;
        } catch (error) {
            console.error('Error edit category :', error.message);
            throw error;
        }
    },

    async addSubCategory(id, name, image, code,) {
        try {
            const token = localStorage.getItem('token');

            const response = await CategoriesService.addSubCategory(token, name, image, id, code,);
            return response.data;
        } catch (error) {
            console.error('Error adding subcategory :', error.message);
            throw error;
        }
    },
    async addSubSubCategory(id, name, image, code, drag, trade,catId, isMain) {
        try {
            const token = localStorage.getItem('token');

            const response = await CategoriesService.addSubSubCategory(token, name, image, id, code, drag, trade,catId, isMain);
            return response.data;
        } catch (error) {
            console.error('Error adding subcategory :', error.message);
            throw error;
        }
    }, async editSubSubCategory(id, name, image, code, drag, trade) {
        try {
            const token = localStorage.getItem('token');

            const response = await CategoriesService.editSubSubCategory(token, name, image, id, code, drag, trade);
            return response.data;
        } catch (error) {
            console.error('Error adding subcategory :', error.message);
            throw error;
        }
    },
};
