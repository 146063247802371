export default {
    greeting: 'Hello',
    loginTitle: 'Log in to the Golden Platform',
    email: 'Email',
    emailValidation: 'The email entered is invalid',
    emailRequired: 'Please enter your email',
    password: 'Password',
    passwordValidation: 'Password is less than 8 characters',
    passwordRequired: 'Please enter your password',
    login: 'Login',
    Statistics: "Statistics",
    StatisticsRoute: "Application-specific statistics and reports",
    IntermediateStoresRoute: "Arrangement of intermediate stores",
    ProductsRoute: "Products available within the application",
    CategoriesRoute: "Basic Categories",
    mostStoresProvideInspection: "The 5 most stores that provide inspection services",
    TopBestSellingMerchants: "Top 5 best selling merchants",
    IntermediateStores: "Intermediate Store",
    MostActiveUsers: "The 5 most active users",
    AllUsers: "All Users",
    Products: "Products",
    Categories: "Categories",
    PublicPolicies: "Public Policies",
    Notifications: "Notifications",
    Messages: "Messages",
    SendGroupMessage: "Send a group message",
    ExportReportsIntermediaryStores: "Export reports related to intermediary stores",
    ExportReportsForSales: "Export reports for sales merchants",
    ExportReportsActiveUsers: "Export reports regarding active users",
    ExportProducts: "Export reports regarding products",

    SendGroupMessagePopUp: "Send a group message through the application",
    uploadFile: "Upload File",
    uploadFileTitle: "  Upload File",
    or: "Or",
    send: "Send",
    filter: "Filter",
    sort: "Sort",
    filterIntermiadateStore: "Filter of intermediate stores",
    StoreCommission: "Store Commission",
    Region: "Region",
    City: "City",
    District: "District",
    Search: "Search",
    FromHighestToLowest: "From Highest To Lowest",
    FromLowestToHighest: "From Lowest To Highest",
    FromNewestToOldest: "From Newest To Oldest",
    FromOldestToNewest: "From Oldest To Newest",
    FromMostToLeast: "From Most To Least",
    FromLeastToMost: "From Least To Most",
    SortByPrice: "Sort By Price",
    SortByRegistrationDate: "Sort By Registration Date",
    SortByBestSellers: "Sort By Best Sellers",
    RegisteredIntermediateStores: "Registered Intermediate Stores Within the Application",
    StoreName: "Store Name",
    StoreName2: "Store Name",
    emailStore: "Email",
    ByWhatsapp: "By WhatsApp",
    BySMS: "By SMS",
    AddStoreTitle: "Add intermediate store",
    phoneNumber: "Phone number",
    CheckValue: "Check value",
    AboutStore: "About the store",
    Address: "Address",
    Governorate: "Governorate",
    StoreLogo: "Store logo",
    add: "Add",
    Street: "Street",
    StoreVerification: "(Store account verification)",
    NationalId: "National Id",
    CommercialRegister: "Commercial Register",
    CommercialRegisterImage: "Commercial Register Image",
    License: "License",
    LicenseImage: "License Image",
    ExportedReports: "Exported reports from the store",
    EditStoreInformation: "Edit store information",
    DeleteStoreAccount: "Delete store account",
    BlockStoreAccount: "Block store account",
    UnblockAccount: "Unblock account",
    UserAds: "User ads",
    UserSales: "User sales",
    UserPurchases: "User Purchases",
    BlockAccount: "Block account",
    ProductsTitle: "Products available within the application",
    productSearch: "The product number you want to search for",
    SortProducts: "Sort products",
    FilterProducts: "Filter products",
    SortPublicationDate: "Sort by publication date",
    productCategory: "Product Category",
    productSubCategory: "Product Subcategory",
    CaliberPiece: "Caliber Piece",
    weight: "weight",
    from: "From",
    to: "To",
    price: "Price",
    productDetails: "Product Details",
    manufacturer: "Manufacturer",
    itemAge: "Item Age",
    ItemPrice: "Item Price",
    gramPrice: "gramPrice",
    itemWeight: "Item weight",
    itemCaliber: "item caliber",
    receivingInformation: "Receiving Information",
    Sold: "Sold",
    BuyerInformation: "Buyer information",
    amountPaid: "Amount paid",
    PaymentMethod: "payment method",
    Ratings: "Ratings",
    SellerRating: "Seller Rating",
    BuyerRating: "Buyer Rating",
    ProductRating: "Product Rating",
    ServiceRating: "Service Rating",
    Reports: "Reports",
    EditProductInformation: 'Edit Product Information',
    DeleteProduct: 'Delete Product',
    AddProduct: 'Add Product',
    ProductDesc: 'Describe the product.',
    ProductAge: 'Product age',
    ProductWeight: 'Product weight',
    ProductType: 'Product Type',
    ProductCategory: 'Product category',
    CurrentPricePerGram: 'Current price per gram',
    AddedProfitValue: 'Added profit value',
    PlatformCommissionPerGram: 'Platform commission per gram',
    AdditionalAmountIfAny: 'Additional amount, if any',
    ExplanationOfTheAdditionalAmount: 'Explanation of the additional amount',
    ThePriceOfATotalGram: 'The price of a total gram',
    TotalPricePerPiece: 'Total price per piece',
    GoldKarat: 'Gold karat',
    Industry: 'Industry',
    International: 'International',
    Local: 'Local',
    PieceOffer: 'Offer on piece',
    OfferText: 'Offer text',
    ValueOfTheDiscount: 'The value of the discount, if any',
    FixedAtTheCurrentPrice: 'Fixed at the current price (if the price is fixed, the price will not\n' +
        'It changes with the price of gold)',
    UploadProductImages: 'Upload product images',
    ChooseDeliveryMethod: 'Choose the delivery method',
    HandInHandWithoutFees: 'Hand in hand without fees',
    IntermediaryStoreIncludeFees: 'An intermediary store may include fees borne by the seller',
    BackupPhone: 'Backup phone number in case the original number is not available:',
    DealtWithOfficiallyAndReceipt: 'These numbers will be dealt with officially and receipt and delivery messages will be sent to them',
    ChooseMiddleStore: 'Choose the middle store',
    IntermediaryShopAgreement: 'An intermediary shop within an agreement between the seller and the buyer',
    BrokerStorePlatform: 'Broker store within the platform',
    SelectCity: 'Select city',
    ChooseNeighborhood: 'Choose the neighborhood',
    CommissionRate: 'Commission rate',
    SearchStoreName: 'Search for the store name',
    MainCategories: 'Main Categories',
    SubCategoriesFor: 'Sub Categories for',
    AddMainCategories: 'Add Main Categories',
    CategoryName: 'Category Name',
    CategoryImg: 'Category Img',
    GoldBannerOffers: ' banner advertising offers',
    AddOfferBanner: 'Add an offer within the advertising banner',
    OfferImage: 'Offer Image',
    PrivacyPolicy: 'Privacy Policy',
    PaymentPolicy: 'Payment policy',
    ContractPolicy: 'The contract between the platform and the merchant',
    whoAreWe: 'who are we',
    PlatformCommission: 'Platform commission',
    Save: 'Save',
    IncomingMessages: 'Incoming Messages',
    TypeReplay: 'Type a replay',
    AddSubCategory: 'Add Sub Category',
    Logout: 'Logout',
    bankAccount: 'Bank Account',
    do_you_want_to_delete_the: 'Do you want to delete the',
    EditMainCategories: 'EditMainCategories',
    do_you_want_to_delete_the_type: 'Do you want to delete the type',
    orders: 'Orders',
    EditSubCategories: 'EditSubCategories',
    Book: 'Book',
    Problems: 'Problem types',
    AddProblems: 'Add problems type',
    EditProblem: 'Edit problems type',
    ProblemName: 'Type name',


}
