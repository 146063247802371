<template>
  <div>
    <div class="row">
      <div class="route-title">{{
          $t(this.route)
        }}
      </div>
      <div class="button-container">
        <button
            :class="{ 'active': this.$route.name === 'FilterProductsBody' }"
            @click="OpenFilterOption"
        >
          {{ $t('filter') }}
        </button>
        <div class="divider"></div>
        <button
            :class="{ 'active': this.$route.name === 'SortpProductsBody' }"
            @click="OpenSortOption"
        >
          {{ $t('sort') }}
        </button>

      </div>


    </div>
<div class="page-scrollable">
  <router-view/>

</div>  </div>

</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ProductsFilterPage",
  data() {
    return {
      route: 'SortProducts'

    };
  },
  methods: {
    OpenFilterOption() {
      this.$router.push({name: 'FilterProductsBody'});
      this.route = 'FilterProducts'
    },
    goBack() {
      this.navigateBackUntilS();
    },
    navigateBackUntilS() {
      const currentRoute = this.$route.name;
      console.log(currentRoute)
      if (currentRoute !== 'ProductsResults') {
        this.$router.go(-1)
        setTimeout(this.navigateBackUntilS, 100);
      } else {
        return
      }
    },
    OpenSortOption() {
      this.$router.push({name: 'SortpProductsBody'});
      this.route = 'SortProducts'

    },
  }

}
</script>

<style scoped src="../styles/SortBody.css">

</style>