<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name :'Divider',
}
</script>

<template>
  <div class="divider"></div>
</template>

<style scoped>
.divider {
  height: 1px;
  background-color: #707070;
  width: 21vw;}
</style>