<template>
  <div>
    <div class="page-scrollable">
      <router-view></router-view>

    </div>
    <GroupMessagePopUp v-if="showGroupPopUp" ref="GroupMessagePopUpRef" @close-popup="closeGroupPopUp"/>

  </div>
</template>
<script>

import {GroupMessage} from "@/util/constants";
import GroupMessagePopUp from "@/features/statistics/presentation/components/GroupMessagePopUp.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Statistics",


  components: {

    GroupMessagePopUp
  },

  data() {
    return {
      showGroupPopUp: false,

      GroupMessage: GroupMessage,
      exportList: [
        'ExportReportsIntermediaryStores',
        'ExportReportsForSales',
        'ExportReportsActiveUsers'
      ],
      items: [
        1, 2, 3, 4, 5
      ],
      items2: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15
      ]
    }
  },
  methods: {
    pressUser() {
      this.$router.push({name: 'UserDetails'});
    },
    closeGroupPopUp() {
      this.showGroupPopUp = false;
    },
    openPopup() {
      this.showGroupPopUp = true;
      if (this.$refs.GroupMessagePopUpRef && this.$refs.GroupMessagePopUpRef.openPopup) {
        this.$refs.GroupMessagePopUpRef.openPopup();
      }
    },

  },

}
</script>
<style scoped src="../styles/Statistics.css">

</style>