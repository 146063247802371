import ProblemsService from '../services/ProblemsService';

export default {

    async getAllProblems() {
        try {
            const token = localStorage.getItem('token');

            const response = await ProblemsService.fetchProblems(token);
            return response.data;
        } catch (error) {
            console.error('Error fetching policies :', error.message);
            throw error;
        }
    },

    async AddProblem(name) {
        try {
            const token = localStorage.getItem('token');

            const response = await ProblemsService.addProblem(token, name);
            return response.data;
        } catch (error) {
            console.error('Error post policies :', error.message);
            throw error;
        }
    },

    async EditProblem(name, id) {
        try {
            const token = localStorage.getItem('token');

            const response = await ProblemsService.editProblem(token, name, id);
            return response.data;
        } catch (error) {
            console.error('Error post policies :', error.message);
            throw error;
        }
    },


    async DeleteProblem(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await ProblemsService.deleteProblem(token, id);
            return response.data;
        } catch (error) {
            console.error('Error post policies :', error.message);
            throw error;
        }
    },
};
