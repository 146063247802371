<template>
  <div :class="{ 'contact-card': true, 'selected': selected }" @click="$emit('toggle-selected')">
    <div class="contact-info">
      <img :src="ImageUrl(contact)" alt="Profile Image" class="profile-image">
      <div class="contact-details">
        <div class="title">{{ contact.user.first_name }} {{ contact.user.last_name }}</div>
        <div class="last-message">{{ (contact_description) ? contact_description : contact.description }}</div>
      </div>
    </div>
    <p class="last-message-time">{{ formattedDateTime }}</p>
  </div>
</template>

<script>
import {format} from "date-fns";
import {ImageUrl} from "@/util/constants";
// import {description} from "vue2-google-maps/docs/.vuepress/config";

export default {
  name: "ContactCard",
  methods: {
    ImageUrl(contact) {
      console.log(ImageUrl + 'storage/' + contact.user.photo)
      return ImageUrl + contact.user.photo
    }
  },

  props: {
    contact: {
      type: Object,
      required: true
    },
    contact_description: {
      type: String,
      required: false
    },
    selected: {
      type: Boolean,
      required: true
    }
  },
  computed: {

    formattedDateTime() {
      const date = new Date(this.contact.created_at);

      let hours = date.getHours();
      let minutes = date.getMinutes();
      hours = hours.toString().padStart(2, '0');
      minutes = minutes.toString().padStart(2, '0');

      const formattedTime = `${hours}:${minutes}`;

      const formattedDate = format(date, "dd/MM/yyyy");

      return `${formattedTime} ${formattedDate}`;
      // const date = new Date(item);
      // const formattedDate = format(date, "dd/MM/yyyy");
      //
      // let hours = date.getHours();
      // let minutes = date.getMinutes();
      // let ampm = hours >= 12 ? 'PM' : 'AM';
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      //
      // const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} `;
      //
      // return ` ${formattedDate} ${formattedTime} ${ampm}`;
    },
  },
}

</script>

<style scoped>
.contact-card {
  background-color: #fff;
  padding: 10px;
  //box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.contact-card:hover {
  background-color: rgba(230, 184, 0, 0.05);
}

.last-message {
  white-space: nowrap;
  overflow: hidden;
  font-size: 13px;
  max-width: 8vw;
  text-align: start;
  text-overflow: ellipsis;
}

.contact-card.selected {
  background-color: rgba(230, 184, 0, 0.1);
}

.contact-info {
  display: flex;
  align-items: center;
}

.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.contact-details {
  flex-grow: 1;
  margin-right: 10px;
}

.title {
  font-weight: bold;
}

.last-message-time {
  color: #888;
  font-size: 9px;
  position: relative;
  bottom: -3vh;
  width: 6vw;
}

@media screen and (max-width: 768px) {
  .profile-image {
    width: 25px;
    height: 25px;

  }

  .title {
    font-size: 9px;
  }

  .last-message {
    white-space: nowrap;
    overflow: hidden;
    font-size: 10px;
    max-width: 8vw;
    text-align: start;
    text-overflow: ellipsis;
  }

  .last-message-time {
    color: #888;
    font-size: 6px;
    position: relative;
    bottom: -3vh;
    width: 6vw;
  }
}

</style>
