<template>
  <div class="page-scrollable">  <div class="filter">
    <div STYLE=" padding: 16px 0px;">

      <div class="row">
        <div class="select-title">
          <label for="selectItem"> {{ $t('productCategory') }}</label>
        </div>
        <select id="selectItem" v-model="selectedItem1" @change="getSubCategories">
          <option v-for="(item, index) in items" :key="index" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="row">
        <div class="select-title">
          <label for="selectItem"> {{ $t('productSubCategory') }}</label>

        </div>

        <select id="selectItem" v-model="selectedItem2">
          <option v-for="(item, index) in items1" :key="index" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>

      <div class="row">
        <div class="select-title">
          <label for="selectItem"> {{ $t('CaliberPiece') }}</label>

        </div>

        <select id="selectItem" v-model="carat">
          <option v-for="(item, index) in items3" :key="index" :value="item">
            {{ item }}
          </option>
        </select>
      </div>
      <div class="row">
        <div class="select-title">
          <label for="selectItem"> حالة المنتج</label>

        </div>

        <select id="selectItem" v-model="status">
          <option v-for="(item, index) in items4" :key="index" :value="item.type">
            {{ item.name }}
          </option>
        </select>
      </div>

      <!--      <div class="row">-->
      <!--        <div class="select-title">-->
      <!--          <label for="selectItem2"> {{ $t('CaliberPiece') }}</label>-->
      <!--        </div>-->
      <!--        <input id="selectItem" v-model="carat">-->
      <!--      </div>-->

      <div class="row2">
        <div style="display: flex">
          <div class="select-title">
            <label for="selectItem2"> {{ $t('price') }}</label>
            <label class="select2" for="selectItem"> {{ $t('from') }}</label>

          </div>
          <input id="selectItem" v-model="fromPrice">
        </div>
        <div class="rooww">
          <div class="select-title2">
            <label class="select2" for="selectItem"> {{ $t('to') }}</label>
          </div>

          <input id="selectItem" v-model="toPrice">
        </div>
      </div>
      <div class="row2">
        <div style="display: flex">
          <div class="select-title">
            <label for="selectItem2"> {{ $t('weight') }}</label>
            <label class="select2" for="selectItem"> {{ $t('from') }}</label>

          </div>
          <input id="selectItem" v-model="fromWeight"></div>
        <div class="rooww">
          <div class="select-title2">
            <label class="select2" for="selectItem"> {{ $t('to') }}</label>
          </div>

          <input id="selectItem" v-model="toWeight">
        </div>
      </div>


    </div>
    <div class="image-container">
      <div class="image" @click="sendData1">
        <span class="button-text">{{ $t("Search") }}</span>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import ProductsViewModel from "@/features/products/viewmodels/ProductsViewModel";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "FilterProductsBody",
  data() {
    return {
      selectedItem1: '',
      selectedItem2: '',
      carat: '',
      status: '',
      fromPrice: '',
      toWeight: '',
      fromWeight: '',
      toPrice: '',
      items: [],
      items1: [],
      items3: [],
      items4: [
        {name: 'جديد', type:3},
        {name: 'شبه جديد', type: 2},
        {name: 'مستعمل', type: 1}

      ]

    };
  },
  created() {
    this.getCategories()
    this.getCarats()

  },
  methods: {
    async getSubCategories() {
      try {
        this.items1 = []
        const data = await ProductsViewModel.getAllSubCategories(this.selectedItem1);
        console.log(data)
        this.items1 = data.data;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading = false;

      }
    },
    async getCarats() {
      try {
        this.items3 = []
        const data = await ProductsViewModel.getAllCarats();
        console.log(data)
        this.items3 = data.data;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading = false;

      }
    },
    async getCategories() {
      try {
        this.items1 = []
        const data = await ProductsViewModel.getAllCategories();
        console.log(data)
        this.items = data.data;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading = false;

      }
    },
    sendData1() {
      this.$router.push({
        name: 'ProductsResults',
        query: {
          cat: this.selectedItem1,
          subCat: this.selectedItem2,
          carat: this.carat,
          fromPrice: this.fromPrice,
          toPrice: this.toPrice,
          fromWeight: this.fromWeight,
          toWeight: this.toWeight,
          state: this.status,


        },
      });
    }
  }
}
</script>
<style scoped src="../presentation/styles/FilterBody.css">

</style>