<template>
 <div class="export-row">
<div class="export-title">{{$t(title)}}</div>
   <div class="icon" v-html="ExportPDF()"></div>

 </div>
</template>

<script >

import {ExportPDF} from "@/util/constants";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ExportPDF",
  methods: {
    ExportPDF() {
      return ExportPDF
    }
  },
  props: {
    title: {
      type: String,
      required: true
    }
  }}
</script>


<style scoped src="../styles/ExportPDF.css">

</style>