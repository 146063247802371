import OrdersService from '../services/OrdersService';

export default {
    async getAllReservations() {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.getAllReservations(token);
            return response.data;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    },

    async getOrderDetails(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.getOrderDetails(token, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },
    async addNote(id, message) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.addNotes(token, id, message);
            return response.data;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },
    async confirmOrder(id,price) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.confirmOrder(token, id,price);
            return response;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },
    async rejectOrder(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await OrdersService.rejectOrder(token, id);
            return response;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },
};
