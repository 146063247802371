<template>
  <div class="row2">
    <div class="select-title">
      <label for="selectItem"> {{ $t('ChooseMiddleStore') }}</label>
    </div>
    <div>

      <div style="display: flex"><input
          type="checkbox"
          v-model="isChecked1"
          @change="handleCheck(1)"
      />
        <div class="select-title2">
          <label for="selectItem"> {{ $t('IntermediaryShopAgreement') }}</label>
        </div>
      </div>

      <br/>
      <div style="display: flex">
        <input
            type="checkbox"
            v-model="isChecked1"
            @change="handleCheck(1)"
        />
        <div class="select-title2">
          <label for="selectItem"> {{ $t('BrokerStorePlatform') }}</label>
        </div>
      </div>

    </div>

  </div>
  <div class="body">
    <div style="display: flex;">
      <div style="margin-left: 30px">
        <select v-model="selectedItem2" @change="handleChange" id="items">
          <option style="    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
" disabled value="">{{ $t('SelectCity') }}
          </option>
          <option v-for="item in items2" :key="item.id" :value="item.id">{{ item.name }}</option>
        </select>
      </div>
      <div>
        <select v-model="selectedItem3" @change="handleChange" id="items">
          <option style="    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
" disabled value="">{{ $t('ChooseNeighborhood') }}
          </option>
          <option v-for="item in items3" :key="item.id" :value="item.id">{{ item.name }}</option>
        </select>
      </div>

    </div>
    <div class="row5">
      <div class="select-title4">
        <label for="selectItem"> {{ $t('CommissionRate') }}</label>
      </div>
      <div style="display: flex;">
        <input id="selectItem" style="margin-left: 20px" :placeholder="$t('from')">
        <input id="selectItem" :placeholder="$t('to')">
      </div>
    </div>
    <div class="row5" style="justify-content: space-between">
      <input
          type="text"
          v-model="isChecked1"
          @change="handleCheck(1)"
          :placeholder=" $t('SearchStoreName') "
      />
      <button class="gradient-button">{{ $t('Search') }}</button>

    </div>
    <div class="grid-container1">
      <div v-for="store in stores" :key="store.id" class="store-card">
        <h2 style="color: #000;">{{ store.name }}</h2>
        <h3 style="color: #E6B800;">{{ store.price }} ريال خدمة الفحص</h3>
        <p>{{ store.address }}</p>
      </div>
    </div>

  </div>

</template>
<script>

export default {
  name: "StoreContainer",
  data() {
    return {
      stores: [
        {id: 1, name: 'اسم المتجر', price: 50, address: 'المدينة المنورة , حي النبلاء , شارع فارس الخوري'},
        {id: 2, name: 'اسم المتجر', price: 50, address: 'المدينة المنورة , حي النبلاء , شارع فارس الخوري'},
        {id: 3, name: 'اسم المتجر', price: 50, address: 'المدينة المنورة , حي النبلاء , شارع فارس الخوري'},
        {id: 3, name: 'اسم المتجر', price: 50, address: 'المدينة المنورة , حي النبلاء , شارع فارس الخوري'},
        {id: 3, name: 'اسم المتجر', price: 50, address: 'المدينة المنورة , حي النبلاء , شارع فارس الخوري'},
        {id: 3, name: 'اسم المتجر', price: 50, address: 'المدينة المنورة , حي النبلاء , شارع فارس الخوري'},
        {id: 3, name: 'اسم المتجر', price: 50, address: 'المدينة المنورة , حي النبلاء , شارع فارس الخوري'},
        {id: 3, name: 'اسم المتجر', price: 50, address: 'المدينة المنورة , حي النبلاء , شارع فارس الخوري'},
        {id: 3, name: 'اسم المتجر', price: 50, address: 'المدينة المنورة , حي النبلاء , شارع فارس الخوري'},
        {id: 3, name: 'اسم المتجر', price: 50, address: 'المدينة المنورة , حي النبلاء , شارع فارس الخوري'},
        {id: 3, name: 'اسم المتجر', price: 50, address: 'المدينة المنورة , حي النبلاء , شارع فارس الخوري'},
        {id: 3, name: 'اسم المتجر', price: 50, address: 'المدينة المنورة , حي النبلاء , شارع فارس الخوري'},


      ],
      selectedItem2: '',
      selectedItem3: '',

      items1: ['a'],
      items2: ['b']

    }
  }

}
</script>

<style scoped src="../presentation/styles/AddProduct.css">

</style>