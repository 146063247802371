import StatisticsService from '../services/StatisticsService';

export default {
    async getTopStores() {
        try {
            const token = localStorage.getItem('token');

            const response = await StatisticsService.getTopStores(token);
            return response.data;
        } catch (error) {
            console.error('Error fetching stores:', error.message);
            throw error;
        }
    },

    async sendMessage( name , image) {
        try {
            const token = localStorage.getItem('token');

            const response = await StatisticsService.sendGroupMessage(token,name,image);
            return response.data;
        } catch (error) {
            console.error('Error adding category :', error.message);
            throw error;
        }
    },
    async getTopSellers() {
        try {
            const token = localStorage.getItem('token');

            const response = await StatisticsService.getTopSellers(token);
            return response.data;
        } catch (error) {
            console.error('Error fetching sellers:', error.message);
            throw error;
        }
    },
    async getTopUsers() {
        try {
            const token = localStorage.getItem('token');

            const response = await StatisticsService.getTopUsers(token);
            return response.data;
        } catch (error) {
            console.error('Error fetching sellers:', error.message);
            throw error;
        }
    },
    async getAllUsers() {
        try {
            const token = localStorage.getItem('token');

            const response = await StatisticsService.getAllUsers(token);
            return response.data;
        } catch (error) {
            console.error('Error fetching sellers:', error.message);
            throw error;
        }
    },
    async getUserDetails(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await StatisticsService.getUserDetails(token,id);
            return response.data;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },

    async blockUser(id) {
        try {
            const token = localStorage.getItem('token');
            const response = await StatisticsService.BlockUser(token, id);
            return response.data;
        } catch (error) {
            console.error('Error blocking user:', error.message);
            throw error;
        }
    },

};
