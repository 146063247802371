<template>
  <div>
    <div class="page-scrollable">

      <router-view></router-view>

    </div>
  </div>

</template>
<script>



export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "IntermediateStores",
  components: {},
  data() {
    return {

    };
  },
  methods: {

  },
}
</script>
<style scoped src="../styles/IntermidiateStores.css">

</style>