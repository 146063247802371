<template>
  <div CLASS="sort">

    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('SortByPrice') }}</label>
      </div>
      <div>
        <div class="check-box">
          <input
              type="checkbox"
              v-model="isChecked1"
              @change="handleCheck(1)"
          />
          {{ $t('FromHighestToLowest') }}
        </div>
        <br/>
        <div class="check-box">
          <input
              type="checkbox"
              v-model="isChecked2"
              @change="handleCheck(2)"
          />
          {{ $t('FromLowestToHighest') }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('SortByRegistrationDate') }}</label>
      </div>
      <div>
        <div class="check-box">
          <input
              type="checkbox"
              v-model="isChecked3"
              @change="handleCheck(3)"
          />
          {{ $t('FromNewestToOldest') }}
        </div>
        <br/>
        <div class="check-box">
          <input
              type="checkbox"
              v-model="isChecked4"
              @change="handleCheck(4)"
          />
          {{ $t('FromOldestToNewest') }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('SortByBestSellers') }}</label>
      </div>
      <div>
        <div class="check-box">
          <input
              type="checkbox"
              v-model="isChecked5"
              @change="handleCheck(5)"
          />
          {{ $t('FromMostToLeast') }}
        </div>
        <br/>
        <div class="check-box">
          <input
              type="checkbox"
              v-model="isChecked6"
              @change="handleCheck(6)"
          />
          {{ $t('FromLeastToMost') }}
        </div>
      </div>
    </div>
    <div class="image-container">
      <div class="image" @click="goToProducts">
        <span class="button-text">{{ $t("Search") }}</span>
      </div>
    </div>
  </div>


</template>


<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "SortBody",
  data() {
    return {
      isChecked1: false,
      isChecked2: false,
      isChecked3: false,
      isChecked4: false,
      isChecked5: false,
      isChecked6: false,
    };
  },
  methods: {
    handleCheck(checkboxNumber) {
      if (checkboxNumber === 1 && this.isChecked1) {
        this.isChecked2 = false;
      } else if (checkboxNumber === 2 && this.isChecked2) {
        this.isChecked1 = false;
      } else if (checkboxNumber === 3 && this.isChecked3) {
        this.isChecked4 = false;
      } else if (checkboxNumber === 4 && this.isChecked4) {
        this.isChecked3 = false;
      } else if (checkboxNumber === 5 && this.isChecked5) {
        this.isChecked6 = false;
      } else if (checkboxNumber === 6 && this.isChecked6) {
        this.isChecked5 = false;
      }
    },
    goToProducts() {
      let cost = null
      let created = null
      let sales = null

      if (this.isChecked1) {
        cost = 1;
      } else if (this.isChecked2) {
        cost = 0;
      }
      if (this.isChecked3) {
        sales = 1;
      } else if (this.isChecked4) {
        sales = 0;
      }
      if (this.isChecked5) {
        created = 1;
      } else if (this.isChecked6) {
        created = 0;
      }
      this.$router.push({name: 'StoreResultsBody', query: {cost: cost,sales: sales, created: created},});
    },
  }

}
</script>

<style scoped src="../styles/SortBody.css">

</style>