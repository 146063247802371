<template>
  <div class="page-scrollable">
    <div class="row">
      <div class="route-title">{{ $t('AddProduct') }}</div>

    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('ProductDesc') }}</label>
      </div>
      <input id="selectItem">
    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('ProductAge') }}</label>
      </div>
      <input id="selectItem">
    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('ProductWeight') }}</label>
      </div>
      <input id="selectItem">
    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('ProductType') }}</label>

      </div>

      <select id="selectItem" v-model="selectedItem2">
        <option v-for="(item, index) in items" :key="index" :value="item">
          {{ item }}
        </option>
      </select>
    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('ProductCategory') }}</label>

      </div>

      <select id="selectItem" v-model="selectedItem2">
        <option v-for="(item, index) in items" :key="index" :value="item">
          {{ item }}
        </option>
      </select>
    </div>
    <div class="row">
      <div class="select-title3">
        <label for="selectItem"> {{ $t('CurrentPricePerGram') }}</label>
      </div>
      <input id="selectItem">
    </div>
    <div class="row">
      <div class="select-title3">
        <label for="selectItem"> {{ $t('AddedProfitValue') }}</label>
      </div>
      <input id="selectItem">
    </div>
    <div class="row">
      <div class="select-title3">
        <label for="selectItem"> {{ $t('PlatformCommissionPerGram') }}</label>
      </div>
      <input id="selectItem">
    </div>

    <div class="row">
      <div class="select-title3">
        <label for="selectItem"> {{ $t('AdditionalAmountIfAny') }}</label>
      </div>
      <input id="selectItem">
    </div>

    <div class="row">
      <div class="select-title3">
        <label for="selectItem"> {{ $t('AdditionalAmountIfAny') }}</label>
      </div>
      <input id="selectItem">
    </div>

    <div class="row">
      <div class="select-title3">
        <label for="selectItem"> {{ $t('ExplanationOfTheAdditionalAmount') }}</label>
      </div>
      <textarea type="text" class="custom-input" v-model="groupName" rows="5"/>
    </div>

    <div class="row">
      <div class="select-title3">
        <label for="selectItem"> {{ $t('ThePriceOfATotalGram') }}</label>
      </div>
      <input id="selectItem">
    </div>

    <div class="row">
      <div class="select-title3">
        <label for="selectItem"> {{ $t('TotalPricePerPiece') }}</label>
      </div>
      <input id="selectItem">
    </div>

    <div class="row">
      <div class="select-title3">
        <label for="selectItem"> {{ $t('GoldKarat') }}</label>
      </div>
      <input id="selectItem">
    </div>
    <div class="row">
      <div class="select-title3">
        <label for="selectItem"> {{ $t('manufacturer') }}</label>
      </div>
      <input id="selectItem">
    </div>

    <div class="row">
      <div class="select-title3">
        <label for="selectItem"> {{ $t('Industry') }}</label>
      </div>
      <div class="button-container-check">
        <button class="button" :class="{ selected: selectedButton === 1 }" @click="selectButton(1)">{{
            $t('Local')
          }}
        </button>
        <button class="button" :class="{ selected: selectedButton === 2 }" @click="selectButton(2)">
          {{ $t('International') }}
        </button>
      </div>
    </div>
    <div class="row2">
      <input
          type="checkbox"
          v-model="isChecked1"
          @change="handleCheck(1)"
      />
      <div class="select-title2">
        <label for="selectItem"> {{ $t('PieceOffer') }}</label>
      </div>

    </div>

    <div class="row">
      <div class="select-title3">
        <label for="selectItem"> {{ $t('OfferText') }}</label>
      </div>
      <textarea type="text" class="custom-input" v-model="groupName" rows="5"/>
    </div>

    <div class="row">
      <div class="select-title3">
        <label for="selectItem"> {{ $t('ValueOfTheDiscount') }}</label>
      </div>
      <div class="percent">
        <span class="percent-symbol">%</span>
        <input id="selectItem" class="percent-input">
      </div>
    </div>
    <div class="row2">
      <input
          type="checkbox"
          v-model="isChecked1"
          @change="handleCheck(1)"
      />
      <div class="select-title2">
        <label for="selectItem"> {{ $t('FixedAtTheCurrentPrice') }}</label>
      </div>

    </div>
    <div class="upload-file-container">

      <div class="upload-file">
        <label for="fileInput" class="upload-label">
          <div class="upload-content">
            <div class="icon" v-html="uploadIcon"></div>
            <div>{{ $t('UploadProductImages') }}</div>

          </div>
        </label>
        <input
            id="fileInput"
            type="file"
            class="file-input"
            style="display: none;"
            @change="handleFileUpload"
        />
      </div>
    </div>
    <div style="width: 80vw;  display: flex; justify-content: center;" v-if="selectedFileName.length!==0">
      <div class="row-remove">
                <span class="name" v-for="(file, index) in selectedFileName" :key="index">{{ file }}
                <div style="cursor: pointer" @click="removeFile">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="remove-icon-svg"
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"/>
                    <line x1="6" y1="6" x2="18" y2="18"/>
                  </svg>
                </div>
                  </span>
      </div>
    </div>
    <div class="image-container">
      <div class="image" @click="onPress">
        <span class="button-text">{{ $t("add") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import {AddIcon} from "@/util/constants";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "AddProduct",
  data() {
    return {
      phoneNumbers: [{value: ""}],
      items: [
        {label: this.$t('Region')},
        {label: this.$t('Governorate')},
        {label: this.$t('City')},
        {label: this.$t('District')},
        {label: this.$t('Street')},
      ],
      selectedFileName: [],
      selectedButton: 1,
      selectedFiles: [],

    };
  }, methods: {
    selectButton(buttonNumber) {
      this.selectedButton = buttonNumber;
    },
    handleFileUpload(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        this.selectedFiles.push(files[i]);
        this.selectedFileName.push(files[i].name)

      }
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1);
      this.selectedFileName.splice(index, 1);

    },
    onPress() {
      this.$router.push({name: 'AddProductInfo'});

    },
    AddIcon() {
      return AddIcon
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFileName = file.name;
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result;
          console.log("Uploaded image URL:", imageUrl);
        };
        reader.readAsDataURL(file);
      }
    },
    addPhoneNumber() {
      if (this.phoneNumbers.length < 3) {
        this.phoneNumbers.push({value: ""});
      }
    },
  }
}
</script>

<style scoped src="../styles/AddProduct.css">

</style>