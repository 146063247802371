<template>
  <div class="shop-card" @click="openPDF">
    <div class="circle"
         v-html="UserReportIcon()"></div>

    <div class="card-title"> المنتج رقم {{ fileData.code }}</div>
    <div class="card-title2">{{ formattedDate }}</div>
  </div>
</template>

<script>
import {Statistics, UserReportIcon} from "@/util/constants";
import {format} from "date-fns";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'ReportCard',
  props: {
    fileData: Object,

  },
  data() {
    return {
      pdfUrl: "https://www.africau.edu/images/default/sample.pdf",
    };
  },
  computed: {
    formattedDate() {
      const date = new Date(this.fileData.created_at);
      return format(date, "dd/MM/yyyy");
    },
  },
  methods: {
    UserReportIcon() {
      return UserReportIcon
    },
    Statistics() {
      return Statistics
    },
    openPDF() {
      this.$router.push({name: 'ProductsDetails', params: {id: this.fileData.id},});
    }
  }
}

</script>


<style scoped src="../styles/UserReportsCard.css">

</style>