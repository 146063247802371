import axios from 'axios';
import {BaseUrl} from "@/util/constants";

const Logout = 'logout'

class MainService {
    static async logout(token) {
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
        try {
            const response = await axios.post(`${BaseUrl}${Logout}`,null, {headers});
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during stores');
            }
        }
    }
}

        export default MainService;

