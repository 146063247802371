<template>
  <div class="card" @click="clickme(this.category.id)">
    <img :src="ImageUrl(this.category.image)" alt="Card Image"
         class="card-image"/>

    <div class="card-content">
      <div style="margin: 11px 0px 7px; font-weight: bold">{{ this.category.name }}</div>
      <div style="font-weight: bold; font-size: 13px">العمولة : {{ this.category.commission }}</div>

      <div class="card-icons">
        <div class="icon-left" @click.stop="openDeletePopup">
          <div v-html="DeleteIcon()"></div>
        </div>
        <div class="icon-right" @click.stop="openEdit">
          <div v-html="EditIcon()"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {DeleteIcon, EditIcon, ImageUrl} from "@/util/constants";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "CategoryCard",
  props: {
    category: Object,
  },

  methods: {

    clickme(catId) {
      this.$router.push({
        name: 'SubCategoriesData',
        params: {id: catId},
        query: {
          name: this.category.name,

        }
      });

    },
    openEdit() {
      this.$router.push({
        name: 'EditCategory',
        query: {
          category: this.category.name,
          id: this.category.id,
          code: this.category.code,
          price: this.category.commission
        }
      });

    },
    ImageUrl(image) {
      return ImageUrl + image;
    },
    EditIcon() {
      return EditIcon
    },
    DeleteIcon() {
      return DeleteIcon
    },
    openDeletePopup() {
      console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaakkkkkkkkkkkkkkd')
      this.$emit('open-popup');
    },

  },
}
</script>
<style scoped src="../styles/Categories.css">

</style>