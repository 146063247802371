<template>
  <div class="page-scrollable">
    <div class="row">
      <div class="route-title">{{ $t('AddProduct') }}</div>

    </div>

    <div class="row2">
      <div class="select-title">
        <label for="selectItem"> {{ $t('ChooseDeliveryMethod') }}</label>
      </div>
      <div>

        <div style="display: flex"><input
            type="checkbox"
            v-model="isChecked1"
            @change="handleCheck(1)"
        />
          <div class="select-title2">
            <label for="selectItem"> {{ $t('HandInHandWithoutFees') }}</label>
          </div>
        </div>

        <br/>
        <div style="display: flex">
          <input
              type="checkbox"
              v-model="isChecked1"
              @change="handleCheck(1)"
          />
          <div class="select-title2">
            <label for="selectItem"> {{ $t('IntermediaryStoreIncludeFees') }}</label>
          </div>
        </div>

      </div>
    </div>

    <StoreContainer/>

    <div class="row2">
      <div class="select-title2">
        <label for="selectItem"> {{ $t('BackupPhone') }}</label>
      </div>
    </div>
    <div class="row" style="display: flex; justify-content: center; width: 75%">

      <div class="phone-column" id="phoneNumbers">
        <input v-for="(phone, index) in phoneNumbers" :key="index" :id="'phoneNumber' + index" v-model="phone.value">
      </div>
      <div class="icon" @click="addPhoneNumber" v-if="phoneNumbers.length < 3" v-html="AddIcon()"></div>

    </div>
    <div class="row2">
      <div class="select-title2">
        <label for="selectItem"> {{ $t('DealtWithOfficiallyAndReceipt') }}</label>
      </div>
    </div>


    <div class="image-container">
      <div class="image" @click="onPress">
        <span class="button-text">{{ $t("add") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import {AddIcon} from "@/util/constants";
import StoreContainer from "@/features/products/components/StoreContainer.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "AddProductInfo",
  components: {StoreContainer},
  data() {
    return {
      phoneNumbers: [{value: ""}],
      items: [
        {label: this.$t('Region')},
        {label: this.$t('Governorate')},
        {label: this.$t('City')},
        {label: this.$t('District')},
        {label: this.$t('Street')},
      ],
      selectedFileName: [],
      selectedButton: 1,
      selectedFiles: [],

    };
  }, methods: {
    selectButton(buttonNumber) {
      this.selectedButton = buttonNumber;
    },
    handleFileUpload(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        this.selectedFiles.push(files[i]);
        this.selectedFileName.push(files[i].name)

      }
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1);
      this.selectedFileName.splice(index, 1);

    },
    onPress() {
      this.$router.push({name: 'VerifyStore'});

    },
    AddIcon() {
      return AddIcon
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFileName = file.name;
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result;
          console.log("Uploaded image URL:", imageUrl);
        };
        reader.readAsDataURL(file);
      }
    },
    addPhoneNumber() {
      if (this.phoneNumbers.length < 3) {
        this.phoneNumbers.push({value: ""});
      }
    },
  }
}
</script>

<style scoped src="../styles/AddProduct.css">

</style>