<template>
  <div>
    <div v-if="isLoading1" class="loader-container">
      <div class="loader"></div>
    </div>
    <div style="display: flex">

      <div class="page-scrollable">
        <div class="side">
          <div class="route-title">{{ $t('Messages') }}</div>
          <ContactCard
              v-for="contact in contacts"
              :key="contact.user_id"
              :contact_description="descMessage!==''&& selectedContactId === contact.user_id?descMessage:null"
              :contact="contact"
              :selected="selectedContactId === contact.user_id"
              @toggle-selected="toggleSelected(contact.user_id)"
          />
        </div>
      </div>
      <div style="    width: 59vw;">
        <div v-if="isLoading2" class="loader-container">
          <div class="loader"></div>
        </div>

        <div class="centered-text" v-if="this.selectedContactId===null">
          لم يتم تحديد دردشات
        </div>
        <div class="row" v-if="!isLoading2">

          <div class="icon">{{ name }}</div>
        </div>
        <div style="height: 72vh; overflow-y: auto" v-if="this.selectedContactId">

          <div class="chat-container" ref="messageContainer">
            <div v-for="(message, index) in messages" :key="index"
                 :class="{ 'my-message': message.admin_id===1, 'other-message': message.admin_id!==1 }">
              <div class="message-body">{{ message.description }}</div>
              <div class="message-date">{{ formattedDateTime(message.created_at) }}</div>
            </div>
            <div ref="endOfMessages"></div>
          </div>
        </div>
        <div class="message-input-container" v-if="!isLoading1 && !isLoading2">
          <input v-model="newMessage" :placeholder="$t('TypeReplay')"/>
          <button @click="sendMessage">{{ $t('send') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import ContactCard from "@/features/messages/presentation/components/ContactCard.vue";
import MessagesViewModel from "@/features/messages/viewmodels/MessagesViewModel";
import {format} from "date-fns";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Messages",
  components: {ContactCard},
  data() {
    return {
      messages: [],
      newMessage: "",
      isLoading1: true,
      isLoading2: false,
      descMessage: "",
      name: "",
      selectedContactId: null,

      contacts: []


    };
  },
  created() {
    this.fetchChatsData();

  },
  async mounted() {

  },
  methods: {
    formattedDateTime(item) {
      const date = new Date(item);

      let hours = date.getHours();
      let minutes = date.getMinutes();
      hours = hours.toString().padStart(2, '0');
      minutes = minutes.toString().padStart(2, '0');

      const formattedTime = `${hours}:${minutes}`;

      const formattedDate = format(date, "dd/MM/yyyy");

      return `${formattedTime} ${formattedDate}`;

    },
    async fetchChatsData() {

      try {
        const data = await MessagesViewModel.getAllChats();
        console.log(data)
        this.contacts = data;
        // this.toggleSelected(this.contacts[0].user_id)
        this.isLoading1 = false;
      } catch (error) {
        console.error("Error fetching messages:", error.message);
        this.isLoading1 = false;

      }
    },

    async toggleSelected(contactId) {
      try {
        this.messages = []
        // this.descMessage=''
        this.isLoading2 = true;
        this.selectedContactId = this.selectedContactId === contactId ? null : contactId;

        const data = await MessagesViewModel.getUserConversations(contactId);
        console.log(data)
        this.messages = data['conversation']['data'];
        console.log('messages are ' + this.messages)
        this.name = data['user_name']
        this.isLoading2 = false;
      } catch (error) {
        console.error("Error fetching messages:", error.message);
        this.isLoading2 = false;

      }
    },
    async sendMessage() {

      try {
        const data = await MessagesViewModel.sendMessage(this.selectedContactId, this.newMessage);
        console.log(data)
        if (this.newMessage.trim() !== "") {
          this.messages.push({
            description: this.newMessage,
            created_at: new  Date().toISOString()
            ,
            admin_id: 1,
          });
          console.log(new Date().toISOString()
          )
          this.descMessage = this.newMessage
          this.$nextTick(() => {
            this.scrollToEnd();
          });
          this.newMessage = "";
        }
        this.name = data['user_name']
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching messages:", error.message);
        this.isLoading = false;

      }


    },
    scrollToEnd() {
      const endOfMessages = this.$refs.endOfMessages;
      endOfMessages.scrollIntoView({behavior: "smooth", block: "end"});
    },
  },
}
</script>
<style scoped src="../styles/Messages.css">

</style>