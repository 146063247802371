<template>
  <div>
    <div class="page-scrollable">
      <div class="row">
        <div class="route-title">{{ $t('ProductsTitle') }}</div>
        <div class="image-container">
          <div class="image" @click="onPress1">
            <span class="button-text">المحافظات المخدمة</span>
          </div>
        </div>
      </div>

      <div class="row">
        <input
            style="font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif"
            :placeholder="$t('productSearch')"
            type="text"
            v-model="code"
            @input="searchProductData(code)"
        />

        <div class="icon2" v-html="FilterIcon()" @click="OpenFilterOptions"></div>
      </div>
      <div v-if="isLoading" class="loader-container">
        <div class="loader"></div>
      </div>
      <div v-if="this.productsItems.length===0 && !isLoading">
        <div>لا يوجد عناصر</div>
      </div>
      <div class="grid-container">
        <ProductsCard v-for="(item, index) in productsItems" :key="index" :item="item"/>
      </div>
    </div>

  </div>
</template>

<script>
import {AddIcon, FilterIcon} from "@/util/constants";
import ProductsCard from "@/features/products/components/ProductsCard.vue";
import ProductsViewModel from "@/features/products/viewmodels/ProductsViewModel";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ProductsResults",
  components: {ProductsCard},
  data() {
    return {
      productsItems: [],
      isLoading: true,
      code: ''
    };
  },
  created() {
    if (this.$route.query.cat !== undefined || this.$route.query.subCat !== undefined || this.$route.query.carat !== undefined || this.$route.query.fromPrice !== undefined || this.$route.query.toPrice !== undefined || this.$route.query.fromWeight !== undefined || this.$route.query.toWeight !== undefined|| this.$route.query.state !== undefined) {
      this.sendProductsData()
    } else if (this.$route.query.cost === undefined && this.$route.query.created === undefined) {
      this.fetchProductsData();
    } else {
      this.SortStoresData(this.$route.query.cost, this.$route.query.created);

    }
  },
  methods: {
    OpenFilterOptions() {
      this.$router.push({name: 'SortpProductsBody'});
    },

    OpenAddOptions() {
      this.$router.push({name: 'AddProduct'});
    },
    FilterIcon() {
      return FilterIcon
    },
    AddIcon() {
      return AddIcon
    },
    goToProductDetails(id) {
      this.$router.push({name: 'ProductsDetails', params: {id: id},});
    },

    async SortStoresData(cost, created) {

      try {
        const data = await ProductsViewModel.sortProducts(cost, created);
        console.log(data)
        this.productsItems = data.data;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading = false;

      }
    },
    async sendProductsData() {
      try {
        const data = await ProductsViewModel.sendProduct(this.$route.query.cat, this.$route.query.subCat, this.$route.query.carat, this.$route.query.fromPrice, this.$route.query.toPrice, this.$route.query.fromWeight, this.$route.query.toWeight, this.$route.query.state,);
        console.log(data)
        this.productsItems = data.data;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading = false;

      }
    },
    async fetchProductsData() {

      try {
        const data = await ProductsViewModel.getAllProducts();
        console.log(data)
        this.productsItems = data.data;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading = false;

      }
    },

    async searchProductData(code) {
      if (code.length === 0) {
        await this.fetchProductsData()
      } else {
        try {
          const data = await ProductsViewModel.searchProduct(code);
          console.log(data)
          this.productsItems = data.data;
          this.isLoading = false;
        } catch (error) {
          console.error("Error searching:", error.message);
          this.isLoading = false;

        }
      }

    },
    onPress1() {
      this.$router.push({name: 'RegionContent'});

    },
  },
};
</script>

<style scoped src="../styles/Products.css">
</style>
