import axios from 'axios';
import {BaseUrl} from "@/util/constants";

const NotificationsEndPoint = 'notification/get';


class NotificationsService {


    static async getAllNotifications(token) {
        const header = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        try {
            const response = await axios.get(`${BaseUrl}${NotificationsEndPoint}`, header);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during products');
            }
        }
    }


}


export default NotificationsService;
