import axios from 'axios';
import {BaseUrl} from "@/util/constants";

const ReservationsEndPoint = 'orders/on/hold';
const DetailsEndPoint='orders/'
const ConfirmEndPoint='orders/reserved/'
const AddNoteEndPoint='orders/notes/'

class ReservationsService {


    static async getAllReservations(token) {
        const header = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        try {
            const response = await axios.get(`${BaseUrl}${ReservationsEndPoint}`, header);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during products');
            }
        }
    }



    static async getOrderDetails(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            const response = await axios.get(`${BaseUrl}${DetailsEndPoint}${id}`, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }
    static async confirmOrder(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            const response = await axios.get(`${BaseUrl}${ConfirmEndPoint}${id}`, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }
    static async addNotes(token, id , message) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const formData = new FormData();
            formData.append('notes', message);
            console.log(`id is ${id}`)

            const response = await axios.post(`${BaseUrl}${AddNoteEndPoint}${id}`, formData, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }
}


export default ReservationsService;
