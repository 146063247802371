<template>
  <div class="page-scrollable">
    <div class="row">
      <div class="route-title">تعديل معلومات محافظة</div>

    </div>
    <div class="row">

      <GMapMap
          :center="{ lat: lit, lng: lang }"
          :zoom="20"
          map-type-id="terrain"
          style="width: 70vw; height: 22rem"
          :options="{
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true
    }"
          @click="addMarker"
      >
        <GmapMarker
            v-for="(m, index) in markers"
            :key="index"
            :position="m.position"
            :title="m.title"
        />
        <GMapMarker
            :position="{ lat: this.lit, lng: this.lang }"
            clickable=true
        />
      </GMapMap>
    </div>

    <div style="display: flex;">
      <div class="row">
        <div class="select-title">
          <label for="selectItem"> البلد </label>

        </div>

        <input id="selectItem" v-model="country" disabled>

      </div>
      <div class="row">
        <div class="select-title">
          <label for="selectItem"> المحافظة</label>
        </div>
        <input id="selectItem" v-model="city" disabled>
      </div>
    </div>
    <div class="image-container">
      <div class="image" @click="sendData">
        <span class="button-text">تعديل</span>
      </div>
    </div>
  </div>
</template>
<script>
import {AddIcon} from "@/util/constants";
import ProductsViewModel from "@/features/products/viewmodels/ProductsViewModel";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "AddRegion",

  data() {
    return {
      phoneNumbers: [{value: ""}],
      items: [],
      selectedFileName: [],
      selectedButton: 1,
      selectedFiles: [],
      country: null,
      id: null,
      city: '',
      lit: 24.470901,
      lang: 39.61223,
      markers: [],

    };

  },

  mounted() {
    (async () => {
      const response = await fetch("https://restcountries.com/v3.1/all");
      const data = await response.json();
      const items = data
          .map(country => country.translations.ara.common)
          .filter(name => name !== "إسرائيل")
          .sort((a, b) => a.localeCompare(b, 'ar'));
      this.items = items;
      console.log(this.items);
    })();
  },


  created() {
    this.initMap()
    this.city = this.$route.query.city;
    this.country = this.$route.query.country;
    this.id = this.$route.query.id;

  }, methods: {

    async initMap() {
      try {
        // Load the Google Maps API
        const googleMaps = await this.$gmapApiPromiseLazy();

        // Perform geocoding to get the coordinates
        googleMaps.geocode({
          address: `${this.city}, ${this.country}`
        }, (results, status) => {
          if (status === 'OK') {
            // Extract latitude and longitude from the results
            const lat = results[0].geometry.location.lat();
            const lng = results[0].geometry.location.lng();

            // Now you can use lat and lng in your API call or wherever you need them
            console.log('Coordinates:', lat, lng);

            // Proceed with your editRegion function with the obtained coordinates

          } else {
            throw new Error(`Geocode was not successful for the following reason: ${status}`);
          }
        });
      } catch (error) {
        console.error('Error fetching coordinates:', error.message);
        this.isLoading = false;
      }
    },
    selectButton(buttonNumber) {
      this.selectedButton = buttonNumber;
    },
    handleFileUpload(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        this.selectedFiles.push(files[i]);
        this.selectedFileName.push(files[i].name)

      }
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1);
      this.selectedFileName.splice(index, 1);

    },
    async sendData() {

      try {
        const data = await ProductsViewModel.editRegion(this.country, this.city, this.id);
        console.log(data)
        this.$router.go(-1);

        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading = false;

      }
    },
    onPress() {
      this.$router.push({name: 'AddProductInfo'});

    },
    AddIcon() {
      return AddIcon
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFileName = file.name;
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result;
          console.log("Uploaded image URL:", imageUrl);
        };
        reader.readAsDataURL(file);
      }
    },
    addPhoneNumber() {
      if (this.phoneNumbers.length < 3) {
        this.phoneNumbers.push({value: ""});
      }
    },

  }
}
</script>

<style scoped src="../styles/AddProduct.css">

</style>