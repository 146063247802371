// Import axios and BaseUrl from constants file
import axios from 'axios';
import {BaseUrl} from "@/util/constants";

const PoliciesEndPoint = 'policies';

class PublicPoliciesService {
    static async fetchPolicies(token) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.get(`${BaseUrl}${PoliciesEndPoint}`,{headers});
            return response.data;
        } catch (error) {
            console.error('Error fetching policies:', error);
            throw error;
        }
    }
    static async updatePolicies(token,adminId, updatedPolicies) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
console.log(`${BaseUrl}${PoliciesEndPoint}/${adminId}`)
            const response = await axios.post(`${BaseUrl}${PoliciesEndPoint}/${adminId}`, updatedPolicies, { headers });
            return response.data;
        } catch (error) {
            console.error('Error updating policies:', error);
            throw error;
        }
    }
}

export default PublicPoliciesService;
