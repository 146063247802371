<template>
  <div class="background" :class="{ 'popup--back-open': showGroupPopUp }">
    <div class="blur"></div>
  </div>

  <div class="popup" :class="{ 'popup-open': showGroupPopUp }">
    <div class="expanded-row">
      <div class="dialog-title">{{ $t('SendGroupMessagePopUp') }}</div>
      <div @click="closePopup" class="icon" v-html="closeIcon"></div>

    </div>
    <div class="column">
        <textarea type="text" class="custom-input" v-model="groupName" rows="5"
        />
    </div>

    <div class="row-upload">
      <div class="upload-file-title">{{ $t('uploadFileTitle') }}</div>

      <div class="upload-file">
        <label for="fileInput" class="upload-label">
          <div class="upload-content">
            <div v-if="!selectedFileName" class="icon" v-html="uploadIcon"></div>
            <div v-if="!selectedFileName">{{ $t('uploadFile') }}</div>
            <div v-else>
              <div class="row-remove">
                <span class="name">{{ selectedFileName }}</span>
                <div @click="removeFile">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="remove-icon-svg"
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"/>
                    <line x1="6" y1="6" x2="18" y2="18"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </label>
        <input v-if="!selectedFileName"
               id="fileInput"
               type="file"
               accept="image/*"
               class="file-input"
               style="display: none;"
               @change="handleFileUpload"
        />
      </div>


    </div>
    <div class="image-container">
      <div v-if="isLoading" class="loader-container">
        <div class="loader"></div>
      </div>
      <div class="image" @click="onPress" v-if="!this.isLoading">
        <span class="button-text">{{ $t("send") }}</span>
      </div>
    </div>
    <div CLASS="row-upload-with-padding">
      <Divider/>
      <div class="expanded">{{ $t('or') }}</div>
      <Divider/>

    </div>
    <div CLASS="row-upload-with-padding">
      <div class="expanded">
        <div class="expanded-title">{{ $t('ByWhatsapp') }}</div>
        <div @click="shareFile" class="icon" v-html="WhatsApp"/>


      </div>
      <div class="expanded">
        <div class="expanded-title">{{ $t('BySMS') }}</div>
        <div @click="closePopup" class="icon" v-html="SMS()"></div>

      </div>

    </div>
  </div>


</template>

<script>


import {CloseIcon, SMS, UploadIcon, WhatsApp} from "@/util/constants";
import Divider from "@/components/Divider.vue";
import StatisticsViewModel from "@/features/statistics/viewmodels/StatisticsViewModel";

export default {
  name: 'GroupMessagePopUp',
  components: {Divider},
  setup() {
  },
  data() {
    return {
      groupName: '',
      showGroupPopUp: false,
      selectedImage: null,
      isLoading: false,
      closeIcon: CloseIcon,
      uploadIcon: UploadIcon,
      WhatsApp: WhatsApp,
      selectedFileName: null,

    };
  },
  methods: {
    async shareFile() {
      const shareText = `هذه صورة لنص ${this.groupName} ${this.selectedImage}`;
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(shareText)}`;

      window.open(whatsappUrl, '_blank');
    },
    SMS() {
      return SMS
    },
    removeFile() {
      this.selectedFileName = null;
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.selectedImage = file
      this.selectedFileName = file ? file.name : null;

      console.log('Selected file:', file);
    },
    openPopup() {
      this.showGroupPopUp = true;
    },
    async onPress() {
      try {
        this.isLoading = true
        const data = await StatisticsViewModel.sendMessage(this.groupName, this.selectedImage);
        console.log(data)
        this.closePopup()
        this.isLoading = false

      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading = false;

      }
    },
    closePopup() {
      this.$emit('close-popup');

      this.groupName = '';
      this.isLoading = false;
      this.selectedImage = null;
      this.showGroupPopUp = false;
    },
  },
};
</script>

<style scoped src="../styles/GroupMessagePopUp.css">


</style>
