
<template>
  <div class="page-scrollable">

    <router-view></router-view>

  </div>
</template>
<script >

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "PublicPolicies",
}
</script>
<style scoped src="../styles/PublicPolicies.css">

</style>