<template>
  <div class="page-scrollable">
    <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
    <div class="row">
      <div class="route-title">اضافة محافظة للمنتجات</div>

    </div>
    <div class="row">

      <GMapMap
          :center="{ lat: lit, lng: lang }"
          :zoom="20"
          map-type-id="terrain"
          style="width: 70vw; height: 22rem"
          :options="{
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true
    }"
          @click="addMarker"
      >
        <GmapMarker
            v-for="(m, index) in markers"
            :key="index"
            :position="m.position"
            :title="m.title"
        />
        <GMapMarker
            :position="{ lat: this.lit, lng: this.lang }"
            clickable=true
        />
      </GMapMap>
    </div>


    <div style="display: flex;">
      <div class="row">
        <div class="select-title">
          <label for="selectItem"> البلد </label>

        </div>

        <input id="selectItem" v-model="country" disabled>

      </div>
      <div class="row">
        <div class="select-title">
          <label for="selectItem"> المحافظة</label>
        </div>
        <input id="selectItem" v-model="city" disabled>
      </div>
    </div>

    <div  class="image-container">
      <div v-if="isLoading" class="loader-container">
        <div class="loader"></div>
      </div>

      <div  v-if="!isLoading" class="image" @click="sendData">
        <span class="button-text">{{ $t("add") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import {AddIcon} from "@/util/constants";
import ProductsViewModel from "@/features/products/viewmodels/ProductsViewModel";
import IntermediateStoreViewModel from "@/features/intermediate_store/viewmodels/IntermediateStoreViewModel";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "AddRegion",
  data() {
    return {
      phoneNumbers: [{value: ""}],
      items: [],
      selectedFileName: [],
      selectedButton: 1,
      selectedFiles: [],
      country: null,
      city: '',
      lit: 24.470901,
      lang: 39.61223,
      markers: [],
      isLoading: false,
      errorMessage: '',


    };

  },
  mounted() {
    (async () => {
      const response = await fetch("https://restcountries.com/v3.1/all");
      const data = await response.json();
      const items = data
          .map(country => country.translations.ara.common)
          .filter(name => name !== "إسرائيل")
          .sort((a, b) => a.localeCompare(b, 'ar'));
      this.items = items;
      console.log(this.items);
    })();
  },


  created() {

    this.getAddress(this.lang, this.lit);
  }, methods: {
    selectButton(buttonNumber) {
      this.selectedButton = buttonNumber;
    },
    handleFileUpload(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        this.selectedFiles.push(files[i]);
        this.selectedFileName.push(files[i].name)

      }
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1);
      this.selectedFileName.splice(index, 1);

    },
    async addMarker(event) {
      const latLng = event.latLng;
      const lat = latLng.lat();
      const lng = latLng.lng();
      const marker = {
        position: {lat, lng},
        title: "Clicked Marker"
      };
      this.markers = [marker];
      this.lit = lat;
      this.lang = lng;
      console.log("Clicked coordinates:", {lat, lng});
      this.getAddress(lng, lat);

    },
    async getAddress(lng, lat) {

      try {
        const data = await IntermediateStoreViewModel.getAddress(lng, lat);
        console.log(data);
        const addressComponents = data.results[0].address_components;

        addressComponents.forEach(component => {
          switch (component.types[0]) {
            case 'country':
              this.country = component.long_name;
              break;
            case 'political':
              this.sublocality = component.long_name;
              break;
            case 'locality':
              this.locality = component.long_name;
              break;
            case 'administrative_area_level_2':
              this.administrativeAreaLevel2 = component.long_name;
              break;
            case 'administrative_area_level_1':
              this.administrativeAreaLevel1 = component.long_name;
              break;
            default:
              break;
          }
        });
        this.city = this.locality;

        this.isLoading = false;
      } catch (error) {
        console.error("Error Login:", error);

        if (error.message) {
          this.errorMessage = error.message || "خطا في السيرفر";
        } else {
          this.errorMessage = "خطا في السيرفر";
        }
      }
    },
    async sendData() {
      this.isLoading = true

      try {
        const data = await ProductsViewModel.sendRegion(this.country, this.city);

        console.log(data)
        this.$router.go(-1);

        this.isLoading = false;
      } catch (error) {
        console.error("Error :", error);

        if (error.message) {
          this.errorMessage = error.message || "خطا في السيرفر";
        } else {
          this.errorMessage = "خطا في السيرفر";
        }

        setTimeout(() => {
          this.errorMessage = '';
        }, 2000);

        this.isLoading = false;
      }
    },
    onPress() {
      this.$router.push({name: 'AddProductInfo'});

    },
    AddIcon() {
      return AddIcon
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFileName = file.name;
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result;
          console.log("Uploaded image URL:", imageUrl);
        };
        reader.readAsDataURL(file);
      }
    },
    addPhoneNumber() {
      if (this.phoneNumbers.length < 3) {
        this.phoneNumbers.push({value: ""});
      }
    },

  }
}
</script>

<style scoped src="../styles/AddProduct.css">

</style>