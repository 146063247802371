<template>
  <div>
    <div class="row">
      <div class="route-title">{{
          $t(this.route)
        }}
      </div>
      <div class="button-container">

        <button
            :class="{ 'active': this.$route.name === 'FilterBody' }"
            @click="OpenFilterOption"
        >
          {{ $t('filter') }}
        </button>
        <div class="divider"></div>
        <button
            :class="{ 'active': this.$route.name === 'SortBody' }"
            @click="OpenSortOption"
        >
          {{ $t('sort') }}
        </button>

      </div>
<!--      <div @click="goBack" class="icon" style=" color: black">-->
<!--        <i style="width: 20px; height: 20px" class="fa fa-arrow-left"></i>-->
<!--      </div>-->

    </div>
    <router-view/>
  </div>

</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "FilterPage",
  data() {
    return {
      route: 'IntermediateStoresRoute'

    };
  },
  methods: {
    OpenFilterOption() {
      this.$router.push({name: 'FilterBody'});
      this.route = 'filterIntermiadateStore'
    },
    goBack() {
      this.navigateBackUntilS();
    },
    navigateBackUntilS() {
      const currentRoute = this.$route.name;
      console.log(currentRoute)
      if (currentRoute !== 'StoreResultsBody') {
        this.$router.go(-1)
        setTimeout(this.navigateBackUntilS, 100);
      }else {
return      }
    },
    OpenSortOption() {
      this.$router.push({name: 'SortBody'});
      this.route = 'IntermediateStoresRoute'

    },
  }

}
</script>

<style scoped src="../styles/SortBody.css">

</style>