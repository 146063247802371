<template>
  <div>
    <div class="page-scrollable">
      <div class="row">
        <div class="route-title">عمليات تاكيد منتجات قيد الحجز</div>

      </div>

      <div class="row">


      </div>
      <div v-if="isLoading" class="loader-container">
        <div class="loader"></div>
      </div>
      <div v-if="this.productsItems.length===0 && !isLoading">
        <div>لا يوجد عناصر</div>
      </div>
      <div class="grid-container">
        <ReportsCard v-for="(item, index) in productsItems" :key="index" :item="item"/>
      </div>
    </div>

  </div>
</template>

<script>
import {AddIcon, FilterIcon} from "@/util/constants";
import ProductsViewModel from "@/features/products/viewmodels/ProductsViewModel";
import ReservationsViewModel from "@/features/reservations/viewmodels/ReservationsViewModel";
import ReportsCard from "@/features/reservations/components/ProductsCard.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ReservationsResults",
  components: {ReportsCard},
  data() {
    return {
      productsItems: [],
      isLoading: true,
      code: ''
    };
  },
  created() {
    this.fetchProductsData();

  },
  methods: {

    FilterIcon() {
      return FilterIcon
    },
    AddIcon() {
      return AddIcon
    },
    goToProductDetails(id) {
      this.$router.push({name: 'ProductsDetails', params: {id: id},});
    },


    async fetchProductsData() {

      try {
        const data = await ReservationsViewModel.getAllReservations();
        console.log(data)
        this.productsItems = data.data;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading = false;

      }
    },

    async searchProductData(code) {
      if (code.length === 0) {
        await this.fetchProductsData()
      } else {
        try {
          const data = await ProductsViewModel.searchProduct(code);
          console.log(data)
          this.productsItems = data.data;
          this.isLoading = false;
        } catch (error) {
          console.error("Error searching:", error.message);
          this.isLoading = false;

        }
      }

    },
  },
};
</script>

<style scoped src="../styles/Products.css">
</style>
