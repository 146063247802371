<template>
  <div class="page-scrollable">
    <div class="row">
      <div class="route-title">{{ $t('Problems') }}</div>
      <div class="icon" v-html="AddIcon()" @click="openAdd"></div>
    </div>
    <div v-if="isLoading" class="loader-container">
      <div class="loader"></div>
    </div>
    <div v-if="categories.length===0&&!isLoading" class="select-title">
      <label for="selectItem"> لا يوجد عناصر لعرضها</label>
    </div>
    <div class="grid-list">
      <div class="categories-grid">
        <ProblemCard
            v-for="category in categories"
            :key="category.id"
            :category="category"
            @open-popup="openPopup(category.name,category.id)"
        />
      </div>
    </div>
    <DeleteProblemPopUp v-if="showDeletePopup" ref="showDeletePopupRef" @close-popup="closePopUp"
                         @refresh="fetchProblemsData" :name="selectedName" :id="selectedId" :is-cat="true"/>

  </div>
</template>
<script>

// import CategoryCard from "@/features/categories/presentation/components/CategoryCard.vue";
import {AddIcon} from "@/util/constants";
import ProblemCard from "@/features/problems/presentation/components/ProblemCard.vue";
import ProblemsViewModel from "@/features/problems/viewmodels/ProblemsViewModel";
import DeleteProblemPopUp from "@/features/problems/presentation/components/DeleteProblemPopUp.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "ProblemsData",
  created() {
    this.fetchProblemsData();


  },
  methods: {
    AddIcon() {
      return AddIcon
    },
    openAdd() {
      this.$router.push({name: 'AddProblem'});

    },
    closePopUp() {
      this.showDeletePopup = false;

    },
    openPopup(name, id) {
      this.selectedName = name;
      this.selectedId = id;
      console.log('id is like that' + id)
      this.showDeletePopup = true;
      if (this.$refs.showDeletePopupRef && this.$refs.showDeletePopupRef.openPopup) {
        this.$refs.showDeletePopupRef.openPopup();
      }
    },
    async fetchProblemsData() {
      try {
        this.isLoading = true
        const data = await ProblemsViewModel.getAllProblems();
        console.log(data)
        this.categories = data;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading = false;
      }
    },
  },

  components: {DeleteProblemPopUp, ProblemCard},
  data() {
    return {
      categories: [],
      isLoading: true,
      showDeletePopup: false,
      selectedName: '',
      selectedId: null

    };
  }
}
</script>
<style scoped src="../styles/Problems.css">

</style>

