import PublicPoliciesService from '../services/PublicPoliciesService';

export default {

    async getAllPolicies() {
        try {
            const token = localStorage.getItem('token');

            const response = await PublicPoliciesService.fetchPolicies(token);
            return response.data;
        } catch (error) {
            console.error('Error fetching policies :', error.message);
            throw error;
        }
    },

    async updateAllPolicies(updateValues) {
        try {
            const token = localStorage.getItem('token');
            const adminId = localStorage.getItem('adminId');

            const response = await PublicPoliciesService.updatePolicies(token,adminId,updateValues);
            return response.data;
        } catch (error) {
            console.error('Error post policies :', error.message);
            throw error;
        }
    },
};
