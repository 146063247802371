<template>
  <div>
    <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
    <div class="row">
      <div class="route-title">{{ $t('AddStoreTitle') }}</div>

    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('StoreName2') }}</label>
      </div>
      <div><input id="selectItem" v-model="name">
        <div v-if="nameValidate" style="color: red; font-size: 13px;">{{ nameValidate }}</div>

      </div>

    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('email') }}</label>
      </div>
      <div>
        <input id="selectItem" v-model="email" type="email">

        <div v-if="emailValidate" style="color: red; font-size: 13px;">{{ emailValidate }}</div>

      </div>
    </div>
    <div class="row">
      <div class="select-title">
        <label for="phoneNumber">{{ $t('phoneNumber') }}</label>
      </div>
      <div>
        <div class="phone-column" id="phoneNumbers">
          <input v-for="(phone, index) in phoneNumbers" :key="index" :id="'phoneNumber' + index" v-model="phone.value">
        </div>
        <div v-if="phoneValidate" style="color: red; font-size: 13px;">{{ phoneValidate }}</div>

      </div>

      <div class="icon" @click="addPhoneNumber" v-if="phoneNumbers.length < 3" v-html="AddIcon()"></div>

    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('password') }}</label>
      </div>
      <div>
        <input id="selectItem" v-model="password">
        <div v-if="passwordValidate" style="color: red; font-size: 13px;">{{ passwordValidate }}</div>

      </div>
    </div>

    <div class="row">
      <div class="select-title">
        <label for="selectItem">اسم المستخدم</label>
      </div>
      <div>
        <input id="selectItem" v-model="userName">
        <div v-if="userNameValidate" style="color: red; font-size: 13px;">{{ userNameValidate }}</div>

      </div>
    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('CheckValue') }}</label>
      </div>
      <div>
        <input id="selectItem" v-model="cost">
        <div v-if="costValidate" style="color: red; font-size: 13px;">{{ costValidate }}</div>

      </div>
    </div>

    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('AboutStore') }}</label>
      </div>
      <div>
        <textarea type="text" class="custom-input" v-model="description" rows="5"/>
        <div v-if="descriptionValidate" style="color: red; font-size: 13px;">{{ descriptionValidate }}</div>

      </div>

    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('Address') }}</label>
      </div>
      <GMapMap
          :center="{ lat: lit, lng: lang }"
          :zoom="20"
          map-type-id="terrain"
          style="width: 50vw; height: 20rem"
          :options="{
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true
    }"
          @click="addMarker"
      >
        <GmapMarker
            v-for="(m, index) in markers"
            :key="index"
            :position="m.position"
            :title="m.title"
        />
        <GMapMarker
            :position="{ lat: this.lit, lng: this.lang }"
            clickable=true
        />
      </GMapMap>


    </div>
    <div class="grid-container">
      <div class="row" v-for="(item, index) in items" :key="index">
        <div class="select-title">
          <label :for="'selectItem' + index"> {{ item.label }}</label>
        </div>
        <input :id="'selectItem' + index" v-model="address[index]" disabled>
      </div>
    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('StoreLogo') }}</label>
      </div>
      <div>
        <label for="imageUpload" class="custom-file-upload">

          <div class="icon" v-html="AddIcon()"></div>
        </label>
        <div v-if="imageValidate" style="color: red; font-size: 13px; text-align: center">{{ imageValidate }}</div>

      </div>

      <input type="file" id="imageUpload" @change="handleImageUpload" style="display: none" accept="image/*">
      <span style="font-weight: bold" v-if="selectedFileName">{{ selectedFileName }}</span>

    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> صورة واجهة المتجر</label>
      </div>
      <div>
        <label for="imageUpload2" class="custom-file-upload">

          <div class="icon" v-html="AddIcon()"></div>
        </label>
        <div v-if="imageValidate2" style="color: red; font-size: 13px; text-align: center">{{ imageValidate2 }}</div>

      </div>

      <input type="file" id="imageUpload2" @change="handleImageUpload2" style="display: none" accept="image/*">
      <span style="font-weight: bold" v-if="selectedFileName2">{{ selectedFileName2 }}</span>

    </div>

    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('NationalId') }}</label>
      </div>
      <div>
        <input id="selectItem" v-model="nationalId">
        <div v-if="nationalValidate" style="color: red; font-size: 13px;">{{ nationalValidate }}</div>

      </div>

    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('CommercialRegister') }}</label>
      </div>
      <div>
        <input id="selectItem" v-model="commercial">
        <div v-if="comValidate" style="color: red; font-size: 13px;">{{ comValidate }}</div>
      </div>
    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('CommercialRegisterImage') }}</label>
      </div>
      <div>
        <label for="imageUploadcom" class="custom-file-upload">

          <div class="icon" v-html="AddIcon()"></div>
        </label>
        <div v-if="imageValidateCom" style="color: red; font-size: 13px; text-align: center">{{
            imageValidateCom
          }}
        </div>

      </div>

      <input type="file" id="imageUploadcom" @change="handleImageUploadcom" style="display: none" accept="image/*">
      <span style="font-weight: bold" v-if="selectedFileNameCom">{{ selectedFileNameCom }}</span>

    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('License') }}</label>
      </div>
      <div>
        <input id="selectItem" v-model="License">
        <div v-if="lisValidate" style="color: red; font-size: 13px;">{{ lisValidate }}</div>

      </div>
    </div>
    <div class="row">
      <div class="select-title">
        <div>
          <label for="selectItem"> {{ $t('LicenseImage') }}</label>


        </div>
      </div>

      <div>
        <label for="imageUploadLis" class="custom-file-upload">

          <div class="icon" v-html="AddIcon()"></div>
        </label>
        <div v-if="imageValidateLis" style="color: red; font-size: 13px; text-align: center">{{
            imageValidateLis
          }}
        </div>

      </div>

      <input type="file" id="imageUploadLis" @change="handleImageUploadLis" style="display: none" accept="image/*">
      <span style="font-weight: bold" v-if="selectedFileNameLis">{{ selectedFileNameLis }}</span>
    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> الرقم الضريبي</label>
      </div>
      <div>
        <input id="selectItem" v-model="tax">
        <div v-if="taxValidate" style="color: red; font-size: 13px;">{{ taxValidate }}</div>
      </div>
    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> شهادة التكليف الضريبي</label>
      </div>
      <div>
        <label for="imageUploadtax" class="custom-file-upload">

          <div class="icon" v-html="AddIcon()"></div>
        </label>
        <div v-if="imageValidateTax" style="color: red; font-size: 13px; text-align: center">{{
            imageValidateTax
          }}
        </div>

      </div>

      <input type="file" id="imageUploadtax" @change="handleImageUploadTax" style="display: none" accept="image/*">
      <span style="font-weight: bold" v-if="selectedFileNameTax">{{ selectedFileNameTax }}</span>

    </div>
    <div v-if="isLoading" class="loader-container">
      <div class="loader"></div>
    </div>
    <div class="image-container" v-if="!isLoading">
      <div class="image" @click="addStoresData">
        <span class="button-text">{{ $t("add") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import {AddIcon} from "@/util/constants";
import IntermediateStoreViewModel from "@/features/intermediate_store/viewmodels/IntermediateStoreViewModel";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "AddStore",
  data() {
    return {
      googleMapHTML: '',
      name: '',
      email: '',
      password: '',
      cost: '',
      description: '',
      nameValidate: '',
      address: [],
      emailValidate: '',
      phoneValidate: '',
      userName: '',
      lit:  24.45816971063795,
      lang: 39.70992260736625,
      nationalId: '',
      commercial: '',
      tax: '',
      License: '',
      passwordValidate: '',
      nationalValidate: '',
      comValidate: '',
      taxValidate: '',
      lisValidate: '',
      userNameValidate: '',
      costValidate: '',
      descriptionValidate: '',
      imageValidate: '',
      imageValidate2: '',
      imageValidateCom: '',
      imageValidateTax: '',
      imageValidateLis: '',
      phoneNumbers: [{value: ""}],
      items: [
        {label: this.$t('Region'), name: ''},
        {label: this.$t('Governorate'), name: ''},
        {label: this.$t('City'), name: ''},
        {label: this.$t('District'), name: ''},
        {label: this.$t('Street'), name: ''},
      ],
      markers: [],

      selectedFileName: "",
      selectedFileName2: "",
      selectedFileNameCom: "",
      selectedFileNameLis: "",
      selectedFileNameTax: "",
      selectedFile: null,
      selectedFile2: null,
      selectedFileCom: null,
      selectedFileLis: null,
      selectedFileTax: null,
      errorMessage: '',
      isLoading: false,

    };
  },
  created() {
    this.getAddress(this.lang, this.lit);


  },
  watch: {
    tax(newVal) {
      if (/^\d{15}$/.test(newVal)) {
        this.taxValidate = '';
        console.log('الرقم الضريبي صحيح');
      } else {
        this.taxValidate = 'الرقم الضريبي يجب أن تكون 15 رقم';
        console.log('الرقم الضريبي يجب أن تكون 15 رقم');
      }
    },
    commercial(newVal) {
      if (/^\d{10}$/.test(newVal)) {
        this.comValidate = '';
        console.log('السجل التجاري صحيح');
      } else {
        this.comValidate = 'السجل التجاري يجب أن تكون 10 أرقام';
        console.log('السجل التجاري يجب أن تكون 10 أرقام');
      }
    },
    License(newVal) {
      if (/^\d{10}$/.test(newVal)) {
        this.lisValidate = '';
        console.log('رخصة صحيحة');
      } else {
        this.lisValidate = 'الرخصة يجب أن تكون 10 أرقام';
        console.log('الرخصة يجب أن تكون 10 أرقام');
      }
    },
    nationalId(newVal) {
      if (newVal.startsWith('1')) {
        if (/^\d{10}$/.test(newVal)) {
          this.nationalValidate = ''

        } else {
          this.nationalValidate = 'يجب ان يكون الرقم الوطني 10 ارقام';

        }
      } else {
        this.nationalValidate = 'يجب ان يبدا الرقم الوطني بالعدد 1'

        console.log('National ID should be 10 numeric digits');
      }
    },
  },
  mounted() {
    // this.loadGoogleMaps();
  },
  methods: {

    async addMarker(event) {
      const latLng = event.latLng;
      const lat = latLng.lat();
      const lng = latLng.lng();
      const marker = {
        position: {lat, lng},
        title: "Clicked Marker"
      };
      this.markers = [marker];
      this.lit = lat;
      this.lang = lng;
      console.log("Clicked coordinates:", {lat, lng});
      this.getAddress(lng, lat);

    }

    ,

    async getAddress(lng, lat) {

      try {
        const data = await IntermediateStoreViewModel.getAddress(lng, lat);
        console.log(data);
        const addressComponents = data.results[0].address_components;
        let streetName = '';

        addressComponents.forEach(component => {
          switch (component.types[0]) {
            case 'route':
              streetName = component.long_name;
              break;
            case 'political':
              this.sublocality = component.long_name;
              break;
            case 'locality':
              this.locality = component.long_name;
              break;
            case 'administrative_area_level_2':
              this.administrativeAreaLevel2 = component.long_name;
              break;
            case 'administrative_area_level_1':
              this.administrativeAreaLevel1 = component.long_name;
              break;
            default:
              break;
          }
        });
        this.address[0] = this.administrativeAreaLevel1;
        this.address[1] = this.administrativeAreaLevel2;
        this.address[2] = this.locality;
        this.address[3] = this.sublocality;
        this.address[4] = streetName;
        console.log(streetName + this.locality + ' ' + this.administrativeAreaLevel1 + ' ' + this.administrativeAreaLevel2);

        this.isLoading = false;
      } catch (error) {
        console.error("Error Login:", error);

        if (error.message) {
          this.errorMessage = error.message || "خطا في السيرفر";
        } else {
          this.errorMessage = "خطا في السيرفر";
        }
      }
    },
    validateInputName() {
      if (this.name.length < 1) {
        this.nameValidate = 'الرجاء ادخال اسم';
      } else {
        this.nameValidate = '';
      }
    }
    ,
    validateInputUserName() {
      if (this.userName.length < 1) {
        this.userNameValidate = 'الرجاء ادخال اسم المستخدم';
      } else {
        this.userNameValidate = '';
      }
    }
    ,
    validateInputEmail() {
      if (this.email.length < 1) {
        this.emailValidate = 'الرجاء ادخال البريد الالكتروني';
      } else {
        this.emailValidate = '';
      }
    }
    ,
    validateInputPhone() {
      if (this.phoneNumbers[0]['value'].length < 1) {
        this.phoneValidate = 'الرجاء ادخال رقم الهاتف';
      } else {
        this.phoneValidate = '';
      }
    }
    ,
    validateInputPassword() {
      if (this.password.length < 1) {
        this.passwordValidate = 'الرجاء ادخال كلمة المرور';
      } else {
        this.passwordValidate = '';
      }
    },
    validateInputNational() {
      if (this.nationalId.length < 1) {
        this.nationalValidate = 'الرجاء ادخال الرقم الوطني';
      } else {
        this.nationalValidate = '';
      }
    },
    validateInputCom() {
      if (this.commercial.length < 1) {
        this.comValidate = 'الرجاء ادخال السجل التجاري';
      } else {
        this.comValidate = '';
      }
    },
    validateInputLis() {
      if (this.License.length < 1) {
        this.lisValidate = 'الرجاء ادخال الرخصة';
      } else {
        this.lisValidate = '';
      }
    },
    validateInputTax() {
      if (this.tax.length < 1) {
        this.taxValidate = 'الرجاء ادخال الرقم الضريبي';
      } else {
        this.taxValidate = '';
      }
    },
    validateInputCost() {
      if (this.cost.length < 1) {
        this.costValidate = 'الرجاء ادخال قيمة الفحص';
      } else {
        this.costValidate = '';
      }
    }
    ,
    validateInputDesc() {
      if (this.description.length < 1) {
        this.descriptionValidate = 'الرجاء ادخال الوصف';
      } else {
        this.descriptionValidate = '';
      }
    }
    ,
    validateInputImage() {
      if (this.selectedFileName.length < 1) {
        this.imageValidate = 'الرجاء ادخال صورة';
      } else {
        this.imageValidate = '';
      }
    },
    validateInputImageCom() {
      if (this.selectedFileNameCom.length < 1) {
        this.imageValidateCom = 'الرجاء ادخال صورة';
      } else {
        this.imageValidateCom = '';
      }
    }, validateInputImage2() {
      if (this.selectedFileNameCom.length < 1) {
        this.imageValidate2 = 'الرجاء ادخال صورة';
      } else {
        this.imageValidate2 = '';
      }
    },
    validateInputImageLis() {
      if (this.selectedFileNameLis.length < 1) {
        this.imageValidateLis = 'الرجاء ادخال صورة';
      } else {
        this.imageValidateLis = '';
      }
    },
    validateInputImageTax() {
      if (this.selectedFileNameTax.length < 1) {
        this.imageValidateTax = 'الرجاء ادخال صورة';
      } else {
        this.imageValidateTax = '';
      }
    },
    AddIcon() {
      return AddIcon
    }
    ,
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file
        this.selectedFileName = file.name;
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result;
          console.log("Uploaded image URL:", imageUrl);
        };
        reader.readAsDataURL(file);
      }
    }, handleImageUpload2(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile2 = file
        this.selectedFileName2 = file.name;
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result;
          console.log("Uploaded image URL:", imageUrl);
        };
        reader.readAsDataURL(file);
      }
    },
    handleImageUploadcom(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFileCom = file
        this.selectedFileNameCom = file.name;
        console.log(this.selectedFileNameCom)
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result;
          console.log("Uploaded image URLlllllll:", imageUrl);
        };
        reader.readAsDataURL(file);
      }
    },
    handleImageUploadLis(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFileLis = file
        this.selectedFileNameLis = file.name;
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result;
          console.log("Uploaded image URL:", imageUrl);
        };
        reader.readAsDataURL(file);
      }
    }
    , handleImageUploadTax(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFileTax = file
        this.selectedFileNameTax = file.name;
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result;
          console.log("Uploaded image URL:", imageUrl);
        };
        reader.readAsDataURL(file);
      }
    }
    ,
    async addStoresData() {
      try {
        this.isLoading = true;

        this.validateInputEmail()
        this.validateInputImage()
        this.validateInputImage2()
        this.validateInputImageCom()
        this.validateInputImageTax()
        this.validateInputImageLis()
        this.validateInputCost()
        this.validateInputCom()
        this.validateInputNational()
        this.validateInputLis()
        this.validateInputTax()
        this.validateInputDesc()
        this.validateInputPhone()
        this.validateInputName()
        this.validateInputUserName()
        this.validateInputPassword()
        if (this.nameValidate === '' && this.emailValidate === '' && this.costValidate === '' && this.descriptionValidate === '' && this.phoneValidate === '' && this.passwordValidate === '' && this.imageValidate === '' && this.imageValidateCom === '' && this.imageValidateLis === '' && this.comValidate === '' && this.lisValidate === '' && this.nationalValidate === '' && this.userNameValidate === '') {
          const data = await IntermediateStoreViewModel.addStore(this.name, this.email, this.selectedFile, this.description, this.cost, this.phoneNumbers, this.password, this.address, this.lang, this.lit, this.nationalId, this.commercial, this.selectedFileCom, this.License, this.selectedFileLis, this.userName, this.selectedFile2, this.tax, this.selectedFileTax,);
          console.log(data)
          this.shopItems = data.data;
          this.$router.push({name: 'StoreResultsBody'});
        }

        this.isLoading = false;
      } catch (error) {
        console.error("Error Login:", error);

        if (error.message) {
          this.errorMessage = error.message || "خطا في السيرفر";
        } else {
          this.errorMessage = "خطا في السيرفر";
        }

        setTimeout(() => {
          this.errorMessage = '';
        }, 2000);

        this.isLoading = false;
      }
    }
    ,
    addPhoneNumber() {
      if (this.phoneNumbers.length < 3) {
        this.phoneNumbers.push({value: ""});
      }
    }
    ,

  }
}

</script>

<style scoped src="../styles/AddStore.css">

</style>