import axios from 'axios';
import {BaseUrl} from "@/util/constants";

const StoreEndPoint = 'statistics/store';
const SellersEndPoint = 'statistics/seller';
const TopUsersEndPoint = 'statistics/buyer';
const AllUsersEndPoint = 'users';
const GetUserDetailsEndPoint = 'users/show/';
const blockUserEndPoint = 'users/block/';
const sendEndPoint = 'contact/send';


class StatisticsService {


    static async getTopStores(token) {
        const header = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        try {
            const response = await axios.get(`${BaseUrl}${StoreEndPoint}`, header);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during stores');
            }
        }
    }

    static async getTopSellers(token) {
        const header = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        try {
            const response = await axios.get(`${BaseUrl}${SellersEndPoint}`, header);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during stores');
            }
        }
    }

    static async getTopUsers(token) {
        const header = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        try {
            const response = await axios.get(`${BaseUrl}${TopUsersEndPoint}`, header);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during Users');
            }
        }
    }

    static async getUserDetails(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            console.log(`${BaseUrl}${GetUserDetailsEndPoint}${id}`)
            const response = await axios.get(`${BaseUrl}${GetUserDetailsEndPoint}${id}`, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during store details.');
            }
        }
    }

    static async sendGroupMessage(token, name, file) {
        try {
            const formData = new FormData();
            formData.append('description', name);

            formData.append('image', file);


            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            };

            console.log('token is ' + token)

            const response = await axios.post(`${BaseUrl}${sendEndPoint}`, formData, {headers});
            return response

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during subCategory.');
            }
        }
    }

    static async BlockUser(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            console.log(`${BaseUrl}${GetUserDetailsEndPoint}${id}`)
            const response = await axios.get(`${BaseUrl}${blockUserEndPoint}${id}`, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during store details.');
            }
        }
    }

    static async getAllUsers(token) {
        const header = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        try {
            const response = await axios.get(`${BaseUrl}${AllUsersEndPoint}`, header);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during Users');
            }
        }
    }

}


export default StatisticsService;
