<template>
  <div class="filter">
    <div STYLE=" padding: 16px 0px;">
      <div class="row">
        <div class="select-title">
          <label for="selectItem"> {{ $t('StoreCommission') }}</label>
        </div>
        <input id="selectItem" v-model="commission">
      </div>
      <div class="row">
        <div class="select-title">
          <label for="selectItem"> {{ $t('Region') }}</label>
        </div>
        <select id="selectItem" v-model="selectedItem1" @change="fetchCitiesData">
          <option v-for="(item, index) in items" :key="index" :value="item">
            {{ item }}
          </option>
        </select>
      </div>
      <div class="row">
        <div class="select-title">
          <label for="selectItem"> {{ $t('City') }}</label>

        </div>

        <select id="selectItem" v-model="selectedItem2" @change="fetchRegData">
          <option v-for="(item, index) in items1" :key="index" :value="item">
            {{ item }}
          </option>
        </select>
      </div>
      <div class="row">
        <div class="select-title">
          <label for="selectItem"> {{ $t('District') }}</label>
        </div>
        <select id="selectItem" v-model="selectedItem3">
          <option value="" disabled selected></option>
          <option v-for="(item, index) in items2" :key="index" :value="item">
            {{ item }}
          </option>
        </select>
      </div>

    </div>
    <div class="image-container">
      <div class="image" @click="sendData1">
        <span class="button-text">{{ $t("Search") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import IntermediateStoreViewModel from "@/features/intermediate_store/viewmodels/IntermediateStoreViewModel";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "FilterBody",
  data() {
    return {
      selectedItem1: null,
      selectedItem2: null,
      selectedItem3: null,

      items: [],
      items1: [],
      items2: [],
      commission: null


    };
  },
  created() {
    this.fetchStatesData()
  },
  methods: {
    async fetchStatesData() {
      this.items1 = []
      this.items2 = []

      try {
        const data = await IntermediateStoreViewModel.getAllStates();
        console.log(data)
        this.items = data.data;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading = false;

      }
    },
    async fetchCitiesData() {
      this.items1 = []
      this.items2 = []

      try {
        const data = await IntermediateStoreViewModel.getAllCities(this.selectedItem1);
        console.log(data)
        this.items1 = data.data;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading = false;

      }
    },
    async fetchRegData() {
      this.items2 = []

      try {
        const data = await IntermediateStoreViewModel.getAllNig(this.selectedItem2);
        console.log(data)
        this.items2 = data.data;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading = false;

      }
    },
    sendData1() {
      this.$router.push({
        name: 'StoreResultsBody',
        query: {
          reg: this.selectedItem1,
          city: this.selectedItem2,
          nig: this.selectedItem3,
          commission: this.commission
        },
      });

    }
  }


}
</script>
<style scoped src="../styles/FilterBody.css">

</style>