<template>
  <div class="page-scrollable">
    <div class="row" v-if="categories.length!==0||subcategories.length!==0">
      <div class="route-title" v-if="categories.length!==0">التصنيفات الوسيطة لل{{ this.name }}</div>
      <div class="route-title" v-if="subcategories.length!==0">التصنيفات الفرعية لل{{ this.name }}</div>
      <div class="icon" v-html="AddIcon()" v-if="categories.length!==0" @click="openAdd1"></div>
      <div class="icon" v-html="AddIcon()" v-if="subcategories.length!==0" @click="openAddSubSubCategory"></div>
    </div>
    <div v-if="isLoading1" class="loader-container">
      <div class="loader"></div>
    </div>
    <div class="grid-list">

      <div v-if="categories.length===0&&subcategories.length===0&&!isLoading1" class="select-title">
        <div class="button-group">
          <button @click="openAdd1">إضافة تصنيف وسيط</button>
          <button @click="openAddSubSubCategory">إضافة تصنيف فرعي</button>
        </div>
      </div>
      <div class="categories-grid" v-if="categories.length!==0">
        <SubCategoryCard
            :catId="this.$route.params.id"
            v-for="category in categories"
            :key="category.id"
            :subcategory="category"
            @open-popup="openPopup(category.name,category.id)"
        />
      </div>
      <div class="categories-grid" v-if="subcategories.length!==0">
        <SubSubCategoryCard
            :catId="this.$route.params.id"
            v-for="category in subcategories"
            :key="category.id"
            :subcategory="category"
            @open-popup="openPopup(category.name,category.id)"
            :is-main="true"
        />
      </div>
    </div>
    <div class="row">
      <div class="route-title">{{ $t('GoldBannerOffers') }} بال{{ this.name }}</div>
      <div class="icon" v-html="AddIcon()" @click="openAdd"></div>
    </div>
    <div v-if="isLoading2" class="loader-container">
      <div class="loader"></div>
    </div>
    <div v-if="cardData.length===0&&!isLoading2" style="padding: 100px" class="select-title">
      <label for="selectItem" style="margin: 20px"> لا يوجد عناصر لعرضها</label>
    </div>
    <div class="card-grid" v-if="cardData.length!==0&&!isLoading2">
      <div v-for="item in cardData" :key="item.id" class="card-banner">
        <div class="card-icons-row">
          <div class="icon-left" @click.stop="openPopup2(item.paragraph,item.id)">
            <div v-html="DeleteIcon()"></div>
          </div>
          <div class="icon-right" @click.stop="openEdit(item.id,item.paragraph,item.active, item.time)">
            <div v-html="EditIcon()"></div>
          </div>
        </div>
        <div class="content">
          <p>{{ item.paragraph }}</p>
          <img :src="ImageUrl(item.background)" alt="icon img"
               class="icon"/>
        </div>
        <img :src="ImageUrl(item.image) " alt="Card Image"
             class="image"/>
      </div>
    </div>
    <DeleteCategoryPopUp v-if="showDeletePopup" ref="showDeletePopupRef" @close-popup="closePopUp"
                         @refresh="fetchSubCategoriesData" :name="selectedName" :id="selectedId" :is-cat="false" :is-sub-sub-cat="true"/>
    <DeleteADSPopUp v-if="showDelete2Popup" ref="showDelete2PopupRef" @close-popup="closePopUp2"
                    @refresh="fetchAdsData" :name="selectedName2" :id="selectedId2" :is-cat="false"/>
  </div>
</template>

<script>

import {AddIcon, DeleteIcon, EditIcon, GoldItem, ImageUrl} from "@/util/constants";
import SubCategoryCard from "@/features/categories/presentation/components/SubCategoryCard.vue";
import CategoriesViewModel from "@/features/categories/viewmodels/CategoriesViewModel";
import DeleteCategoryPopUp from "@/features/categories/presentation/components/DeleteCategoryPopUp.vue";
import DeleteADSPopUp from "@/features/categories/presentation/components/DeleteADSPopUp.vue";
import SubSubCategoryCard from "@/features/categories/presentation/components/SubSubCategoryCard.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "SubCategoriesData",
  created() {
    this.fetchSubCategoriesData();
    this.fetchAdsData();

  },
  methods: {
    EditIcon() {
      return EditIcon
    },
    DeleteIcon() {
      return DeleteIcon
    },
    GoldItem() {
      return GoldItem
    },
    AddIcon() {
      return AddIcon
    },
    openAdd1() {
      this.$router.push({name: 'AddSubCategory', params: {id: this.$route.params.id}});

    }, openAddSubSubCategory() {
      this.$router.push({name: 'AddSubSubCategory', query: {isSub: false}});

    },
    closePopUp() {
      this.showDeletePopup = false;
    }, closePopUp2() {
      this.showDelete2Popup = false;
    },
    openPopup(name, id) {
      this.selectedName = name;
      this.selectedId = id;
      console.log('id is like that' + id)
      this.showDeletePopup = true;
      if (this.$refs.showDeletePopupRef && this.$refs.showDeletePopupRef.openPopup) {
        this.$refs.showDeletePopupRef.openPopup();
      }
    },
    openPopup2(name, id) {
      this.selectedName2 = name;
      this.selectedId2 = id;
      console.log('id is like that' + id)
      this.showDelete2Popup = true;
      if (this.$refs.showDelete2PopupRef && this.$refs.showDelete2PopupRef.openPopup) {
        this.$refs.showDelete2PopupRef.openPopup();
      }
    }, openEdit(id, name, isActive, time) {

      this.$router.push({
        name: 'EditBanner', params: {bannerId: id}, query: {name: name, isActive: isActive, time: time,}
      });

    },
    openAdd() {
      this.$router.push({
        name: 'AddBanner', params: {
          categoryId: this.$route.params.id, subcategoryId: null,

        }, query: {name: 'category'}
      });
    },
    ImageUrl(image) {
      return ImageUrl + image;
    },

    async fetchSubCategoriesData() {

      try {
        const id = this.$route.params.id;
        this.name = this.$route.query.name;
        const data = await CategoriesViewModel.getAllSubCategories(id);
        console.log('is data' + data.data.length)
        if (data.data.length===0) {
          this.fetchSubSubCategoriesData();
          return;
        }
        console.log(data)
        this.categories = data.data;
        this.isLoading1 = false;
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading = false;

      }
    }, async fetchSubSubCategoriesData() {

      try {
        const id = this.$route.params.id;
        this.name = this.$route.query.name;
        const data = await CategoriesViewModel.getAllSubSubMainCategories(id);
        console.log(data)
        this.subcategories = data.data;
        this.isLoading1 = false;
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading = false;

      }
    },
    async fetchAdsData() {

      try {
        const id = this.$route.params.id;

        const data = await CategoriesViewModel.getAdsCategory(id);
        console.log(data)
        this.cardData = data.data;
        this.isLoading2 = false;
      } catch (error) {
        console.error("Error fetching ads:", error.message);
        this.isLoading = false;

      }
    },
  },
  components: {SubSubCategoryCard, DeleteADSPopUp, DeleteCategoryPopUp, SubCategoryCard,},
  data() {
    return {
      isLoading1: true,
      isLoading2: true,
      categories: [],
      subcategories: [],
      cardData: [],
      name: '',
      showDeletePopup: false,
      showDelete2Popup: false,
      selectedName: '',
      selectedName2: '',
      selectedId: null,
      selectedId2: null
    };
  }
}
</script>
<style scoped src="../styles/Categories.css">

</style>