<template>
  <div class="back">
    <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
    <div class="title">
      {{ $t("loginTitle") }}
    </div>
    <div class="form ">
      <div class="form-item">
        <div class="field-title">
          {{ $t("email") }}
        </div>
        <input
            type="email"
            v-model="email"
            @input="checkEmail"
            required
        />
        <div v-if="!this.isEmailValid && !this.emailTouched" class="validation">{{ $t("emailRequired") }}</div>
        <div v-if="this.emailTouched" class="validation">{{ $t("emailValidation") }}</div>

      </div>
      <div class="form-item">
        <div class="field-title">
          {{ $t("password") }}

        </div>
        <input
            type="password"
            v-model="password"
            @input="checkPassword"

            required/>

        <div v-if="!this.isPasswordValid&&!this.passwordTouched" class="validation">{{ $t("passwordRequired") }}</div>
        <div v-if="this.passwordTouched" class="validation">{{ $t("passwordValidation") }}</div>

      </div>


    </div>
    <div class="form-item">

      <div  class="image-container">
        <div v-if="isLoading" class="loader-container">
          <div class="loader"></div>
        </div>
        <div v-if="!isLoading"  class="image" @click="click">
          <span class="button-text">{{ $t("login") }}</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import LoginViewModel from "@/features/login/viewmodels/LoginViewModel";

export default {
  name: "LoginCard",
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
      emailTouched: false,
      passwordTouched: false,
      isEmailValid: true,
      isPasswordValid: true,
      isLoading:false,
    }
  },
  methods: {
    click() {
      this.checkEmail()
      this.checkPassword()
      if (this.isEmailValid && this.isPasswordValid && !this.emailTouched && !this.passwordTouched && this.password !== '' && this.email !== '') {
        console.log('my route')
        this.sendLoginData();

      }
    },
    checkEmail() {
      this.isEmailValid = this.email !== ''; // Check if email is not empty
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.emailTouched = !emailRegex.test(this.email);
    },
    checkPassword() {
      this.isPasswordValid = this.password !== '' && this.password.length >= 8; // Password criteria check
      this.passwordTouched = this.password !== '' && this.password.length < 8; // Update passwordTouched
    },
    sendLoginData() {
      console.log("code: ", this.email);

      this.isLoading = true;
      LoginViewModel.login(this.email, this.password)
          .then((response) => {
            console.log("Login successful:", response);
            const token = response['token'];
            const adminId = response['admin_id'];
            console.log("token successful:", token +'id  '+ adminId);

            localStorage.setItem('token', token);
            localStorage.setItem('adminId', adminId);
            const adminId1 = localStorage.getItem('adminId');
console.log('admin id is - '+ adminId1)

            this.$router.replace({ name: 'MainStatistic' });
          })
          .catch((error) => {
            console.error("Error Login:", error);

            if (error.message) {
              this.errorMessage = error.message || "Login failed due to unauthorized access.";
            } else {
              this.errorMessage = "Login failed due to unauthorized access.";
            }

            setTimeout(() => {
              this.errorMessage = '';
            }, 2000);

            this.isLoading = false;
          });
    },


  }
};

</script>
<style scoped src="../views/styles/LoginCard.css"></style>
