<template>
  <div>
    <div class="row">
              <div  class="route-title">{{ $t('AddStoreTitle') }}</div>
              <div  class="route-title-desc">{{ $t('StoreVerification') }}</div>

    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('phoneNumber') }}</label>
      </div>
      <input id="selectItem">
    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('NationalId') }}</label>
      </div>
      <input id="selectItem">
    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('CommercialRegister') }}</label>
      </div>
      <input id="selectItem">
    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('CommercialRegisterImage') }}</label>
      </div>
      <label for="imageUpload" class="custom-file-upload">

        <div   class="icon"  v-html="AddIcon()">    </div>
      </label>
      <input type="file" id="imageUpload" @change="handleImageUpload" style="display: none" accept="image/*">
      <span style="font-weight: bold" v-if="selectedFileName">{{ selectedFileName }}</span>
    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('License') }}</label>
      </div>
      <input id="selectItem">
    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('LicenseImage') }}</label>
      </div>
      <label for="imageUpload" class="custom-file-upload">

        <div   class="icon"  v-html="AddIcon()">    </div>
      </label>
      <input type="file" id="imageUpload" @change="handleImageUpload" style="display: none" accept="image/*">
      <span style="font-weight: bold" v-if="selectedFileName">{{ selectedFileName }}</span>
    </div>
    <div class="image-container">
      <div class="image" @click="click">
        <span class="button-text">{{ $t("add") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import {AddIcon} from "@/util/constants";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "VerifyStore",
  data() {
    return {
      phoneNumbers: [{value: ""}],
      items: [
        { label: this.$t('Region') },
        { label: this.$t('Governorate') },
        { label: this.$t('City') },
        { label: this.$t('District') },
        { label: this.$t('Street') },
      ],
      selectedFileName: "",

    };
  },
  // created() {
  //     window.scrollTo(0, 0);
  // },

  methods: {
    AddIcon() {
      return AddIcon
    },
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFileName = file.name;
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result;
          console.log("Uploaded image URL:", imageUrl);
        };
        reader.readAsDataURL(file);
      }
    },
    addPhoneNumber() {
      if (this.phoneNumbers.length < 3) {
        this.phoneNumbers.push({value: ""});
      }
    },
  }}
</script>

<style scoped src="../styles/AddStore.css">

</style>