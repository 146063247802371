import MessagesService from '../services/MessagesService';

export default {
    async getAllChats() {
        try {
            const token = localStorage.getItem('token');

            const response = await MessagesService.getAllChats(token);
            return response.data;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    },

    async getUserConversations(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await MessagesService.getUserConversations(token, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },
    async sendMessage(id, message) {
        try {
            const token = localStorage.getItem('token');

            const response = await MessagesService.sendMessage(token, id, message);
            return response.data;
        } catch (error) {
            console.error('Error send message :', error.message);
            throw error;
        }
    },


};
