<template>
  <div class="background" :class="{ 'popup--back-open': showGroupPopUp }">
    <div class="blur"></div>
  </div>

  <div class="popup" :class="{ 'popup-open': showGroupPopUp }">
    <div class="expanded-row">
      <div class="dialog-title">هل أنت متأكد من أنك تريد تسجيل الخروج ؟</div>
      <div @click="closePopup" class="icon" v-html="closeIcon"></div>

    </div>
    <div CLASS="row-upload-with-padding">
      <Divider/>
      <div class="expanded">{{ $t('or') }}</div>
      <Divider/>

    </div>
    <div CLASS="row-upload-with-padding">
      <div class="expanded">
        <div class="expanded-title">{{ $t('ByWhatsapp') }}</div>
          <div @click="closePopup" class="icon" v-html="WhatsApp"></div>


      </div>
      <div class="expanded">
        <div class="expanded-title">{{ $t('BySMS') }}</div>
        <div @click="closePopup" class="icon" v-html="SMS()"></div>

      </div>

    </div>
  </div>


</template>

<script>


import {CloseIcon, SMS, UploadIcon, WhatsApp} from "@/util/constants";
import Divider from "@/components/Divider.vue";

export default {
  name: 'LogoutPopUp',
  components: {Divider},
  setup() {
  },
  data() {
    return {
      groupName: '',
      showGroupPopUp: false,
      selectedImage: null,
      isLoading: false,
      closeIcon: CloseIcon,
      uploadIcon: UploadIcon,
      WhatsApp:WhatsApp,
      selectedFileName: null,

    };
  },
  methods: {
    SMS() {
      return SMS
    },
    removeFile() {
      this.selectedFileName = null;
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.selectedFileName = file ? file.name : null;

      console.log('Selected file:', file);
    },
    openPopup() {
      this.showGroupPopUp = true;
    },
    closePopup() {
      this.$emit('close-popup');

      this.groupName = '';
      this.isLoading = false;
      this.selectedImage = null;
      this.showGroupPopUp = false;
    },
  },
};
</script>

<style scoped src="../styles/Logout.css">


</style>
