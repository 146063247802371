<template>
  <div>
    <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
    <div class="row">
      <div class="route-title">اضافة تصنيف وسيط
      </div>

    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('CategoryName') }}</label>
      </div>
      <div><input id="selectItem" v-model="name">
        <div v-if="validationErrorName" style="color: red; font-size: 13px;">{{ validationErrorName }}</div>
      </div>


    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem">رمز الصنف</label>
        <div for="selectItem" style="color: red ; margin-top: 5px; font-size: 13px;font-weight: bold">
          {{ '\n (باللغة الانجليزية)' }}
        </div>
      </div>
      <div><input id="selectItem" v-model="SubCategoryCode" v-on:input="validateInput">
        <div v-if="validationError" style="color: red; font-size: 13px;">{{ validationError }}</div>
      </div>


    </div>



    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('CategoryImg') }}</label>
      </div>
      <div>
        <label for="imageUpload" class="custom-file-upload">
          <div>
            <div class="icon" v-html="AddIcon()"></div>
          </div>
        </label>
        <div v-if="validationErrorImage" style="color: red; font-size: 13px; text-align: center">{{
            validationErrorImage
          }}
        </div>

      </div>
      <input type="file" id="imageUpload" @change="handleImageUpload" style="display: none" accept="image/*">
      <span style="font-weight: bold" v-if="selectedFileName">{{ selectedFileName }}</span>

    </div>
    <div v-if="isLoading" class="loader-container">
      <div class="loader"></div>
    </div>
    <div class="image-container" v-if="!isLoading">

      <div class="image" @click="onPress">
        <span class="button-text">{{ $t("add") }}</span>
      </div>
    </div>
  </div>

</template>
<script>
import {AddIcon} from "@/util/constants";
import CategoriesViewModel from "@/features/categories/viewmodels/CategoriesViewModel";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "AddSubCategory",
  data() {
    return {
      phoneNumbers: [{value: ""}],
      items: [
        {label: this.$t('Region')},
        {label: this.$t('Governorate')},
        {label: this.$t('City')},
        {label: this.$t('District')},
        {label: this.$t('Street')},
      ],
      selectedFileName: "",
      validationErrorTraderProfit: "",
      validationErrorDraftingFee: "",
      selectedFile: null,
      drafting_fee: "",
      trader_profit: "",
      name: "",
      SubCategoryCode: "",
      validationErrorName: '',
      validationErrorImage: '',
      isLoading: false,
      validationError: '',
      errorMessage: '',

    };
  }, methods: {
    async onPress() {
      try {
        this.isLoading = true
        this.validateInputName()

        this.validateInputImage()
        this.validateInput()
        const id = this.$route.params.id;
        if (this.validationErrorName === '' && this.validationErrorImage === '' && this.validationError === '' ) {
          const data = await CategoriesViewModel.addSubCategory(id, this.name, this.selectedFile, this.SubCategoryCode, );
          console.log(data)
          this.$router.go(-1);

        }
        this.isLoading = false

      } catch (error) {
        console.error("Error Login:", error);

        if (error.message) {
          this.errorMessage = error.message || "خطا في السيرفر";
        } else {
          this.errorMessage = "خطا في السيرفر";
        }

        setTimeout(() => {
          this.errorMessage = '';
        }, 2000);

        this.isLoading = false;

      }
    },
    AddIcon() {
      return AddIcon
    },
    validateInput() {
      const englishRegex = /^[a-zA-Z0-9]+$/;
      if (this.SubCategoryCode.length < 1 || this.SubCategoryCode.length > 6 || this.SubCategoryCode.length === 0) {
        this.validationError = 'الرجاء ادخال رمز بين ال 0 وال 6 محارف';
      } else if (!englishRegex.test(this.SubCategoryCode)) {
        this.validationError = 'الرجاء الادخال باللغة الانجليزية';
      } else {
        this.validationError = '';
      }
    },
    validateInputName() {
      if (this.name.length < 1) {
        this.validationErrorName = 'الرجاء ادخال اسم';
      } else {
        this.validationErrorName = '';
      }
    }, validateInputT() {
      if (this.trader_profit.length < 1) {
        this.validationErrorTraderProfit = 'الرجاء ادخال مربح التاجر';
      } else {
        this.validationErrorTraderProfit = '';
      }
    }, validateInputD() {
      if (this.drafting_fee.length < 1) {
        this.validationErrorDraftingFee = 'الرجاء ادخال اجرة الصياغة';
      } else {
        this.validationErrorDraftingFee = '';
      }
    },
    validateInputImage() {
      if (this.selectedFileName.length < 1) {
        this.validationErrorImage = 'الرجاء ادخال صورة';
      } else {
        this.validationErrorImage = '';
      }
    },
    handleImageUpload(event) {
      this.selectedFile = event.target.files[0];
      const file = this.selectedFile;
      if (file) {
        this.selectedFileName = file.name;
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result;
          console.log("Uploaded image URL:", imageUrl);
        };
        reader.readAsDataURL(file);
      }
    },
    addPhoneNumber() {
      if (this.phoneNumbers.length < 3) {
        this.phoneNumbers.push({value: ""});
      }
    },
  }
}
</script>

<style scoped src="../styles/AddCategory.css">

</style>