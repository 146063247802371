import axios from 'axios';
import {BaseUrl} from "@/util/constants";

const EndPoint = 'login';

class LoginService {


    static async loginAdmin(email, password) {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        console.error('email:', email);
        console.error('pass:', password);
        try {
            const response = await axios.post(`${BaseUrl}${EndPoint}`, formData);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during login');
            }
        }
    }
}


export default LoginService;
