import {createRouter, createWebHistory} from 'vue-router';
import Login from "@/features/login/presentation/views/pages/Login";
import MainLayout from "@/features/main/presentation/views/pages/MainLayout.vue";
import Statistics from "@/features/statistics/presentation/pages/Statistics.vue";
import IntermediateStores from "@/features/intermediate_store/presentation/pages/IntermediateStores.vue";
import Products from "@/features/products/presentation/pages/Products.vue";
import Categories from "@/features/categories/presentation/pages/Categories.vue";
import PublicPolicies from "@/features/public_policies/presentation/pages/PublicPolicies.vue";
import Notifications from "@/features/notifications/presentation/pages/Notifications.vue";
import Messages from "@/features/messages/presentation/pages/Messages.vue";
import ShowReport from "@/features/intermediate_store/presentation/pages/ShowReport.vue";
import SortBody from "@/features/intermediate_store/presentation/components/SortBody.vue";
import AddStore from "@/features/intermediate_store/presentation/pages/AddStore.vue";
import VerifyStore from "@/features/intermediate_store/presentation/pages/VerifyStore.vue";
import StoreResultsBody from "@/features/intermediate_store/presentation/pages/StoreResultsBody.vue";
import IntermediateStoreDetails from "@/features/intermediate_store/presentation/pages/IntermediateStoreDetails.vue";
import FilterPage from "@/features/intermediate_store/presentation/pages/FilterPage.vue";
import FilterBody from "@/features/intermediate_store/presentation/components/FilterBody.vue";
import UserDetails from "@/features/statistics/presentation/pages/UserDetails.vue";
import MainStatistic from "@/features/statistics/presentation/pages/MainStatistic.vue";
// import ProductsResutls from "@/features/products/presentation/pages/OrdersResutls.vue";
import ProductsFilterPage from "@/features/products/presentation/pages/ProductsFilterPage.vue";
import ProductsResutls from "@/features/products/presentation/pages/ProductsResutls.vue";
import SortpProductsBody from "@/features/products/components/SortpProductsBody.vue";
import FilterProductsBody from "@/features/products/components/FilterProductsBody.vue";
import ProductsDetails from "@/features/products/presentation/pages/ProductsDetails.vue";
import AddProduct from "@/features/products/presentation/pages/AddProduct.vue";
import AddProductInfo from "@/features/products/presentation/pages/AddProductInfo.vue";
import CategoriesData from "@/features/categories/presentation/pages/CategoriesData.vue";
import AddCategory from "@/features/categories/presentation/pages/AddCategory.vue";
import SubCategoriesData from "@/features/categories/presentation/pages/SubCategoriesData.vue";
import SubCategoriesDetails from "@/features/categories/presentation/pages/SubCategoriesDetails.vue";
import AddBanner from "@/features/categories/presentation/pages/AddBanner.vue";
import PolicityContent from "@/features/public_policies/presentation/pages/PolicityContent.vue";
import AddSubCategory from "@/features/categories/presentation/pages/AddSubCategory.vue";
import Map from "@/components/Map.vue";
import Map2 from "@/components/Map2.vue";
import EditCategory from "@/features/categories/presentation/pages/EditCategory.vue";
import EditSubCategory from "@/features/categories/presentation/pages/EditSubCategory.vue";
import Reservations from "@/features/reservations/presentation/pages/Reservations.vue";
import ReservationsResutls from "@/features/reservations/presentation/pages/ReservationsResutls.vue";
import ReservationDetails from "@/features/reservations/presentation/pages/ReservationDetails.vue";
import Problems from "@/features/problems/presentation/pages/Problems.vue";
import ProblemsContent from "@/features/problems/presentation/pages/ProblemsContent.vue";
import AddProblem from "@/features/problems/presentation/pages/AddProblem.vue";
import EditProblem from "@/features/problems/presentation/pages/EditProblem.vue";
import Orders from "@/features/orders/presentation/pages/Orders.vue";
import OrdersDetails from "@/features/orders/presentation/pages/OrdersDetails.vue";
import OrdersResutls from "@/features/orders/presentation/pages/OrdersResutls.vue";
import EditBanner from "@/features/categories/presentation/pages/EditBanner.vue";
import AddRegion from "@/features/products/presentation/pages/AddRegion.vue";
import RegionContent from "@/features/products/presentation/pages/RegionContent.vue";
import EditRegion from "@/features/products/presentation/pages/EditRegion.vue";
import AddSubSubCategory from "@/features/categories/presentation/pages/AddSubSubCategory.vue";
import EditSubSubCategory from "@/features/categories/presentation/pages/EditSubSubCategory.vue";
import SubSubCategoriesDetails from "@/features/categories/presentation/pages/SubSubCategoriesDetails.vue";

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/',
        component: MainLayout,
        children: [
            {
                path: 'statistics',
                name: 'Statistics',
                component: Statistics,
                children: [
                    {
                        path: 'user-details/:id',
                        name: 'UserDetails',
                        component: UserDetails
                        ,
                    },
                    {
                        path: 'statistics',
                        name: 'MainStatistic',
                        component: MainStatistic
                        ,
                    },
                ],

            },

            {
                path: 'orders',
                name: 'Orders',
                component: Orders,
                children: [
                    {
                        path: 'order-details/:id',
                        name: 'OrdersDetails',
                        component: OrdersDetails
                        ,
                    },
                    {
                        path: 'orders',
                        name: 'OrdersResutls',
                        component: OrdersResutls
                        ,
                    },
                ],

            },
            {
                path: 'intermediate-stores',
                name: 'IntermediateStores',
                component: IntermediateStores,
                children: [
                    {
                        path: 'verify-store',
                        name: 'VerifyStore',
                        component: VerifyStore
                        ,
                    },
                    {
                        path: 'intermediate-store-details/:id',
                        name: 'IntermediateStoreDetails',
                        component: IntermediateStoreDetails
                        ,
                    },
                    {
                        path: '',
                        name: 'StoreResultsBody',
                        component: StoreResultsBody
                        ,
                    },
                    {
                        path: 'add-store',
                        name: 'AddStore',
                        component: AddStore
                        ,
                    },
                    {
                        path: 'filter',
                        name: 'FilterPage',
                        component: FilterPage,
                        children: [
                            {
                                path: 'sort',
                                name: 'SortBody',
                                component: SortBody,
                            },
                            {
                                path: 'filter',
                                name: 'FilterBody',
                                component: FilterBody,
                            },

                        ]
                    },
                    {
                        path: 'show-report',
                        name: 'ShowReport',
                        component: ShowReport,
                    },
                ],
            },
            {
                path: 'map',
                name: 'Map2',
                component: Map2,
            },
            {
                path: 'map',
                name: 'Mapiiiliii',
                component: Map,
            },
            {
                path: 'products',
                name: 'Products',
                component: Products,
                children: [
                    {
                        path: '',
                        name: 'ProductsResults',
                        component: ProductsResutls
                        ,
                    },
                    {
                        path: 'add-product',
                        name: 'AddProduct',
                        component: AddProduct
                        ,
                    }, {
                        path: 'add-region',
                        name: 'AddRegion',
                        component: AddRegion
                        ,
                    }, {
                        path: 'edit-region',
                        name: 'EditRegion',
                        component: EditRegion
                        ,
                    }, {
                        path: 'regions',
                        name: 'RegionContent',
                        component: RegionContent
                        ,
                    },
                    {
                        path: 'add-product',
                        name: 'AddProductInfo',
                        component: AddProductInfo
                        ,
                    },
                    {
                        path: 'product-details/:id',
                        name: 'ProductsDetails',
                        component: ProductsDetails
                        ,
                    },
                    {
                        path: 'filter',
                        name: 'ProductsFilterPage',
                        component: ProductsFilterPage,
                        children: [
                            {
                                path: 'sort',
                                name: 'SortpProductsBody',
                                component: SortpProductsBody,
                            },
                            {
                                path: 'filter',
                                name: 'FilterProductsBody',
                                component: FilterProductsBody,
                            },

                        ]
                        ,
                    },

                ]
            },
            {
                path: 'reservations',
                name: 'Reservations',
                component: Reservations,
                children: [
                    {
                        path: '',
                        name: 'ReservationsResults',
                        component: ReservationsResutls
                        ,
                    },
                    {
                        path: 'reservation-details/:id',
                        name: 'ReservationDetails',
                        component: ReservationDetails
                        ,
                    },


                ]
            },
            {
                path: 'problems',
                name: 'Problems',
                component: Problems,
                children: [
                    {
                        path: '',
                        name: 'ProblemsContent',
                        component: ProblemsContent,
                    },
                    {
                        path: 'add-problem',
                        name: 'AddProblem',
                        component: AddProblem,
                    },
                    {
                        path: 'edit-problem',
                        name: 'EditProblem',
                        component: EditProblem,
                    },]
            },
            {
                path: 'categories',
                name: 'Categories',
                component: Categories,
                children: [
                    {
                        path: '',
                        name: 'CategoriesData',
                        component: CategoriesData,
                    },
                    {
                        "path": 'edit-banner/:bannerId',
                        "name": 'EditBanner',
                        "component": EditBanner,
                    },
                    {
                        path: 'add-category',
                        name: 'AddCategory',
                        component: AddCategory,
                    },
                    {
                        path: 'edit-category',
                        name: 'EditCategory',
                        component: EditCategory,
                    },
                    {
                        path: 'edit-sub-category',
                        name: 'EditSubCategory',
                        component: EditSubCategory,
                    }, {
                        path: 'edit-sub-sub-category',
                        name: 'EditSubSubCategory',
                        component: EditSubSubCategory,
                    }, {
                        path: 'sub-sub-category/:id',
                        name: 'SubSubCategoriesDetails',
                        component: SubSubCategoriesDetails,
                    },
                    {
                        path: 'add-sub-category/:id',
                        name: 'AddSubCategory',
                        component: AddSubCategory,
                    }, {
                        path: 'add-sub-sub-category/:id',
                        name: 'AddSubSubCategory',
                        component: AddSubSubCategory,
                    },
                    {
                        path: 'sub-categories/:id',
                        name: 'SubCategoriesData',
                        component: SubCategoriesData,
                    },
                    {
                        path: 'add-banner/:id',
                        name: 'AddBanner',
                        component: AddBanner,
                        children: [
                            {
                                path: '',
                                component: AddBanner,
                            },
                            {
                                path: 'category/:categoryId',
                                name: 'filter/sortAddCategoryBanner',
                                component: AddBanner,
                            }, {
                                path: 'category/:categoryId',
                                name: 'filter/sortAddCategoryBanner',
                                component: AddBanner,
                            },
                            {
                                path: 'subcategory/:subcategoryId',
                                name: 'AddSubcategoryBanner',
                                component: AddBanner,
                            },
                        ],
                    },
                    {
                        path: 'sub-category-details/:id',
                        name: 'SubCategoriesDetails',
                        component: SubCategoriesDetails,
                    },
                ]
            },
            {
                path: 'public-policies',
                name: 'PublicPolicies',
                component: PublicPolicies,
                children: [
                    {
                        path: '',
                        name: 'PolicityContent',
                        component: PolicityContent,
                    },]
            },
            {
                path: 'notifications',
                name: 'Notifications',
                component: Notifications,
            },
            {
                path: 'messages',
                name: 'Messages',
                component: Messages,
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,

});

export default router;
