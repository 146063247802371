import axios from 'axios';
import {BaseUrl} from "@/util/constants";

const ChatsEndPoint = 'contact/conversation/list';
const ConversationEndPoint = 'contact/conversation/'
const SendMessageEndPoint = 'contact'

// const AddNoteEndPoint = 'orders/notes/'

class MessagesService {


    static async getAllChats(token) {
        const header = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        try {
            const response = await axios.get(`${BaseUrl}${ChatsEndPoint}`, header);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during products');
            }
        }
    }


    static async getUserConversations(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            const response = await axios.get(`${BaseUrl}${ConversationEndPoint}${id}`, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during user chat.');
            }
        }
    }

    static async sendMessage(token, id, message) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const formData = new FormData();
            formData.append('description', message);
            formData.append('user_id', id);

            console.log(`id is ${id}`)

            const response = await axios.post(`${BaseUrl}${SendMessageEndPoint}`, formData, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }

}


export default MessagesService;
