<template>
  <div>
    <div class="page-scrollable">

      <router-view></router-view>

    </div>
  </div>
</template>
<script>

import {AddIcon} from "@/util/constants";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Categories",
  methods: {
    AddIcon() {
      return AddIcon
    },
    openAdd() {
    },
  },
  data() {
    return {
      categories: [
        {id: 1, name: "Category 1", image: "Category 1"},
        {id: 2, name: "Category 2", image: "Category 1"},
        {id: 2, name: "Category 2", image: "Category 1"},
        {id: 2, name: "Category 2", image: "Category 1"},

      ],
    };
  }
}
</script>
<style scoped src="../styles/Categories.css">

</style>