<template>
  <div class="image-gallery">
    <div class="thumbnail-list">
      <div class="image-gallery-container" v-for="(image, index) in images" :key="index">
        <img
            :src="ImageUrl()+image.image"
            :alt="image.image"
            @click="selectImage(index)"
            :class="{ selected: selectedIndex === index }"
        />
      </div>
    </div>

    <div class="main-image">
      <img
          :src="ImageUrl()+selectedImage.image"
          :alt="selectedImage.image"
          class="selected"
      />
    </div>
  </div>
</template>

<script>
import {ImageUrl} from "@/util/constants";

export default {
  props: {
    images: Array
  },
  data() {
    return {
      selectedIndex: 0,
      selectedImage: {}
    };
  },
  created() {
    if (this.images && this.images.length > 0) {
      this.selectImage(0);
    }
  },
  mounted() {
    setTimeout(() => {
      this.selectImage(0);
    }, 800);

  },
  methods: {
    ImageUrl() {
      return ImageUrl
    },
    selectImage(index) {
      this.selectedIndex = index;
      this.selectedImage = this.images[index];
    },
  },
};
</script>

<style scoped>
.image-gallery {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

.image-gallery-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 5px;
  margin: 10px;

}

.thumbnail-list {
  display: flex;
  margin: 50px;
}

.thumbnail-list img {
  width: 65px;
  height: 50px;
  cursor: pointer;
  border: 2px solid transparent;
}

.thumbnail-list img.selected {
  border-color: #FFCC00;
}

.main-image img {
  width: 200px;
  height: 160px;
  margin-top: 10px;
  flex: 2;
}

@media screen and (max-width: 768px) {
  .image-gallery {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 0px;
    width: 76vw;  }
  .thumbnail-list {
    display: flex;
    margin: 4vw;  }
  .main-image img {
    width: 25vw;
    height: 22vw;
    margin-top: 10px;
    flex: 2;
  }
  .thumbnail-list img {
    width: 11vw;
    height: 9vw;
    cursor: pointer;
    border: 2px solid transparent;
  }
  .image-gallery-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    padding: 1vw;
    margin: 1vw;

  }

}

</style>
