<template>
  <div class="page-scrollable">
  <div class="row">
    <div class="route-title">{{ $t('GoldBannerOffers') }} بال{{ this.name }}</div>
    <div class="icon" v-html="AddIcon()" @click="openAdd2"></div>
  </div>    <div v-if="cardData.length===0&&!isLoading" style="padding: 100px" class="select-title">
      <label for="selectItem" style="margin: 20px"> لا يوجد عناصر لعرضها</label>
    </div>
    <div class="card-grid">
      <div v-for="item in cardData" :key="item.id" class="card-banner">
        <div class="card-icons-row">
          <div class="icon-left" @click.stop="openPopup2(item.paragraph,item.id)">
            <div v-html="DeleteIcon()"></div>
          </div>
          <div class="icon-right" @click.stop="openEdit(item.id,item.paragraph,item.active,item.time)">
            <div v-html="EditIcon()"></div>
          </div>
        </div>
        <div class="content">
          <p>{{ item.paragraph }}</p>
          <img :src="ImageUrl(item.background)" alt="icon img"
               class="icon"/></div>
        <img :src="ImageUrl(item.image) " alt="Card Image"
             class="image"/>
      </div>
    </div>
    <DeleteCategoryPopUp v-if="showDeletePopup" ref="showDeletePopupRef" @close-popup="closePopUp"
                         @refresh="fetchSubCategoriesData " :name="selectedName" :id="selectedId" :is-cat="false"
                         :is-sub-sub-cat="true"/>

    <DeleteADSPopUp v-if="showDelete2Popup" ref="showDelete2PopupRef" @close-popup="closePopUp2"
                    @refresh="fetchAdsData " :name="selectedName2" :id="selectedId2" :is-cat="false"/>
  </div>
</template>
<script>

import {AddIcon, DeleteIcon, EditIcon, GoldItem, ImageUrl} from "@/util/constants";
import CategoriesViewModel from "@/features/categories/viewmodels/CategoriesViewModel";
import DeleteADSPopUp from "@/features/categories/presentation/components/DeleteADSPopUp.vue";
import DeleteCategoryPopUp from "@/features/categories/presentation/components/DeleteCategoryPopUp.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "SubSubCategoriesData",
  components: {DeleteCategoryPopUp, DeleteADSPopUp},
  created() {
    this.fetchAdsData();
    this.fetchSubCategoriesData()
  },
  methods: {
    EditIcon() {
      return EditIcon
    },
    DeleteIcon() {
      return DeleteIcon
    },
    ImageUrl(image) {
      console.log(ImageUrl + image)
      return ImageUrl + image;
    }
    ,
    openPopup(name, id) {
      this.selectedName = name;
      this.selectedId = id;
      console.log('id is like that' + id)
      this.showDeletePopup = true;
      if (this.$refs.showDeletePopupRef && this.$refs.showDeletePopupRef.openPopup) {
        this.$refs.showDeletePopupRef.openPopup();
      }
    },
    async fetchSubCategoriesData() {

      try {
        const id = this.$route.params.id;
        this.name = this.$route.query.name;
        const data = await CategoriesViewModel.getAllSubSubCategories(id);
        console.log(data)
        this.categories = data.data;
        this.isLoading1 = false;
      } catch (error) {
        console.error("Error fetching statistics:", error.message);
        this.isLoading = false;

      }
    },
    openPopup2(name, id) {
      this.selectedName2 = name;
      this.selectedId2 = id;
      console.log('id is like that' + id)
      this.showDelete2Popup = true;
      if (this.$refs.showDelete2PopupRef && this.$refs.showDelete2PopupRef.openPopup) {
        this.$refs.showDelete2PopupRef.openPopup();
      }
    },
    openEdit(id, name, isActive, time) {
      console.log(isActive + time)
      this.$router.push({
        name: 'EditBanner', params: {bannerId: id}, query: {name: name, isActive: isActive, time: time,}
      });

    },
    GoldItem() {
      return GoldItem
    },
    AddIcon() {
      return AddIcon
    },
    openAdd1() {
      this.$router.push({name: 'AddaBanner'});

    },
    openPopupDelete(name, id) {
      this.selectedName = name;
      this.selectedId = id;
      console.log('id is like that' + id)
      this.showDeletePopup = true;
      if (this.$refs.showDeletePopupRef && this.$refs.showDeletePopupRef.openPopup) {
        this.$refs.showDeletePopupRef.openPopup();
      }
    },

    openAdd() {
      const id = this.$route.query.catId;

      this.$router.push({
        name: 'AddSubSubCategory', params: {
          id: this.$route.params.id,
        },
        query: {
          catId: id
        }
      });


    },
    openAdd2() {
      this.$router.push({
        name: 'AddBanner', params: {
          categoryId: null, subcategoryId: this.$route.params.id,

        }, query: {name: 'subcategory'}
      });
    },    closePopUp() {
      this.showDeletePopup = false;
    },
    closePopUp2() {
      this.showDelete2Popup = false;
    },
    async fetchAdsData() {

      try {
        const id = this.$route.params.id;
        this.name = this.$route.query.name;

        const data = await CategoriesViewModel.getAdsSubCategory(id);
        console.log(data)
        this.cardData = data.data;
        this.isLoading = false;
      } catch (error) {
        console.error("Error fetching ads:", error.message);
        this.isLoading = false;

      }
    },
  },
  data() {
    return {
      isLoading: true,
      categories: [],
      cardData: [],
      showDeletePopup: false,
      selectedName: '',
      selectedId: null,
      name: '', showDelete2Popup: false,
      selectedName2: '',
      selectedId2: null

    };
  }
}
</script>
<style scoped src="../styles/Categories.css">

</style>