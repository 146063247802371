// Import axios and BaseUrl from constants file
import axios from 'axios';
import {BaseUrl} from "@/util/constants";

const ProblemsEndPoint = 'problems/type/get';
const AddProblemEndPoint = 'problems/type';

class ProblemsService {
    static async fetchProblems(token) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.get(`${BaseUrl}${ProblemsEndPoint}`, {headers});
            return response.data;
        } catch (error) {
            console.error('Error fetching policies:', error);
            throw error;
        }
    }

    static async addProblem(token, name) {
        try {
            const formData = new FormData();
            formData.append('name', name);

            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            };

            console.log('token is ' + token)

            const response = await axios.post(`${BaseUrl}${AddProblemEndPoint}`, formData, {headers});
            return response

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during add.');
            }
        }
    }
    static async editProblem(token, name, id) {
        try {
            const formData = new FormData();
            formData.append('name', name);

            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            };

            console.log('token is ' + token)

            const response = await axios.post(`${BaseUrl}${AddProblemEndPoint}/${id}`, formData, {headers});
            return response

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during add.');
            }
        }
    }
    static async deleteProblem(token, id) {
        try {


            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            };

            console.log('token is ' + token)

            const response = await axios.delete(`${BaseUrl}${AddProblemEndPoint}/${id}`, {headers});
            return response

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during add.');
            }
        }
    }
}

export default ProblemsService;
