<template>
  <div class="gradient-background">
    <div class="content">

    <div class="circle">
        Logo
      </div>
      <img src="../../../../assets/images/login-background.png" alt="Image" class="image-back">
    </div>

  </div>
</template>

<script>
export default {
  name: "LogoRow"
}
</script>
<style scoped src="../views/styles/LogoRow.css"></style>

