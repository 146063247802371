import axios from 'axios';
import {BaseUrl} from "@/util/constants";

const StoreEndPoint = 'products';
const DetailsEndPoint = 'products/';
const Search = 'products/search/by/code'
const Sort = 'products/search/sort?'
const SendRegion = 'address'
const categories = 'https://mayadeen-md.com/goldStore/public/api/categories/list'
const subCategories = 'https://mayadeen-md.com/goldStore/public/api/categories/sub/list/'


class ProductsService {


    static async getAllProducts(token) {
        const header = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        try {
            const response = await axios.get(`${BaseUrl}${StoreEndPoint}`, header);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during products');
            }
        }
    }

    static async getAllCountries() {

        try {
            const response = await axios.get('https://restcountries.com/v3.1/all',);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during countries');
            }
        }
    }

    static async getAllCategories() {

        try {
            const response = await axios.get(`${categories}`,);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during products');
            }
        }
    }

    static async getAllCarats() {

        try {
            const response = await axios.get(`https://mayadeen-md.com/goldStore/public/api/bursa/get/gold/carat`,);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during products');
            }
        }
    }

    static async sendRegion(token, country, city) {
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
        try {
            const formData = new FormData();
            formData.append('country', country);


            formData.append('city', city);


            const response = await axios.post(`${BaseUrl}${SendRegion}`, formData, {headers});
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during stores');
            }
        }
    }

    static async editRegion(token, country, city, id) {
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
        try {
            const formData = new FormData();
            formData.append('country', country);


            formData.append('city', city);


            const response = await axios.post(`${BaseUrl}${SendRegion}/${id}`, formData, {headers});
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during stores');
            }
        }
    }


    static async getAllSubCategories(id) {

        try {
            const response = await axios.get(`${subCategories}${id}`,);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during products');
            }
        }
    }

    static async deleteRegion(token, id) {
        try {


            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            };

            console.log('token is ' + token)

            const response = await axios.delete(`${BaseUrl}${SendRegion}/${id}`, {headers});
            return response

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during add.');
            }
        }
    }

    static async getAllRegions(token) {

        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await axios.get(BaseUrl + `${SendRegion}`, {headers});
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during products');
            }
        }
    }

    static async sendProduct(token, cat, subCat, carat, fromP, toP, fromW, toW, state) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };


            let s1 = cat === null || cat === '' ? '' : 'category_id=' + cat
            let s2 = subCat === null || subCat === '' ? '' : '&subcategory_id=' + subCat
            let s3 = carat === null || carat === '' ? '' : '&carat=' + carat
            let s4 = fromP === null || fromP === '' ? '' : '&from_price=' + fromP
            let s5 = toP === null || toP === '' ? '' : '&to_price=' + toP
            let s6 = fromW === null || fromW === '' ? '' : '&from_wight=' + fromW
            let s7 = toW === null || toW === '' ? '' : '&to_wight=' + toW
            let s8 = state === null || state === '' ? '' : '&product_type=' + state
            console.log(s8)
            const response = await axios.get(`${BaseUrl}products/search/filter?${s1}${s2}${s3}${s4}${s5}${s6}${s7}${s8}`, {headers});
            return response.data

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during add store.');
            }
        }
    }

    static async downloadBill(token, id) {

        try {
            const header = {
                headers: {
                    Authorization: `Bearer ${token}`,

                }
            };
            const response = await axios.get(`https://mayadeen-md.com/goldStore/public/api/gold/reports/bill/pdf/${id}`, header);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during products');
            }
        }
    }

    static async downloadReceipt(token, id) {

        try {
            const header = {
                headers: {
                    Authorization: `Bearer ${token}`,

                }
            };
            const response = await axios.get(`https://mayadeen-md.com/goldStore/public/api/gold/reports/receipt/voucher/pdf/${id}`, header);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during products');
            }
        }
    }

    static async searchProduct(token, codee) {
        const header = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        try {
            const response = await axios.get(`${BaseUrl}${Search}?code=${codee}`, header);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during search products');
            }
        }
    }

    static async sortProducts(token, costs, created) {
        let cost = 'price='
        let createdAt = 'created_at='

        let createdAtAnd = ''
        const header = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        try {
            if (costs === null) {
                cost = ''
            } else {
                cost = cost + costs
            }

            if (created === null) {
                createdAt = ''
            } else {
                createdAt = createdAt + created
                createdAtAnd = '&'
            }
            console.log(cost + 'ssss ' + createdAt)
            const response = await axios.get(`${BaseUrl}${Sort}${cost}${createdAtAnd}${createdAt}&list_type=1`, header);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during search products');
            }
        }
    }

    static async getProductDetails(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            console.log(`${BaseUrl}${DetailsEndPoint}${id}`)
            const response = await axios.get(`${BaseUrl}${DetailsEndPoint}${id}`, {headers});
            return response.data;

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during details details.');
            }
        }
    }

}


export default ProductsService;
