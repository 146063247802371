<template>
  <div>
    <div v-if="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>
    <div class="row">
      <div class="route-title">تعديل عرض ضمن البانر الاعلاني</div>

    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('OfferText') }}</label>
      </div>
      <div>
        <input id="selectItem" v-model="name">
        <div v-if="validationErrorName" style="color: red; font-size: 13px;">{{ validationErrorName }}</div>
      </div>
    </div>

    <div class="row">
      <div class="select-title">
        <label for="selectItem"> {{ $t('OfferImage') }}</label>
      </div>
      <div>
        <label for="imageUpload" class="custom-file-upload">
          <div>
            <div class="icon" v-html="AddIcon()"></div>
          </div>
        </label>
        <div v-if="validationErrorImage" style="color: red; font-size: 13px; text-align: center">{{
            validationErrorImage
          }}
        </div>

      </div>

      <input type="file" id="imageUpload" @change="handleImageUpload" style="display: none" accept="image/*">
      <span style="font-weight: bold" v-if="selectedFileName">{{ selectedFileName }}</span>

    </div>

    <div>

    </div>

    <div v-if="!isVisible">
    </div>
    <div class="row">
      <div class="select-title">
        <label for="selectItem"> أيقونة العرض</label>
      </div>
      <div>
        <label for="imageUploadIcon" class="custom-file-upload">
          <div>
            <div class="icon" v-html="AddIcon()"></div>
          </div>
        </label>
        <div v-if="validationErrorImageIcon" style="color: red; font-size: 13px; text-align: center">{{
            validationErrorImageIcon
          }}
        </div>

      </div>
      <input type="file" id="imageUploadIcon" @change="handleImageUpload2" style="display: none" accept="image/*">
      <span style="font-weight: bold" v-if="selectedFileName2">{{ selectedFileName2 }}</span>

    </div>
    <div class="row">
      <input type="checkbox" style="height:3vh; width: 6vw " v-model="isVisible">
      <label >
        اخفاء العرض </label>

    </div>
    <div class="row" v-if="isVisible">
      <div class="select-title" style=" width: 14vw;">
        <label for="selectItem" > عدد ساعات الاخفاء</label>
      </div>
      <div>
        <input type="number" v-model="hoursHidden">
        <div v-if="validationErrorName" style="color: red; font-size: 13px;">{{ validationErrorName }}</div>
      </div>
    </div>
    <div v-if="isLoading" class="loader-container">
      <div class="loader"></div>
    </div>
    <div class="image-container" v-if="!isLoading">
      <div class="image" @click="onPress">
        <span class="button-text">{{ $t("add") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import {AddIcon} from "@/util/constants";
import CategoriesViewModel from "@/features/categories/viewmodels/CategoriesViewModel";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "EditBanner",
  data() {
    return {
      phoneNumbers: [{value: ""}],
      isVisible: true,
      hoursHidden: 1,
      items: [
        {label: this.$t('Region')},
        {label: this.$t('Governorate')},
        {label: this.$t('City')},
        {label: this.$t('District')},
        {label: this.$t('Street')},
      ],
      selectedFileName: "",
      selectedFileName2: "",
      selectedFile: null,
      selectedFile2: null,
      errorMessage: '',
      isLoading: false,

      name: "",
      idType: "",
      validationErrorName: '',
      validationErrorImage: '',
      validationErrorImageIcon: '',
      bannerName: '',
    };
  },
  created() {
    console.log(this.$route.query.isActive==0)
    this.isVisible=this.$route.query.isActive==0
    this.hoursHidden=this.$route.query.time
    this.name = this.$route.query.name;

    if (this.$route.query.name === 'category') {
      this.idType = 'categoryId';
    } else if (this.$route.query.name === 'subcategory') {
      this.idType = 'subcategoryId';
    }
  }, methods: {
    toggleElement() {
      this.showElement = !this.showElement;
    },
    validateInputName() {
      if (this.name.length < 1) {
        this.validationErrorName = 'الرجاء ادخال اسم';
      } else {
        this.validationErrorName = '';
      }
    },
    validateInputImage() {
      if (this.selectedFileName.length < 1) {
        this.validationErrorImage = 'الرجاء ادخال صورة';
      } else {
        this.validationErrorImage = '';
      }
    }, validateInputImage2() {
      if (this.selectedFileName2.length < 1) {
        this.validationErrorImageIcon = 'الرجاء ادخال صورة';
      } else {
        this.validationErrorImageIcon = '';
      }
    },

    async onPress() {
      this.isLoading = true;

      console.log(this.$route.name)
      try {



          const data = await CategoriesViewModel.editBanner(this.name, this.selectedFile, this.$route.params.bannerId , this.selectedFile2,this.isVisible?0:1,this.hoursHidden);
          console.log(data)
          this.$router.go(-1);
        this.isLoading = false;

      } catch (error) {
        console.error("Error Login:", error);

        if (error.message) {
          this.errorMessage = error.message || "خطا في السيرفر";
        } else {
          this.errorMessage = "خطا في السيرفر";
        }

        setTimeout(() => {
          this.errorMessage = '';
        }, 2000);

        this.isLoading = false;
      }
    },
    AddIcon() {
      return AddIcon
    },
    handleImageUpload(event) {
      this.selectedFile = event.target.files[0];
      const file = this.selectedFile;
      if (file) {
        this.selectedFileName = file.name;
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result;
          console.log("Uploaded image URL:", imageUrl);
        };
        reader.readAsDataURL(file);
      }
    },
    handleImageUpload2(event) {
      this.selectedFile2 = event.target.files[0];
      const file = this.selectedFile2;
      if (file) {
        this.selectedFileName2 = file.name;
        const reader = new FileReader();
        reader.onload = () => {
          const imageUrl = reader.result;
          console.log("Uploaded image URL:", imageUrl);
        };
        reader.readAsDataURL(file);
      }
    },
    addPhoneNumber() {
      if (this.phoneNumbers.length < 3) {
        this.phoneNumbers.push({value: ""});
      }
    },
  }
}
</script>

<style scoped src="../styles/AddCategory.css">

</style>