import ProductsService from '../services/ProductsService';

export default {
    async getAllProducts() {
        try {
            const token = localStorage.getItem('token');

            const response = await ProductsService.getAllProducts(token);
            return response.data;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    }, async getAllCountries() {
        try {

            const response = await ProductsService.getAllCountries();
            return response.data;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    },
    async getAllCategories() {
        try {

            const response = await ProductsService.getAllCategories();
            return response;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    },
    async getAllCarats() {
        try {

            const response = await ProductsService.getAllCarats();
            return response;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    },

    async getAllSubCategories(id) {
        try {

            const response = await ProductsService.getAllSubCategories(id);
            return response;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    },
    async sendProduct(cat, subCat, carat, fromP, toP, fromW, toW,state) {
        try {
            const token = localStorage.getItem('token');
            const response = await ProductsService.sendProduct(token, cat, subCat, carat, fromP, toP, fromW, toW,state);
            return response.data;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },
    async downloadBill(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await ProductsService.downloadBill(token, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    },
    async sendRegion(country, city) {
        try {
            const token = localStorage.getItem('token');

            const response = await ProductsService.sendRegion(token, country, city);
            return response.data;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    },
    async editRegion(country, city, id,) {
        try {
            const token = localStorage.getItem('token');

            const response = await ProductsService.editRegion(token, country, city, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    }, async getRegions() {
        try {
            const token = localStorage.getItem('token');

            const response = await ProductsService.getAllRegions(token);
            return response.data;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    },

    async DeleteProblem(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await ProductsService.deleteRegion(token, id);
            return response.data;
        } catch (error) {
            console.error('Error post policies :', error.message);
            throw error;
        }
    },
    async downloadReceipt(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await ProductsService.downloadReceipt(token, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching products :', error.message);
            throw error;
        }
    },
    async searchProduct(code) {
        try {
            const token = localStorage.getItem('token');

            const response = await ProductsService.searchProduct(token, code);
            return response.data;
        } catch (error) {
            console.error('Error searching products :', error.message);
            throw error;
        }
    },

    async sortProducts(costs, created) {
        try {
            const token = localStorage.getItem('token');

            const response = await ProductsService.sortProducts(token, costs, created);
            return response.data;
        } catch (error) {
            console.error('Error searching products :', error.message);
            throw error;
        }
    },
    async getProductDetails(id) {
        try {
            const token = localStorage.getItem('token');

            const response = await ProductsService.getProductDetails(token, id);
            return response.data;
        } catch (error) {
            console.error('Error fetching store details :', error.message);
            throw error;
        }
    },

};
