import LoginService from '../services/LoginService';

export default {
    async login(email, password) {
        try {
            const response = await LoginService.loginAdmin(email, password);
            return response.data;
        } catch (error) {
            console.error('Error fetching users:', error.message);
            throw error;
        }
    },

};
