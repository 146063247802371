<template>
  <div>
    <div class="page-scrollable">

      <router-view></router-view>

    </div>
  </div>
</template>
<script>

import {AddIcon} from "@/util/constants";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Problems",
  methods: {
    AddIcon() {
      return AddIcon
    },
    openAdd() {
    },
  },
  data() {
    return {

    };
  }
}
</script>
<style scoped src="../styles/Problems.css">

</style>