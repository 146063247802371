import axios from 'axios';
import {BaseUrl} from "@/util/constants";

const StoreEndPoint = 'stores';
const StoreDetailsEndPoint = 'stores/';
const ReportsEndPoint = 'reports/store/pdf/';
const SearchStoreEndPoint = 'stores/search/by/name?name=';
const sortStoreEndPoint = 'stores/search/sort?';
const StatesUrl = 'https://mayadeen-md.com/goldStore/public/api/address/store/'
const StateEndPoint = 'state'
const CitiesEndPoint = 'city'
const NigEndPoint = 'neighborhood'
const BlockEndPoint = 'stores/block/'


class IntermediateStoreService {

    static async getAllStates() {

        try {
            const response = await axios.get(`${StatesUrl}${StateEndPoint}`);
            return response;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during states');
            }
        }
    }

    static async getAllCities(state) {

        try {
            const response = await axios.get(`${StatesUrl}${CitiesEndPoint}?state=${state}`);
            return response;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during states');
            }
        }
    }

    static async getAllNig(city) {

        try {
            const response = await axios.get(`${StatesUrl}${NigEndPoint}?city=${city}`);
            return response;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during states');
            }
        }
    }

    static async getAddress(long, lat) {

        try {
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&language=ar&key=AIzaSyC75cYgVGb3CfdsuFFU2tNTbobMhEgCUdY
`);
            return response;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during states');
            }
        }
    }

    static async getAllStores(token) {
        const header = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        try {
            const response = await axios.get(`${BaseUrl}${StoreEndPoint}`, header);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during stores');
            }
        }
    }

    static async sortStores(token, costs, salees, created) {
        let cost = 'price='
        let sales = 'price='
        let createdAt = 'created_at='

        let createdAtAnd = ''
        let salesAnd = ''

        const header = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        try {
            if (costs === null) {
                cost = ''
            } else {
                cost = cost + costs
            }
            if (salees === null) {
                sales = ''
            } else {
                sales = sales + salees
                salesAnd = '&'

            }


            if (created === null) {
                createdAt = ''
            } else {
                createdAt = createdAt + created
                createdAtAnd = '&'
            }
            console.log(cost + 'ssss ' + createdAt)
            const response = await axios.get(`${BaseUrl}${sortStoreEndPoint}${cost}${salesAnd}${sales}${createdAtAnd}${createdAt}&list_type=1`, header);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during search products');
            }
        }
    }

    static async searchStores(token, name) {
        const header = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        try {
            const response = await axios.get(`${BaseUrl}${SearchStoreEndPoint}${name}`, header);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during stores');
            }
        }
    }

    static async deleteStore(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            console.log(`${BaseUrl}${StoreDetailsEndPoint}${id}`)
            const response = await axios.delete(`${BaseUrl}${StoreDetailsEndPoint}${id}`, {headers});
            return response.data

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during store details.');
            }
        }
    }

    static async blockStore(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            console.log(`${BaseUrl}${StoreDetailsEndPoint}${id}`)
            const response = await axios.get(`${BaseUrl}${BlockEndPoint}${id}`, {headers});
            return response.data

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during store details.');
            }
        }
    }

    static async getStoreDetails(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            console.log(`${BaseUrl}${StoreDetailsEndPoint}${id}`)
            const response = await axios.get(`${BaseUrl}${StoreDetailsEndPoint}${id}`, {headers});
            return response.data

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during store details.');
            }
        }
    }

    static async sendStore(token, state, city, neighborhood, cost) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            let s1 = state === null || state === '' ? '' : 'state=' + state
            let s2 = city === null || city === '' ? '' : '&city=' + city
            let s3 = neighborhood === null || neighborhood === '' ? '' : '&neighborhood=' + neighborhood
            let s4 = cost === null || cost === '' ? '' : '&cost=' + cost


            const response = await axios.get(`${BaseUrl}stores/search/filter?${s1}${s2}${s3}${s4}`, {headers});
            return response.data

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during add store.');
            }
        }
    }

    static async getUserDetails(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            console.log(`${BaseUrl}${StoreDetailsEndPoint}${id}`)
            const response = await axios.get(`${BaseUrl}${StoreDetailsEndPoint}${id}`, {headers});
            return response.data

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during store details.');
            }
        }
    }


    static async getReport(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            console.log(`${BaseUrl}${StoreDetailsEndPoint}${id}`)
            const response = await axios.get(`${BaseUrl}${ReportsEndPoint}${id}`, {headers});
            return response.data

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during store details.');
            }
        }
    }

    static async addStore(token, name, email, photo, description, cost, numbers, password, address, lang, lit, nationalId, commercial, selectedFileCom, License, selectedFileLis, userName, storeImage, tax, taxImage) {
        try {
            console.log('phone is ' + numbers[0].value)

            const formData = new FormData();
            formData.append('name', name);
            formData.append('email', email);
            formData.append('tax_number', tax);
            formData.append('store_image', storeImage);
            formData.append('photo', photo);
            formData.append('commercial_register_image', selectedFileCom);
            formData.append('tax_assignment_certificate_image', taxImage);
            formData.append('license_image', selectedFileLis);
            formData.append('description', description);
            formData.append('password', password);
            formData.append('cost', cost);
            formData.append('user_name', userName);
            formData.append('national_number', nationalId);
            formData.append('license', License);
            formData.append('commercial_register', commercial);
            formData.append('phone_number', numbers[0].value);
            formData.append('latitude', lit);
            formData.append('country', address[0]);
            formData.append('state', address[1]);
            formData.append('city', address[2]);
            formData.append('neighborhood', address[3]);
            formData.append('street', address[4]);
            formData.append('longitude', lang);
            numbers.shift();
            numbers.forEach((number, index) => {
                formData.append(`numbers[${index}]`, number.value);
            });
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            };

            console.log('token is ' + token)

            const response = await axios.post(`${BaseUrl}${StoreEndPoint}`, formData, {headers});
            return response

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during add.');
            }
        }
    }

}


export default IntermediateStoreService;
