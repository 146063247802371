import axios from 'axios';
import {BaseUrl} from "@/util/constants";

const CategoriesEndPoint = 'categories';
const SubCategoriesEndPoint = 'second/layer/categories/all/';
const SubSubCategoriesEndPoint = 'subcategories/second/';
const SubSubMainCategoriesEndPoint = 'subcategories/';
const AddSubCategoriesEndPoint = 'second/layer/categories';
const AddSubSubCategoriesEndPoint = 'subcategories';
const AdsEndPoint = 'advertisements/';
const AddAdsEndPoint = 'advertisements';

const subAdsEndPoint = 'advertisements/sub/';

const DeleteEndPoint = 'categories/';
const DeleteSubSubEndPoint = 'subcategories/';
const DeleteSubEndPoint = 'second/layer/categories/';


class CategoriesService {


    static async getAllCategories(token) {
        const header = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };
        try {
            const response = await axios.get(`${BaseUrl}${CategoriesEndPoint}?page=1`, header);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during categories.');
            }
        }
    }

    static async getAllSubCategories(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            console.log(`${BaseUrl}${SubCategoriesEndPoint}${id}`)
            const response = await axios.get(`${BaseUrl}${SubCategoriesEndPoint}${id}`, {headers});
            return response.data

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during subcategories.');
            }
        }
    }

    static async getAllSubSubCategories(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            console.log(`${BaseUrl}${SubCategoriesEndPoint}${id}`)
            const response = await axios.get(`${BaseUrl}${SubSubCategoriesEndPoint}${id}`, {headers});
            return response.data

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during subcategories.');
            }
        }
    }

    static async getAllSubSubMainCategories(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            console.log(`${BaseUrl}${SubCategoriesEndPoint}${id}`)
            const response = await axios.get(`${BaseUrl}${SubSubMainCategoriesEndPoint}${id}`, {headers});
            return response.data

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during subcategories.');
            }
        }
    }


    static async getAdsCategory(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            console.log(`${BaseUrl}${AdsEndPoint}${id}`)
            const response = await axios.get(`${BaseUrl}${AdsEndPoint}${id}`, {headers});
            return response

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during ads.');
            }
        }
    }


    static async deleteCategory(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            console.log(`${BaseUrl}${AdsEndPoint}${id}`)
            const response = await axios.delete(`${BaseUrl}${DeleteEndPoint}${id}`, {headers});
            return response

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during delete category.');
            }
        }
    }

    static async deleteSubSubCategory(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            console.log(`${BaseUrl}${AdsEndPoint}${id}`)
            const response = await axios.delete(`${BaseUrl}${DeleteSubSubEndPoint}${id}`, {headers});
            return response

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during delete category.');
            }
        }
    }

    static async deleteBanner(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            console.log(`${BaseUrl}${AdsEndPoint}${id}`)
            const response = await axios.delete(`${BaseUrl}${AdsEndPoint}${id}`, {headers});
            return response

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during delete category.');
            }
        }
    }


    static async deleteSubCategory(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            console.log(`${BaseUrl}${AdsEndPoint}${id}`)
            const response = await axios.delete(`${BaseUrl}${DeleteSubEndPoint}${id}`, {headers});
            return response

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during delete category.');
            }
        }
    }

    static async getAdsSubCategory(token, id) {
        try {
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            console.log(`id is ${id}`)

            console.log(`${BaseUrl}${AdsEndPoint}${id}`)
            const response = await axios.get(`${BaseUrl}${subAdsEndPoint}${id}`, {headers});
            return response

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during ads.');
            }
        }
    }

    static async addSubCategory(token, name, image, id, code,) {
        try {
                const formData = new FormData();
            formData.append('name', name);

            formData.append('image', image);
            formData.append('category_id', id);
            formData.append('code', code);


            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            };

            console.log('token is ' + token)

            const response = await axios.post(`${BaseUrl}${AddSubCategoriesEndPoint}`, formData, {headers});
            return response

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during subCategory.');
            }
        }
    }

    static async addSubSubCategory(token, name, image, id, code, drag, trade, catId, isMain) {
        try {
            const formData = new FormData();
            formData.append('name', name);

            formData.append('image', image);
            if (!isMain) {
                formData.append('category_second_layer_id', id);

            }
            console.log('catego is '+ catId)
            formData.append('category_id', catId);
            formData.append('code', code);
            formData.append('drafting_fee', drag);
            formData.append('trader_profit', trade);


            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            };

            console.log('token is ' + token)
            console.log('name is ' + name + 'category_second_layer_id is ' + id + 'category_id is ' + catId)

            const response = await axios.post(`${BaseUrl}${AddSubSubCategoriesEndPoint}`, formData, {headers});
            return response

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during subCategory.');
            }
        }
    }


    static async addBanner(token, name, image, cateId, subCat, icon, isActive, hours) {
        console.log('cat id is ' + cateId + 'sub id is ' + subCat)
        try {
            const formData = new FormData();
            formData.append('paragraph', name);
            formData.append('image', image);
            formData.append('active', isActive);
            formData.append('background', icon);
            if (isActive === 0) {
                formData.append('time', hours);

            }
            if (cateId !== null) {
                formData.append('category_id', cateId);
                console.log('category_id is ' + cateId)

            } else if (subCat !== null) {
                formData.append('subcategory_id', subCat);
                console.log('subcategory_id is ' + subCat)

            }


            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            };

            console.log('token is ' + token)

            const response = await axios.post(`${BaseUrl}${AddAdsEndPoint}`, formData, {headers});
            return response

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during add.');
            }
        }
    }

    static async editBanner(token, name, image, id, icon, isActive, hours) {
        try {
            const formData = new FormData();
            formData.append('paragraph', name);
            if (image !== null) {
                console.log(image.name)

                formData.append('image', image);

            }
            if (icon !== null) {
                formData.append('background', icon);

            }
            formData.append('active', isActive);
            if (isActive === 0) {
                formData.append('time', hours);

            }
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            };

            console.log('token is ' + token)

            const response = await axios.post(`${BaseUrl}${AdsEndPoint}${id}`, formData, {headers});
            return response

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during add.');
            }
        }
    }

    static async addCategory(token, name, image, code, commission) {
        try {
            const formData = new FormData();
            formData.append('name', name);
            formData.append('code', code);
            formData.append('commission', commission);
            formData.append('image', image);

            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            };

            console.log('token is ' + token)

            const response = await axios.post(`${BaseUrl}${CategoriesEndPoint}`, formData, {headers});
            return response

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during add.');
            }
        }
    }

    static async editCategory(token, name, image, id, code, commission) {
        try {
            const formData = new FormData();
            if (name !== null) {
                formData.append('name', name);

            }
            if (commission !== null) {
                formData.append('commission', commission);

            }
            if (code !== null) {
                formData.append('code', code);

            }
            if (name !== null) {
                formData.append('name', name);

            }
            if (image !== null) {
                formData.append('image', image);
            }

            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            };

            console.log('token is ' + token)

            const response = await axios.post(`${BaseUrl}${DeleteEndPoint}${id}`, formData, {headers});
            return response

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during add.');
            }
        }
    }

    static async editSubCategory(token, name, image, id, code) {
        try {
            const formData = new FormData();
            if (name !== null) {
                formData.append('name', name);

            }

            if (image !== null) {
                formData.append('image', image);
            }
            if (code !== null) {
                formData.append('code', code);
            }
            console.log('name is ' + name + 'code is ' + code)
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            };

            console.log('token is ' + token)

            const response = await axios.post(`${BaseUrl}${DeleteSubEndPoint}${id}`, formData, {headers});
            return response

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during add.');
            }
        }
    }

    static async editSubSubCategory(token, name, image, id, code, drag, trade) {
        try {
            const formData = new FormData();
            if (name !== null) {
                formData.append('name', name);

            }
            if (drag !== null) {
                formData.append('drafting_fee', drag);

            }
            if (trade !== null) {
                formData.append('trader_profit', trade);

            }
            if (image !== null) {
                formData.append('image', image);
            }
            if (code !== null) {
                formData.append('code', code);
            }
            console.log('name is ' + name + 'code is ' + code)
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            };

            console.log('token is ' + token)

            const response = await axios.post(`${BaseUrl}${AddSubSubCategoriesEndPoint}/${id}`, formData, {headers});
            return response

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error('Error during add.');
            }
        }
    }
}


export default CategoriesService;
