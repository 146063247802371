<template>
  <div class="card">
    <div class="card-content">
      <div style="margin: 11px 0px 7px; font-weight: bold">{{ this.category.country }} - {{ this.category.city }}</div>

      <div class="card-icons">
        <div class="icon-left" @click.stop="openDeletePopup">
          <div v-html="DeleteIcon()"></div>
        </div>
<!--        <div class="icon-right" @click.stop="openEdit">-->
<!--          <div v-html="EditIcon()"></div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>

import {DeleteIcon, EditIcon, ImageUrl} from "@/util/constants";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "RegionCard",
  props: {
    category: Object,
  },

  methods: {


    openEdit() {
      this.$router.push({
        name: 'EditRegion',
        query: {
          country: this.category.country,
          id: this.category.id,
          city: this.category.city,
        }
      });

    },
    ImageUrl(image) {
      return ImageUrl + image;
    },
    EditIcon() {
      return EditIcon
    },
    DeleteIcon() {
      return DeleteIcon
    },
    openDeletePopup() {
      console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaakkkkkkkkkkkkkkd')
      this.$emit('open-popup');
    },

  },
}
</script>
<style scoped src="../presentation/styles/Regions.css">

</style>