<template>
  <div class="row">
    <LogoRow />
    <LoginCard />
  </div>
</template>

<script>


import LogoRow from "@/features/login/presentation/components/LogoRow";
import LoginCard from "@/features/login/presentation/components/LoginCard";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",
  components: {
    LogoRow,
    LoginCard
  },
  data() {
    return {};
  },
  methods: {

}}
</script>
<style scoped src="../styles/Login.css"></style>
